class CommitteeRole {
    constructor(data) {
        Object.assign(this, data);

        this.commencement = new Date(data.commencement);
        this.termination = new Date(data.termination);
    }

    isCurrent() {
        const timeNow = new Date();
        /* This javascript comparison is borked.
           This is valid and works as expected:
           role.termination > timeNow && timeNow > role.commencement

           This is valid and does NOT work as expected:
           role.termination > timeNow > role.commencement
       */
        return this.commencement < timeNow && timeNow < this.termination;
    }

    intersects(commencement, termination) {
        let error = false;
        // Case 1
        // New commencement is inside the range of this role
        if (this.commencement <= commencement && this.termination >= commencement)
            error = true;

        // Case 2
        // New termination is inside the range of this role
        if (this.commencement <= termination && this.termination >= termination)
            error = true;

        // Case 3
        // This role's date range falls inside the new date range
        if (this.commencement >= commencement && this.termination <= termination)
            error = true;

        return error;
    }
}

export default CommitteeRole;