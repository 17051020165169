import * as React from 'react';
import {Checkbox, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

function isValidEmail(val) {
    if (!val || val.toString().trim() === '') {
        return true;
    }
    // eslint-disable-next-line max-len
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(val);
}

function isValidPhone(val) {
    if (!val || val.toString().trim() === '') {
        return true;
    }
    let re = /^(\+)?([0-9]+[\-|\s])*[0-9]+$/; // eslint-disable-line no-useless-escape
    return re.test(val.toString().trim());
}

const LDAP_RESTRICTED_CHARS = '\\,:?|*&;#<>"=[]{}()';

/*
 * checks whether the inputted characters are safe for ldap against a set of characters that is exported by the server
 * eventually this will not allow characters like '+' in email field :(
 */
export function isLdapSafe(val) {
    if (!val) {
        return true;
    } else {
        for (let index = 0; index < LDAP_RESTRICTED_CHARS.length; index++) {
            if (val.indexOf(LDAP_RESTRICTED_CHARS[index]) >= 0) {
                return false;
            }
        }
        return true;
    }
}

class BootstrapInput extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            type,
            error,
            warning,
            ...props
        } = this.props;

        let message;
        const validationState = ( error && 'error' ) || ( warning && 'warning' ) || null;

        if (error || warning) {
            message = <span className="help-block">{error || warning}</span>;
        }

        return (
            <FormGroup validationState={validationState}>
                {label ? (<ControlLabel>{label}</ControlLabel>) : null}
                <FormControl {...input}
                    type={type}
                    {...props} />
                {feedbackIcon ? <FormControl.Feedback>{feedbackIcon}</FormControl.Feedback> : null}
                {message}
            </FormGroup>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
class BootstrapCheckbox extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            error,
            warning,
            ...props
        } = this.props;

        let message;
        const validationState = ( error && 'error' ) || ( warning && 'warning' ) || null;

        if (error || warning) {
            message = <span className="help-block">{error || warning}</span>;
        }

        return (
            <FormGroup validationState={validationState}>
                <Checkbox {...input}
                    {...props}>{label}</Checkbox>
                {feedbackIcon ? <FormControl.Feedback>{feedbackIcon}</FormControl.Feedback> : null}
                {message}
            </FormGroup>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
class BootstrapSelect extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            options,
            error,
            warning,
            ...props
        } = this.props;

        let message;
        const validationState = ( error && 'error' ) || ( warning && 'warning' ) || null;

        if (error || warning) {
            message = <span className="help-block">{error || warning}</span>;
        }

        return (
            <FormGroup validationState={validationState}>
                {label ? (<ControlLabel>{label}</ControlLabel>) : null}
                <FormControl componentClass="select" {...input}
                    {...props}>{options}</FormControl>
                {feedbackIcon ? <FormControl.Feedback>{feedbackIcon}</FormControl.Feedback> : null}
                {message}
            </FormGroup>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
class BootstrapDateTimePicker extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            error,
            warning,
            ...props
        } = this.props;

        let message;
        const validationState = ( error && 'error' ) || ( warning && 'warning' ) || null;

        if (error || warning) {
            message = <span className="help-block">{error || warning}</span>;
        }

        return (
            <FormGroup validationState={validationState}>
                <ControlLabel>{label}</ControlLabel>
                <DateTime {...input} {...props} dateFormat="Do MMM YYYY"/>
                {feedbackIcon ? <FormControl.Feedback>{feedbackIcon}</FormControl.Feedback> : null}
                {message}
            </FormGroup>
        );
    }
}

export {
    BootstrapInput,
    BootstrapDateTimePicker,
    BootstrapSelect,
    BootstrapCheckbox,
    isValidEmail,
    isValidPhone
};
