import React, {Component} from 'react';
import {connect} from 'react-redux';

import ServerErrors from '../../utils/ServerErrors';
import {requestUserDetails} from '../../stores/auth/store';
import {defer, navigate} from '../../utils/generalUtils';
import {Button, Col, Image, Panel} from 'react-bootstrap';
import {Control, LocalForm} from 'react-redux-form';
import {
    BootstrapCheckbox,
    BootstrapInput,
    BootstrapSelect,
    isLdapSafe,
    isValidPhone
} from '../../utils/BootstrapInputs';
import {requestUpdateUserDetails} from '../../stores/users/store';
import {resetRequestErrors} from '../../stores/misc/store';
import logo from '../../assets/images/logo.png';

// Define the Register component
class CompleteRegistration extends Component {
    constructor() {
        super();

        this.state = {};
    }

    render() {
        // Retrieve the props we need to use in this component
        const {dispatch, errors, localUser} = this.props;

        // Check if the user is actually already logged in
        if (localUser.registration_complete || !localUser.isValid() || !localUser.is_aaf) {
            // The user is already logged in, instead we should present nothing
            return null;
        }

        // Render the registration form
        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <div className="text-center">
                        <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                        <h1>Proposal System</h1>
                    </div>

                    <Panel header={<h3>Complete Registration</h3>} className="login-panel">
                        <ServerErrors errors={errors}/>
                        <LocalForm
                            onSubmit={
                                (data) => {
                                    dispatch(resetRequestErrors());

                                    // Dispatch a details update request
                                    dispatch(
                                        requestUpdateUserDetails(data, () => {
                                            defer(() => dispatch(requestUserDetails()));
                                            defer(() => navigate(this, '/'));
                                        })
                                    );
                                }
                            }
                            onChange={(data) => {
                                this.setState(
                                    {
                                        ...this.state,
                                        ...data
                                    }
                                );
                            }}
                            initialState={{
                                institution: '',
                                gender: '',
                                phone_number: '',
                                is_student: false
                            }}
                            validators={{
                                '': {
                                    validInstitution: (vals) => isLdapSafe(vals.institution),
                                    validPhone: (vals) =>
                                        isValidPhone(vals.phone_number) && isLdapSafe(vals.phone_number)
                                }
                            }}>
                            <BootstrapInput
                                placeholder="Email Address"
                                required
                                type="email"
                                maxLength={254}
                                disabled
                                value={localUser.email}
                            />
                            <Control
                                placeholder="Institution"
                                component={BootstrapInput}
                                model=".institution"
                                required
                                maxLength={100}
                                error={
                                    !isLdapSafe(this.state.institution) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : null
                                }
                            />
                            <Control
                                placeholder="Gender"
                                component={BootstrapSelect}
                                options={[
                                    (<option key={0} value="">---</option>),
                                    (<option key={1} value="MALE">Male</option>),
                                    (<option key={2} value="FEMALE">Female</option>),
                                    (<option key={3} value="OTHER">Other/Prefer not to say</option>),
                                ]}
                                model=".gender_choice"
                                required
                            />
                            <Control
                                placeholder="Phone Number"
                                component={BootstrapInput}
                                model=".phone_number"
                                required
                                maxLength={30}
                                error={
                                    !isLdapSafe(this.state.phone_number) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        :
                                        !isValidPhone(this.state.phone_number) ?
                                            'Please enter a valid phone number'
                                            : null
                                }
                            />
                            <Control.checkbox
                                label="Are you a student?"
                                component={BootstrapCheckbox}
                                model=".is_student"
                            />
                            <Button type="submit" bsSize="large" bsStyle="success" block>Complete Registration</Button>
                        </LocalForm>
                    </Panel>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = ({misc, auth}) => ({
    errors: misc.errors,
    localUser: auth.localUser
});

// Connect the Register component to the dispatcher
const WrappedRegister = connect(mapStateToProps)(CompleteRegistration);

// Export the Register component from this module
export default WrappedRegister;
