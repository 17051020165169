import {call, put, takeEvery} from 'redux-saga/effects';
import {get, post} from '../../utils/api';
import Enumerable from 'linq';
import CallForProposal from './CallForProposal';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const CALLS_REQUEST = 'astacta/calls/request';
const CALLS_SUCCESS = 'astacta/calls/success';

const CALL_SUBMIT_REQUEST = 'astacta/calls/submit/request';
const CALL_SUBMIT_SUCCESS = 'astacta/calls/submit/success';

const SINGLE_CALL_REQUEST = 'astacta/single_call/request';
const SINGLE_CALL_SUCCESS = 'astacta/single_call/success';

const CALL_CLOSE_REQUEST = 'astacta/close_call/request';
const CALL_CLOSE_SUCCESS = 'astacta/clase_call/success';

// Action creators
const requestCalls = (cb, which_calls) => ({
    type: CALLS_REQUEST,
    cb: cb,
    which_calls: which_calls
});

const receiveCalls = (calls, cb) => ({
    type: CALLS_SUCCESS,
    cb: cb,
    calls: calls
});

const requestSingleCall = (id, cb) => ({
    id: id,
    cb: cb,
    type: SINGLE_CALL_REQUEST
});

const receiveSingleCall = (call, cb) => ({
    currentCall: call,
    cb: cb,
    type: SINGLE_CALL_SUCCESS,
});

const requestSubmitCall = (data, onSuccess) => ({
    type: CALL_SUBMIT_REQUEST,
    data,
    onSuccess
});

const receiveSubmitCall = (response, onSuccess) => ({
    ...response,
    type: CALL_SUBMIT_SUCCESS,
    onSuccess: onSuccess
});

const requestCloseCall = (callId, onSuccess) => ({
    callId: callId,
    onSuccess: onSuccess,
    type: CALL_CLOSE_REQUEST
});

const receiveCloseCall = (onSuccess) => ({
    onSuccess: onSuccess,
    type: CALL_CLOSE_SUCCESS
});

// Sagas
/***
 * Fetches the list of calls from the server
 * @returns
 */
function* fetchCalls({cb, which_calls}) {
    if (which_calls === 'open') {
        var url = '/calls/open/';
    } else if (which_calls === 'closed') {
        url = '/calls/closed/';
    } else {
        url = '/calls/';
    }
    try {
        let response = yield call(get, url);
        // Fix the date times for each call
        response = Enumerable.from(response).select((call) => {
            call.commencement = new Date(call.commencement);
            call.termination = new Date(call.termination);
            call.reviewCommencement = new Date(call.reviewCommencement);
            call.reviewTermination = new Date(call.reviewTermination);
            call.created = new Date(call.created);
            call.lastModified = new Date(call.lastModified);
            return call;
        }).toArray();
        yield put(receiveCalls(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches a single call from the server
 * @returns
 */
function* fetchSingleCall({id, cb}) {
    // We don't have the call yet, so let's fetch the call from the server
    try {
        const response = yield call(get, `/calls/${id}/`);

        // Convert the datetimes
        response.commencement = new Date(response.commencement);
        response.termination = new Date(response.termination);
        response.reviewCommencement = new Date(response.reviewCommencement);
        response.reviewTermination = new Date(response.reviewTermination);
        response.created = new Date(response.created);
        response.lastModified = new Date(response.lastModified);
        // Process receiving the response
        yield put(receiveSingleCall(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Submits the new call form data to the server
 * @returns
 */
function* submitCall({data, onSuccess}) {
    try {
        const response = yield call(post, '/new_call/', data);
        yield put(receiveSubmitCall(response, onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Closes a call
 * @returns
 */
function* closeCall({callId, onSuccess}) {
    try {
        yield call(post, '/close_call/', {callId: callId});
        yield put(receiveCloseCall(onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(CALLS_REQUEST, fetchCalls);
}

function* watchSubmit() {
    yield takeEvery(CALL_SUBMIT_REQUEST, submitCall);
}

function* watchSingle() {
    yield takeEvery(SINGLE_CALL_REQUEST, fetchSingleCall);
}

function* watchCloseCall() {
    yield takeEvery(CALL_CLOSE_REQUEST, closeCall);
}

const sagas = [
    watchFetch,
    watchSubmit,
    watchSingle,
    watchCloseCall
];

// Reducers
const path = 'calls';
const reducer = (state = {
    all: Enumerable.empty(),
    currentCall: null
}, action = {}) => {
    switch (action.type) {
    case CALLS_SUCCESS:
        state = {
            ...state,
            all: Enumerable.from(action.calls).select((c) => new CallForProposal(c))
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;

    case SINGLE_CALL_SUCCESS:
        state = {
            ...state,
            currentCall: new CallForProposal(action.currentCall)
        };

        if (action.cb)
            defer(() => action.cb(state.currentCall));

        break;

    case CALL_CLOSE_SUCCESS:
        if (action.onSuccess)
            defer(() => action.onSuccess());
        break;

    case CALL_SUBMIT_SUCCESS:
        if (action.onSuccess)
            defer(() => action.onSuccess(action.callId));
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestCalls,
    requestSubmitCall,
    requestSingleCall,
    requestCloseCall
};
