import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestDeleteUserCommitteeRole, requestSingleUser, requestUserCommitteeRoles} from '../../stores/users/store';
import {Button, PageHeader, Table} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCommittees} from '../../stores/committees/store';
import {defer, navigate} from '../../utils/generalUtils';
import PermissionDenied from '../PermissionDenied';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';

class EditUser extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        // Ask the server for the details for the current user
        this.props.requestSingleUser(this.props.match.params.userId, () =>
            // Ask the server for the committee roles for this user
            this.props.requestUserCommitteeRoles(this.props.match.params.userId, () =>
                // Fetch all committees
                this.props.requestCommittees(() =>
                    this.setState({
                        isLoading: false
                    })
                )
            )
        );
    }

    renderUserDetails() {
        return (
            <Table bordered condensed hover>
                <tbody>
                    <tr>
                        {/* Display the committee */}
                        <td>User Name</td>
                        <td>{this.props.user.username}</td>
                    </tr>
                    <tr>
                        <td>Email Address</td>
                        <td>{this.props.user.email}</td>
                    </tr>
                    <tr>
                        <td>Institution</td>
                        <td>{this.props.user.institution}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{this.props.user.gender}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>{this.props.user.phone_number}</td>
                    </tr>
                    <tr>
                        <td>Is Student</td>
                        <td>{this.props.user.is_student ? 'Yes' : 'No'}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    renderUserCommitteeRoles() {
        const roles = this.props.committeeRoles.select(
            (role) => (
                <tr key={role.id}>
                    <td>
                        {this.props.committees.firstOrDefault((c) => c.id === role.committeeId).name}
                    </td>
                    <td>
                        {role.role}
                    </td>
                    <td>
                        {role.commencement.toString()}
                    </td>
                    <td>
                        {role.termination.toString()}
                    </td>
                    <td>
                        {role.isCurrent() ? 'Yes' : 'No'}
                    </td>
                    {this.props.localUser.isAdmin() ? (
                        <td>
                            <Link to={`/users/${this.props.user.id}/roles/${role.id}/`}>Edit</Link>
                        </td>
                    ) : null}
                    {this.props.localUser.isAdmin() ? (
                        <td>
                            <Button onClick={
                                () => this.props.requestDeleteUserCommitteeRole(
                                    role.id,
                                    () => defer(() =>
                                    // Ask the server for the committee roles for this user
                                        this.props.requestUserCommitteeRoles(this.props.user.id)
                                    )
                                )}
                            className="pull-right btn-danger">Delete</Button>
                        </td>
                    ) : null}
                </tr>
            )
        ).toArray();

        return (
            <Table bordered condensed hover>
                <thead>
                    <tr>
                        <td>Committee</td>
                        <td>Role</td>
                        <td>Commencement Date</td>
                        <td>Termination Date</td>
                        <td>Current?</td>
                        {this.props.localUser.isAdmin() ? (
                            <td>Edit</td>
                        ) : null}
                        {this.props.localUser.isAdmin() ? (
                            <td>Delete</td>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {roles}
                </tbody>
            </Table>
        );
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Users',
                            url: '/users'
                        },
                        {
                            name: this.props.user.username,
                            url: `/users/${this.props.user.id}`
                        }
                    ]
                }/>

                <PageHeader>
                    Edit User - {this.props.user.username}
                </PageHeader>

                {/* User details */}
                <h4>User Details</h4>
                {this.renderUserDetails()}
                <hr/>

                {/* User committee roles*/}
                <div>
                    <h4>Committee Roles</h4>
                </div>
                {this.renderUserCommitteeRoles()}
                {this.props.localUser.isAdmin() ? (
                    <Button className="pull-right"
                        onClick={() => navigate(this, `/users/${this.props.user.id}/roles/new`)}>Add
                        Role</Button>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({users, committees, auth}) => ({
    user: users.currentUser,
    // proposals: users.proposals,
    // reviews: users.reviews,
    committeeRoles: users.currentUserCommitteeRoles,
    committees: committees,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestSingleUser,
    requestUserCommitteeRoles,
    requestCommittees,
    requestDeleteUserCommitteeRole
})(EditUser);
