import React, {Component} from 'react';

class JsonSchemaTextArea extends Component {
    wordCount = (text) => {
        const re = (text || '').match(/\w+/g);
        return re ? re.length : 0;
    }

    handleChange = (event) => {
        let newValue = event.target.value;

        // If there is a word count option provided to the widget, then while the word count is exceeded,
        // trim characters until the word count is reached
        if (this.props.options.max_word_count) {
            while (this.wordCount(newValue) > this.props.options.max_word_count) {
                newValue = newValue.slice(0, -1);
            }
        }

        this.props.onChange(newValue && newValue.length ? newValue : undefined);
    }

    render() {
        const {value, onBlur, options} = this.props;
        return (
            <div>
                <textarea
                    style={{
                        width: '100%'
                    }}
                    value={value}
                    onBlur={onBlur}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                />
                {
                    options && options.max_word_count ?
                        <p>Word count: {this.wordCount(value)}/{options.max_word_count}</p>
                        : <p>Word count: Unlimited</p>
                }
            </div>
        );
    }
}

export default JsonSchemaTextArea;
