import React, {Component} from 'react';
import {Link} from 'react-router-dom';


import {requestConfirmPasswordReset} from '../../stores/auth/store';
import {connect} from 'react-redux';
import {Button, Col, Image, Panel} from 'react-bootstrap';
import ServerErrors from '../../utils/ServerErrors';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapInput} from '../../utils/BootstrapInputs';
import logo from '../../assets/images/logo.png';

// import {Route, Link} from 'react-router-dom'

class ConfirmResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            done: false
        };
    }

    render() {
        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <div className="text-center">
                        <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                        <h1>Proposal System</h1>
                    </div>

                    <Panel header={<h3>Password Reset</h3>} className="login-panel">
                        {this.state.done ? (
                            <p>Your password has been reset. Return to <Link to="/auth/login">login.</Link></p>
                        ) : (
                            <div>
                                <ServerErrors errors={this.props.errors}/>
                                <LocalForm
                                    onSubmit={
                                        ({password1, password2}) => {
                                            this.props.requestConfirmPasswordReset(
                                                password1,
                                                password2,
                                                this.props.match.params.uidb64,
                                                this.props.match.params.token,
                                                () => {
                                                    this.setState({
                                                        done: true
                                                    });
                                                });
                                        }
                                    }
                                    initialState={{password1: '', password2: ''}}>
                                    <Control
                                        placeholder="Password"
                                        component={BootstrapInput}
                                        model=".password1"
                                        required
                                        type="password"
                                        maxLength={1000}
                                    />
                                    <Control
                                        placeholder="Confirm Password"
                                        component={BootstrapInput}
                                        model=".password2"
                                        required
                                        type="password"
                                        maxLength={1000}
                                    />
                                    <Button
                                        type="submit"
                                        bsSize="large"
                                        bsStyle="success"
                                        block
                                    >
                                        Reset Password
                                    </Button>
                                </LocalForm>
                            </div>
                        )}
                    </Panel>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = ({misc}) => ({
    errors: misc.errors
});

export default connect(mapStateToProps, {
    requestConfirmPasswordReset,
})(ConfirmResetPassword);
