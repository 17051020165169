import React, {Component} from 'react';
import {connect} from 'react-redux';

import ServerErrors from '../../utils/ServerErrors';
import {requestRegister} from '../../stores/auth/store';
import {defer, navigate} from '../../utils/generalUtils';
import {Button, Col, Image, Panel} from 'react-bootstrap';
import {Control, LocalForm} from 'react-redux-form';
import {
    BootstrapCheckbox, BootstrapInput, BootstrapSelect, isLdapSafe, isValidEmail,
    isValidPhone
} from '../../utils/BootstrapInputs';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo.png';

// Define the Register component
class Register extends Component {
    constructor() {
        super();

        this.state = {};
    }

    render() {
        // Retrieve the props we need to use in this component
        const {dispatch, errors, loggedIn} = this.props;

        // Check if the user is actually already logged in
        if (loggedIn) {
            // The user is already logged in, instead we should present nothing
            return null;
        }

        // Render the registration form
        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <div className="text-center">
                        <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                        <h1>Proposal System</h1>
                    </div>

                    <Panel header={<h3>Register</h3>} className="login-panel">
                        <ServerErrors errors={errors}/>
                        <LocalForm
                            onSubmit={
                                (data) => {
                                    const _this = this;
                                    const google_recaptcha = grecaptcha; // eslint-disable-line
                                    google_recaptcha.ready(function () {
                                        google_recaptcha.execute(
                                            '6Lc2Lv0lAAAAAD2nAzd1rOaaK7iLGNI270ZLwN6f',
                                            {action: 'submit'}
                                        ).then(function (token) {
                                            // Dispatch a registration request
                                            dispatch(
                                                requestRegister({...data, captcha: token}, () => {
                                                    defer(() => navigate(_this, '/auth/register/complete'));
                                                })
                                            );
                                        });
                                    });
                                }
                            }
                            onChange={(data) => {
                                this.setState(
                                    {
                                        ...this.state,
                                        ...data
                                    }
                                );
                            }}
                            initialState={{
                                first_name: '',
                                last_name: '',
                                username: '',
                                password1: '',
                                password2: '',
                                email: '',
                                institution: '',
                                gender: '',
                                phone_number: '',
                                is_student: false
                            }}
                            validators={{
                                '': {
                                    validFirstName: (vals) => isLdapSafe(vals.first_name),
                                    validLastName: (vals) => isLdapSafe(vals.last_name),
                                    validUsername: (vals) => isLdapSafe(vals.username),
                                    validEmail: (vals) => isValidEmail(vals.email) && isLdapSafe(vals.email),
                                    validInstitution: (vals) => isLdapSafe(vals.institution),
                                    validPhone:
                                        (vals) => isValidPhone(vals.phone_number) && isLdapSafe(vals.phone_number)
                                }
                            }}>
                            <Control
                                placeholder="First Name"
                                component={BootstrapInput}
                                model=".first_name"
                                required
                                maxLength={30}
                                error={
                                    !isLdapSafe(this.state.first_name) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : null
                                }
                            />
                            <Control
                                placeholder="Last Name"
                                component={BootstrapInput}
                                model=".last_name"
                                required
                                maxLength={30}
                                error={
                                    !isLdapSafe(this.state.last_name) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : null
                                }
                            />
                            <Control
                                placeholder="Username"
                                component={BootstrapInput}
                                model=".username"
                                required
                                maxLength={150}
                                error={
                                    !isLdapSafe(this.state.username) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : null
                                }
                            />
                            <Control
                                placeholder="Email Address"
                                component={BootstrapInput}
                                model=".email"
                                required
                                type="email"
                                maxLength={254}
                                error={
                                    !isLdapSafe(this.state.email) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : !isValidEmail(this.state.email) ?
                                            'Please enter a valid email address' : null
                                }
                            />
                            <Control
                                placeholder="Password"
                                component={BootstrapInput}
                                model=".password1"
                                required
                                type="password"
                                maxLength={1000}
                            />
                            <Control
                                placeholder="Confirm Password"
                                component={BootstrapInput}
                                model=".password2"
                                required
                                type="password"
                                maxLength={1000}
                            />
                            <Control
                                placeholder="Institution"
                                component={BootstrapInput}
                                model=".institution"
                                required
                                maxLength={100}
                                error={
                                    !isLdapSafe(this.state.institution) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : null
                                }
                            />
                            <Control
                                placeholder="Gender"
                                component={BootstrapSelect}
                                options={[
                                    (<option key={0} value="">---</option>),
                                    (<option key={1} value="MALE">Male</option>),
                                    (<option key={2} value="FEMALE">Female</option>),
                                    (<option key={3} value="OTHER">Other/Prefer not to say</option>),
                                ]}
                                model=".gender"
                                required
                            />
                            <Control
                                placeholder="Phone Number"
                                component={BootstrapInput}
                                model=".phone_number"
                                required
                                maxLength={30}
                                error={
                                    !isLdapSafe(this.state.phone_number) ?
                                        '\\ , : ? | * & ; # < > " = [ ] { } ( ) ' +
                                        'are restricted characters for this field.'
                                        : !isValidPhone(this.state.phone_number) ?
                                            'Please enter a valid phone number' : null
                                }
                            />
                            <Control.checkbox
                                label="Are you a student?"
                                component={BootstrapCheckbox}
                                model=".is_student"
                            />
                            <p>Already have an account? <Link to="/auth/login">Login</Link></p>
                            <Button type="submit" bsSize="large" bsStyle="success" block>Register</Button>
                            <small className="form-text text-muted">
                                This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy"> Privacy Policy </a>
                                and
                                <a href="https://policies.google.com/terms"> Terms of Service </a>
                                apply.
                            </small>
                        </LocalForm>
                    </Panel>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = ({misc, auth}) => ({
    errors: misc.errors,
    loggedIn: auth.loggedIn
});

// Connect the Register component to the dispatcher
const WrappedRegister = connect(mapStateToProps)(Register);

// Export the Register component from this module
export default WrappedRegister;
