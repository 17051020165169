import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestCloseCall, requestSingleCall} from '../../stores/calls/store';
import {Button, Modal, PageHeader, Table} from 'react-bootstrap';
import {defer, navigate} from '../../utils/generalUtils';
import Breadcrumbs from '../../components/Breadcrumbs';
import PermissionDenied from '../PermissionDenied';
import {requestAllProposals} from '../../stores/proposals/store';
import PageLoading from '../../components/PageLoading';


class ManageCall extends Component {
    constructor() {
        super();

        this.state = {
            showCloseCallModal: false,
            isLoading: true
        };
    }

    componentWillMount() {
        // Request the call from the server
        this.props.requestSingleCall(this.props.match.params.callId, () =>
            // Request the proposals for this call from the server
            this.props.requestAllProposals(this.props.match.params.callId, () =>
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            )
        );
    }

    onRowClick(row) {
        // Navigate to the proposal selected by the user
        navigate(this, `/calls/${row.callId}/proposals/${row.id}/`);
    }

    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        // Prevent users that are not chairs from managing a proposal
        if (!this.props.localUser.isChair(this.props.call.committee))
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: this.props.call.name,
                            url: `/calls/${this.props.call.id}/`
                        },
                        {
                            name: 'Manage',
                            url: `/calls/${this.props.call.id}/manage`
                        }
                    ]
                }/>

                <PageHeader>
                    Call for Proposal - {this.props.call.name}
                    <div className="pull-right">
                        <Button
                            disabled={!this.props.call.canCloseCall() || this.props.call.closed}
                            onClick={() => this.setState(
                                {
                                    ...this.state,
                                    showCloseCallModal: true
                                }
                            )}>Close Call for Proposal</Button>
                    </div>
                </PageHeader>

                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                Number of Proposals
                            </td>
                            <td>
                                {/* Count the submitted proposals */}
                                {this.props.proposals.count(p => p.stateVal >= 1)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Proposals awaiting Review
                            </td>
                            <td>
                                {/* Count the number of submitted proposals that are not reviewed */}
                                {this.props.proposals.count(p => p.stateVal >= 1 && !p.isReviewed)}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {this.state.showCloseCallModal ? (
                    <Modal.Dialog bsSize="large">
                        <Modal.Header>
                            <Modal.Title>Close Call for Proposal</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>Are you sure you want to close this call for proposal?</p>
                            <p>This will make any decision summaries available to authors.</p>
                            {
                                this.props.proposals.count(p => p.stateVal >= 1 && !p.isReviewed) ? (
                                    <p className="text-danger">
                                        <b>
                                            WARNING: There are still
                                            {this.props.proposals.count(p => p.stateVal >= 1 && !p.isReviewed)}
                                            proposals without reviews.
                                        </b>
                                    </p>
                                ) : null
                            }
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => this.setState({
                                ...this.state,
                                showCloseCallModal: false
                            })}>Cancel</Button>
                            <Button onClick={() => {
                                this.setState({
                                    ...this.state,
                                    showCloseCallModal: false
                                });

                                this.props.requestCloseCall(this.props.call.id,
                                    () => defer(() => {
                                        // Request the call from the server
                                        this.props.requestSingleCall(this.props.match.params.callId);

                                        // Request the proposals for this call from the server
                                        this.props.requestAllProposals(this.props.match.params.callId);
                                    }));
                            }} className="btn-danger">Close Call</Button>
                        </Modal.Footer>

                    </Modal.Dialog>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps =
    ({
        calls,
        auth,
        proposals
    }) => ({
        call: calls.currentCall,
        localUser: auth.localUser,
        proposals: proposals.all
    });

export default connect(mapStateToProps, {
    requestSingleCall,
    requestAllProposals,
    requestCloseCall
})(ManageCall);
