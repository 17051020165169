import {call, put, takeEvery} from 'redux-saga/effects';
import {get} from '../../utils/api';
import Enumerable from 'linq';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const REVIEW_REQUEST = 'astacta/reviews/request';
const REVIEW_SUCCESS = 'astacta/reviews/success';

const ALL_REVIEWS_REQUEST = 'astacta/allreviews/request';
const ALL_REVIEWS_SUCCESS = 'astacta/allreviews/success';

const PENDING_REVIEWS_REQUEST = 'astacta/pendingreviews/request';
const PENDING_REVIEWS_SUCCESS = 'astacta/pendingreviews/success';

// Action creators
const requestReview = (proposalId, reviewId, cb, cbError) => ({
    type: REVIEW_REQUEST,
    proposalId,
    reviewId,
    cb,
    cbError
});

const receiveReview = (review, cb) => ({
    result: review,
    cb: cb,
    type: REVIEW_SUCCESS,
});

const requestAllReviews = (callId, proposalId, cb) => ({
    type: ALL_REVIEWS_REQUEST,
    callId: callId,
    proposalId: proposalId,
    cb: cb
});

const receiveAllReviews = (allReviews, cb) => ({
    type: ALL_REVIEWS_SUCCESS,
    allReviews: allReviews,
    cb: cb
});

const requestPendingReviews = (cb) => ({
    type: PENDING_REVIEWS_REQUEST,
    cb: cb
});

const receivePendingReviews = (allReviews, cb) => ({
    allReviews: allReviews,
    type: PENDING_REVIEWS_SUCCESS,
    cb: cb
});

// Sagas
/***
 * Fetches a form definition from the server
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* fetchReview({proposalId, reviewId, cb, cbError}) {
    try {
        const response = yield call(get, `/reviews/${proposalId}/${reviewId}/`);

        yield put(receiveReview(response, cb));
    } catch (error) {
        if (cbError)
            cbError();

        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches all reviews from the server
 * @returns
 */
function* fetchAllReviews({callId, proposalId, cb}) {
    try {
        const response = yield call(get, `/calls/${callId}/proposals/${proposalId}/reviews/`);

        yield put(receiveAllReviews(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches all pending reviews from the server for this user
 * @returns
 */
function* fetchPendingReviews({cb}) {
    try {
        const response = yield call(get, '/pending_reviews/');

        yield put(receivePendingReviews(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(REVIEW_REQUEST, fetchReview);
}

function* watchAllReview() {
    yield takeEvery(ALL_REVIEWS_REQUEST, fetchAllReviews);
}

function* watchPendingReview() {
    yield takeEvery(PENDING_REVIEWS_REQUEST, fetchPendingReviews);
}

const sagas = [
    watchFetch,
    watchAllReview,
    watchPendingReview
];

// Reducers
const path = 'reviews';
const reducer = (state = {
    all: Enumerable.empty(),
    pending: Enumerable.empty()
}, action = {}) => {
    switch (action.type) {
    case ALL_REVIEWS_SUCCESS:
        state = {
            ...state,
            all: Enumerable.from(action.allReviews)
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;
    case PENDING_REVIEWS_SUCCESS:
        state = {
            ...state,
            pending: Enumerable.from(action.allReviews)
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;
    case REVIEW_SUCCESS:
        let all = state.all.where(r => r.id !== action.result.id);
        state = {
            ...state,
            all: all.concat(action.result)
        };

        if (action.cb)
            defer(() => action.cb(action.result));

        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestReview,
    requestAllReviews,
    requestPendingReviews,
};
