import {call, put, race, select, take, takeEvery} from 'redux-saga/effects';

import * as auth from './api.js';

import {get, post} from '../../utils/api';
import LocalUser from './localUser';
import {REQUEST_ERROR} from '../misc/store';

// Action types
const LOGIN_REQUEST = 'astacta/auth/login/request';
const LOGIN_RESPONSE = 'astacta/auth/login/response';
const LOGOUT_REQUEST = 'astacta/auth/logout/request';
const PASSWORD_RESET_REQUEST = 'astacta/auth/password_reset/request';
const PASSWORD_RESET_SUCCESS = 'astacta/auth/password_reset/response';
const PASSWORD_RESET_CONFIRM_REQUEST = 'astacta/auth/password_reset_confirm/request';
const PASSWORD_RESET_CONFIRM_SUCCESS = 'astacta/auth/password_reset_confirm/response';
const PASSWORD_CHANGE_REQUEST = 'astacta/auth/password_change/request';
const PASSWORD_CHANGE_SUCCESS = 'astacta/auth/password_change/response';
const REGISTER_REQUEST = 'astacta/auth/register/request';
const REGISTER_VERIFY_REQUEST = 'astacta/auth/register_verify/request';

const SENDING_REQUEST = 'astacta/auth/sending_request';

const SET_AUTH = 'astacta/auth/set_auth';

const USER_DETAILS_REQUEST = 'astacta/auth/user_details/request';
const USER_DETAILS_RESPONSE = 'astacta/auth/user_details/response';

// Action creators
const requestLogin = (username, password) => ({
    type: LOGIN_REQUEST,
    username,
    password,
});

const receiveLogin = (response) => ({
    type: LOGIN_RESPONSE,
    response: response
});

const requestLogout = () => ({
    type: LOGOUT_REQUEST,
});

const requestPasswordReset = (email, cb) => ({
    type: PASSWORD_RESET_REQUEST,
    email: email,
    cb: cb
});

const receivePasswordReset = (cb) => ({
    type: PASSWORD_RESET_SUCCESS,
    cb: cb
});

const requestConfirmPasswordReset = (password1, password2, user, token, cb) => ({
    type: PASSWORD_RESET_CONFIRM_REQUEST,
    password1: password1,
    password2: password2,
    user: user,
    token: token,
    cb: cb
});

const receiveConfirmPasswordReset = (cb) => ({
    type: PASSWORD_RESET_CONFIRM_SUCCESS,
    cb: cb
});

const requestPasswordChange = (oldpassword, newpassword1, newpassword2, cb) => ({
    type: PASSWORD_CHANGE_REQUEST,
    oldpassword,
    newpassword1,
    newpassword2,
    cb
});

const receivePasswordChange = (cb) => ({
    type: PASSWORD_CHANGE_SUCCESS,
    cb: cb
});

const requestRegister = (data, cb = null) => ({
    type: REGISTER_REQUEST,
    cb,
    ...data
});

const requestRegisterVerify = (key, cb) => ({
    type: REGISTER_VERIFY_REQUEST,
    key,
    cb
});

const requestUserDetails = (cb) => ({
    type: USER_DETAILS_REQUEST,
    cb: cb
});

const receiveUserDetails = (details, cb) => ({
    details: details,
    cb: cb,
    type: USER_DETAILS_RESPONSE,
});


// Sagas
// Adapted from https://github.com/sotojuan/saga-login-flow/blob/master/app/sagas/index.js

/**
 * Effect to handle authorization
 * @param  {string} username               The username of the user
 * @param  {string} password               The password of the user
 * @param password1
 * @param password2
 * @param  {string} email
 * @param  {boolean} isRegistering Is this a register request?
 */
export function* authorize(data) {
    // We send an action that tells Redux we're sending a request
    yield put({type: SENDING_REQUEST, sending: true});

    // We then try to register or log in the user, depending on the request
    try {
        let response;

        // For either log in or registering, we call the proper function in the `auth`
        // module, which is asynchronous. Because we're using generators, we can work
        // as if it's synchronous because we pause execution until the call is done
        // with `yield`!
        // Clean up the auth token.
        delete localStorage.token;

        if (data.isRegistering) {
            response = yield call(auth.register, data);
        } else if (data.isVerifying) {
            response = yield call(auth.registerVerify, data.key);
        } else {
            response = yield call(auth.login, data.username, data.password);
            yield put(receiveLogin(response));
        }

        return response;
    } catch (error) {
        // If we get an error we send Redux the appropiate action and return
        yield put({type: REQUEST_ERROR, errors: error.json || error.message});

        return false;
    } finally {
        // When done, we tell Redux we're not in the middle of a request any more
        yield put({type: SENDING_REQUEST, sending: false});
    }
}

/**
 * Effect to handle logging out
 */
function* logout() {
    // We tell Redux we're in the middle of a request
    yield put({type: SENDING_REQUEST, sending: true});

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        let response = yield call(auth.logout);
        yield put({type: SENDING_REQUEST, sending: false});

        return response;
    } catch (error) {
        yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/**
 * Log in saga
 */
function* loginFlow() {
    // Because sagas are generators, doing `while (true)` doesn't block our program
    // Basically here we say "this saga is always listening for actions"
    while (true) {
        // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
        let request = yield take(LOGIN_REQUEST);
        let {username, password} = request;

        // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
        // lead to a race condition. This is unlikely, but just in case, we call `race` which
        // returns the "winner", i.e. the one that finished first
        let winner = yield race({
            auth: call(authorize, {username, password, isRegistering: false}),
            logout: take(LOGOUT_REQUEST)
        });

        // If `authorize` was the winner...
        if (winner.auth) {
            // ...we send Redux appropiate actions
            yield put({type: SET_AUTH, newAuthState: true}); // User is logged in (authorized)
            // yield put({type: CHANGE_FORM, newFormState: {username: '', password: ''}}) // Clear form
            // forwardTo('/dashboard') // Go to dashboard page
        }
    }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
function* logoutFlow() {
    while (true) {
        yield take(LOGOUT_REQUEST);
        yield call(logout);
        yield put({type: SET_AUTH, newAuthState: false});

        // forwardTo('/')
    }
}

/**
 * Register saga
 * Very similar to log in saga!
 */
function* registerFlow() {
    while (true) {
        // We always listen to `REGISTER_REQUEST` actions
        let request = yield take(REGISTER_REQUEST);

        // We call the `authorize` task with the data, telling it that we are registering a user
        // This returns `true` if the registering was successful, `false` if not
        let wasSuccessful = yield call(authorize, {...request, isRegistering: true});

        // If we could register a user, we send the appropriate actions
        if (wasSuccessful) {
            // Notify the caller that the registration was successful
            if (request.cb)
                request.cb();

            //yield put({type: SET_AUTH, newAuthState: true}) // User is logged in (authorized) after being registered
            // yield put({type: CHANGE_FORM, newFormState: {username: '', password: ''}}) // Clear form
            // forwardTo('/dashboard') // Go to dashboard page
        }
    }
}

/**
 * Register verify saga
 * @type {string}
 */
function* registerVerifyFlow() {
    while (true) {
        let request = yield take(REGISTER_VERIFY_REQUEST);

        // We call the `authorize` task with the data, telling it that we are registering a user
        // This returns `true` if the registering was successful, `false` if not
        let response = yield call(authorize, {...request, isVerifying: true});

        // Check that a success callback was specified
        if (request.cb)
            request.cb(response);

        yield put({type: SET_AUTH, newAuthState: true}); // User is logged in (authorized) after being registered
        // yield put({type: CHANGE_FORM, newFormState: {username: '', password: ''}}) // Clear form
        // forwardTo('/dashboard') // Go to dashboard page

    }
}

/***
 * Asks for the details of the currently logged in user
 * @returns
 */
function* fetchUserDetails({cb}) {
    // Check that the user is actually logged in
    const state = yield select();

    // Don't do anything if the user is not logged in
    if (!state.auth.loggedIn)
        return;

    try {
        const response = yield call(get, '/user_details/');
        yield put(receiveUserDetails(response, cb));
    } catch (error) {
        // Check if user is not authorized (Means the token is invalid, or login required)
        // This works because fetchUserDetails is called on every page load.
        if (error.response.status === 401)
        {
            yield logout();
        }
        else {
            return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
        }
    }
}

/***
 * Asks for the server to send a password reset email
 * @returns
 */
function* fetchPasswordReset({email, cb}) {
    try {
        yield call(post, '/auth/password/reset/', {email: email});
        yield put(receivePasswordReset(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to change the users password using the token from a reset email
 * @returns
 */
function* fetchConfirmPasswordReset({password1, password2, user, token, cb}) {
    try {
        yield call(post, '/auth/password/reset/confirm/',
            {newPassword1: password1, newPassword2: password2, uid: user, token: token}
        );
        yield put(receiveConfirmPasswordReset(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to change the users password
 * @returns
 */
function* fetchPasswordChange({oldpassword, newpassword1, newpassword2, cb}) {
    try {
        yield call(post, '/auth/password/change/',
            {oldPassword: oldpassword, newPassword1: newpassword1, newPassword2: newpassword2}
        );
        yield put(receivePasswordChange(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchUserDetails() {
    yield takeEvery(USER_DETAILS_REQUEST, fetchUserDetails);
}

function* watchConfirmPasswordReset() {
    yield takeEvery(PASSWORD_RESET_CONFIRM_REQUEST, fetchConfirmPasswordReset);
}

function* watchPasswordReset() {
    yield takeEvery(PASSWORD_RESET_REQUEST, fetchPasswordReset);
}

function* watchChangePassword() {
    yield takeEvery(PASSWORD_CHANGE_REQUEST, fetchPasswordChange);
}

// Export stuff
const path = 'auth';

const defaultState = {
    sending: false,
    loggedIn: auth.loggedIn(),
    localUser: new LocalUser({}),
};
const reducer = (state = defaultState, action) => {
    state.loggedIn = auth.loggedIn();
    switch (action.type) {
    case (SENDING_REQUEST):
        state = {
            ...state,
            sending: action.sending,
        };
        break;
    case (SET_AUTH):
        state = {
            ...state,
            loggedIn: false,
            error: false,
            localUser: new LocalUser({}),
        };
        break;
    case LOGIN_RESPONSE:
        localStorage.token = action.response.key;
        break;
    case USER_DETAILS_RESPONSE:
        const localUser = new LocalUser(action.details);
        state = {
            ...state,
            localUser: localUser
        };

        if (action.cb)
            action.cb(localUser);

        break;
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_CHANGE_SUCCESS:
        if (action.cb)
            action.cb();
        break;
    default:

    }
    return state;
};

const selector = state => state[path];

const sagas = [
    loginFlow,
    logoutFlow,
    registerFlow,
    registerVerifyFlow,
    watchUserDetails,
    watchPasswordReset,
    watchConfirmPasswordReset,
    watchChangePassword
];

export {
    path,
    reducer,
    sagas,

    selector,

    requestLogin,
    requestLogout,
    requestRegister,
    requestRegisterVerify,
    requestUserDetails,
    requestPasswordReset,
    requestConfirmPasswordReset,
    requestPasswordChange,

    SET_AUTH,
};
