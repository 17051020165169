import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, PageHeader} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCreateCommittee} from '../../stores/committees/store';
import {defer, navigate} from '../../utils/generalUtils';
import PermissionDenied from '../PermissionDenied';
import {BootstrapInput} from '../../utils/BootstrapInputs';
import {Control, LocalForm} from 'react-redux-form';

class NewCommittee extends Component {
    render() {
        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Committees',
                            url: '/committees'
                        },
                        {
                            name: 'New',
                            url: '/committees/new'
                        }
                    ]
                }/>

                <PageHeader>
                    New Committee
                </PageHeader>

                <LocalForm initialState={{name: ''}}
                    onSubmit={
                        ({name}) => {
                            this.props.requestCreateCommittee(name, () => {
                                defer(navigate(this, '/committees/'));
                            });
                        }
                    }
                >

                    {/* Committee name */}
                    <Control
                        label="Committee Name"
                        component={BootstrapInput}
                        model=".name"
                        required
                        maxLength={100}
                    />

                    {/* Submit button */}
                    <Button bsStyle="primary" className="pull-right" type="submit">Save</Button>
                </LocalForm>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestCreateCommittee
})(NewCommittee);