import Enumerable from 'linq';

// Possible types of forms
const FORM_TYPE_PROPOSAL = 0;
const FORM_TYPE_MULTI = 1;
const FORM_TYPE_REVIEW = 2;
const FORM_TYPE_REVIEW_SCORE = 3;
const FORM_TYPE_PROPOSAL_RESOURCE = 4;
const FORM_TYPE_PROPOSAL_RESOURCE_MULTI = 5;

const PROPOSAL_FORM_TYPES = Enumerable.from(
    [
        FORM_TYPE_PROPOSAL,
        FORM_TYPE_MULTI,
        FORM_TYPE_PROPOSAL_RESOURCE,
        FORM_TYPE_PROPOSAL_RESOURCE_MULTI
    ]
);

const REVIEW_FORM_TYPES = Enumerable.from(
    [
        FORM_TYPE_REVIEW,
        FORM_TYPE_REVIEW_SCORE
    ]
);

const MULTI_FORM_TYPES = Enumerable.from(
    [
        FORM_TYPE_MULTI,
        FORM_TYPE_PROPOSAL_RESOURCE_MULTI
    ]
);

const RESOURCE_FORM_TYPES = Enumerable.from(
    [
        FORM_TYPE_PROPOSAL_RESOURCE,
        FORM_TYPE_PROPOSAL_RESOURCE_MULTI
    ]
);

const FORM_TYPE_OPTIONS = Enumerable.from(
    [
        FORM_TYPE_REVIEW_SCORE,
        FORM_TYPE_PROPOSAL_RESOURCE,
        FORM_TYPE_PROPOSAL_RESOURCE_MULTI
    ]
);

let choices = {};
choices[FORM_TYPE_PROPOSAL] = 'Proposal Form';
choices[FORM_TYPE_MULTI] = 'Proposal Multi Form';
choices[FORM_TYPE_REVIEW] = 'Review Form';
choices[FORM_TYPE_REVIEW_SCORE] = 'Review Score Form';
choices[FORM_TYPE_PROPOSAL_RESOURCE] = 'Proposal Resource Form';
choices[FORM_TYPE_PROPOSAL_RESOURCE_MULTI] = 'Proposal Resource Multi Form';

const FORM_TYPE_CHOICES = Enumerable.from(choices);

export {
    FORM_TYPE_PROPOSAL,
    FORM_TYPE_MULTI,
    FORM_TYPE_REVIEW,
    FORM_TYPE_REVIEW_SCORE,
    FORM_TYPE_PROPOSAL_RESOURCE,
    FORM_TYPE_PROPOSAL_RESOURCE_MULTI,
    PROPOSAL_FORM_TYPES,
    REVIEW_FORM_TYPES,
    FORM_TYPE_CHOICES,
    MULTI_FORM_TYPES,
    FORM_TYPE_OPTIONS,
    RESOURCE_FORM_TYPES
};
