import React from 'react';
import {ListGroup, ListGroupItem, Table} from 'react-bootstrap';

// Create the proposal form list
function proposalForms(forms, getFormName)
{
    return forms.select((f) => (
        <ListGroupItem key={f.id}>
            {getFormName(f).name} {f.version}
        </ListGroupItem>
    ));
}

// Create the review form list
function reviewForms(forms, getFormName) {
    return forms.select((f) => (
        <ListGroupItem key={f.id}>
            {getFormName(f).name} {f.version}
        </ListGroupItem>
    ));
}

const CallDetails = (props) => 
    // Renders the review page of the new call for proposals
    (
        <div>
            {/* Display the proposal details */}
            <h4>Call for Proposals Details</h4>
            <Table striped bordered condensed hover>
                <tbody>
                    <tr>
                        {/* Display the committee */}
                        <td>Committee</td>
                        <td>{props.committee.name}</td>
                    </tr>
                    <tr>
                        {/* Display the proposal name */}
                        <td>Call for Proposals Name</td>
                        <td>{props.name}</td>
                    </tr>
                    <tr>
                        {/* Display the proposal open time */}
                        <td>Proposals Open Date/Time</td>
                        <td>{props.openDateTime.toString()}</td>
                    </tr>
                    <tr>
                        {/* Display the proposal close time*/}
                        <td>Proposals Close Date/Time</td>
                        <td>{props.closeDateTime.toString()}</td>
                    </tr>
                    <tr>
                        {/* Display the review open time */}
                        <td>Review Open Date/Time</td>
                        <td>{props.reviewOpenDateTime.toString()}</td>
                    </tr>
                    <tr>
                        {/* Display the review close time*/}
                        <td>Review Close Date/Time</td>
                        <td>{props.reviewCloseDateTime.toString()}</td>
                    </tr>
                    <tr>
                        {/* Display the proposal open/close review state */}
                        <td>Are Reviews Open or Closed?</td>
                        <td>{props.isReviewOpen ? 'Open' : 'Closed'}</td>
                    </tr>
                    <tr>
                        {/* Display the proposal description*/}
                        <td>Description</td>
                        {/* eslint-disable-next-line react/no-danger */}
                        <td dangerouslySetInnerHTML={{__html: props.description}}/>
                    </tr>
                </tbody>
            </Table>
            {/* Display the list of proposal forms */}
            {
                props.proposalTypes ? props.proposalTypes.select((e, i) => (
                    <div key={i}>
                        <hr/>
                        <h4>Form Structure for {e.typeName}</h4>
                        <p>Proposal</p>
                        <ListGroup>
                            {proposalForms(e.proposalForms, props.getFormName)}
                        </ListGroup>
                        <p>Review</p>
                        <ListGroup>
                            {reviewForms(e.reviewForms, props.getFormName)}
                        </ListGroup>
                    </div>
                )) : null
            }
        </div>
    )
;

export default CallDetails;