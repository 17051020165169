import React, {Component} from 'react';
import {Col, PageHeader, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {requestCalls} from '../../stores/calls/store';
import {requestCommittees} from '../../stores/committees/store';
import {requestPendingReviews} from '../../stores/reviews/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PermissionDenied from '../PermissionDenied';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import {formatDateTime} from '../../utils/generalUtils';

/* eslint no-unused-vars: 0 */

class PendingReviews extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestCommittees(() =>
            this.props.requestCalls(() =>
                this.props.requestPendingReviews(() =>
                    this.setState({
                        isLoading: false
                    })
                )
            )
        );
    }

    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isMember())
            return (<PermissionDenied/>);

        const items = [
            {
                name: 'Dashboard',
                url: '/'
            },
            {
                name: 'Pending Reviews',
                url: '/pending_reviews/'
            }
        ];

        let proposals;
        try {
            proposals = this.props.pendingReviews.select(p => {
                const call = this.props.calls.firstOrDefault(c => c.id === p.callId);
                const committee = this.props.committees.firstOrDefault(c => c.id === call.committee);
                return {
                    callId: call.id,
                    proposalId: p.id,
                    committee: committee.name,
                    call: call.name,
                    submission_deadline: call.reviewTermination,
                };
            }).toArray();
        } catch (e) {
            // If an error occurred, we're still waiting for data from the server
            return (
                <p>Loading...</p>
            );
        }

        return (
            <div>
                <Breadcrumbs items={items}/>
                <PageHeader>My Pending Reviews</PageHeader>
                <Row>
                    <Col md={12}>
                        <BootstrapTable data={proposals} striped search pagination hover>
                            <TableHeaderColumn dataField="committee">Committee</TableHeaderColumn>
                            <TableHeaderColumn dataField="call" isKey={true}>Call for Proposal Name</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="submission_deadline"
                                dataFormat={formatDateTime}
                            >
                                Review Deadline
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="view_proposal" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => (
                                    <Link to={`/calls/${row.callId}/proposals/${row.proposalId}/`}>
                                            View Proposal
                                    </Link>
                                )
                            }/>
                        </BootstrapTable>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({calls, auth, committees, proposals, reviews}) => 
    // Set the state accordingly
    ({
        calls: calls.all,
        localUser: auth.localUser,
        committees: committees,
        pendingReviews: reviews.pending
    })
;


export default connect(
    mapStateToProps,
    {
        requestCalls,
        requestCommittees,
        requestPendingReviews
    }
)(PendingReviews);
