import * as XLSX from 'xlsx';
import {humanizeLowerCaseWithUnderscores} from '../../utils/generalUtils';


const toXLSX = (data, callName, sheetName) => {
    // convert state to workbook
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    // generate XLSX file and send to client
    XLSX.writeFile(wb, `${callName}.xlsx`);
};

const processScoresData = (proposals) => 
// Process the score data for proposals.
// This results in an array of objects that each represent a line in the returned data.
// Using an object means that there is no way that the data can accidentally end up in the wrong column.
    proposals.select(p => { 
        const reviews = p.allReviewScores.select(score => {
            const author = score.key;
            // We want a single object that represents all the scores.
            // We reduce each score array to a single object entry that has 
            // "Key (Author Name): Score" for each type of score.
            return score.value.aggregate({}, (finalScores, score) => {
                if(score.key !== '__result__') {
                    const columnName = humanizeLowerCaseWithUnderscores(`${score.key} (${author})`);
                    const columnValue = score.value ? score.value : '';
                    finalScores[`${columnName}`] = columnValue;
                }
                return finalScores; 
            });
        });

        // Reduce all the reviews to a single flat object that can be easily merged with the final data object that 
        // represents a row in the table. 
        const reviewData = reviews.aggregate({}, (final, r) => ({...final, ...r}));

        return ({
            'Proposal Name': p.proposalName,
            'Author': p.author,
            'Author Email': p.authorEmail,
            ...reviewData,
        });
    }).toArray();



const processCommentsData = (proposals) => 
// Process the comment data for proposals.
// This results in a array of objects that each represent a line of a potential csv or xlsx file.
// Using an object means that with value > name mapping data can't end up in the wrong column.
    proposals.select(p => {
        const comments = p.allReviewComments.select(reviewComment => 
        // Each reviewComment has an abitrary number of potential keys that look like
        // "Concerns (Jarrod)". We want to flatten the array into a single object. 
            reviewComment.value.aggregate({}, (comments, s) => { 
                // The key will end up the column heading and should be in the format "Key (Author)".
                const column_header = `${s.key} (${reviewComment.key})`;
                const key = `${humanizeLowerCaseWithUnderscores(column_header)}`;
                comments[key] = s.value ? s.value : '';
                return comments; 
            })
        );

        // Reduce all the comments to a single flat object that can be easily merged with the final data object that 
        // represents a row in the table. 
        const commentData = comments.aggregate({}, (final, c) => ({...final, ...c}));

        return ({
            proposalName: p.proposalName, 
            author: p.author, 
            authorEmail: p.authorEmail,
            ...commentData
        });
    }).toArray();


const processResourcesData = (proposals) => 
// Process the resources data for proposals.
// This outputs an array of objects representing a line each in a data file.

    proposals.select(p => {
    // For each proposal we want to create an object that looks like this:
    // { '[Facility Name]: [Resource Name]': [Value] }
    // We want a single key in the object for each resource so that we can spread it 
    // into the data object.
        const resources = p.resourceValues.aggregate({}, (result, rv) => {
            const resource = rv.value.aggregate({}, (entry, s) => {
                const key = humanizeLowerCaseWithUnderscores(`${rv.key}: ${s.key}`);
                entry[key] = s.value ? s.value : '';
                return entry;
            });
            result = {...result, ...resource};
            return result;
        });
    
        return ({
            'Proposal Name': p.proposalName,
            'Author': p.author,
            'Author Email': p.authorEmail,
            ...resources,
            'Total Score': p.score ? p.score : '' 
        });
    }).toArray();


export const processScores = (proposals, callName) => {
    toXLSX(processScoresData(proposals), callName, 'Scores');
};

export const processResources = (proposals, callName) => {
    toXLSX(processResourcesData(proposals), `${callName} Resource Summary`, 'Resources');
};

export const processComments = (proposals, callName) => {
    toXLSX(processCommentsData(proposals), `${callName} Comment Summary`, 'Comments');
};

export const processAll = (proposals, callName) => {
    const wsScores = XLSX.utils.json_to_sheet(processScoresData(proposals));
    const wsComments = XLSX.utils.json_to_sheet(processCommentsData(proposals));
    const wsResources = XLSX.utils.json_to_sheet(processResourcesData(proposals));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsScores, 'Scores');
    XLSX.utils.book_append_sheet(wb, wsComments, 'Comments');
    XLSX.utils.book_append_sheet(wb, wsResources, 'Resources');
    // generate XLSX file and send to client
    XLSX.writeFile(wb, `${callName}.xlsx`);
};
