import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {formatDateTime, ListFromArray} from '../../../utils/generalUtils';
import {Link} from 'react-router-dom';

const CallList = (props) => {
    // Renders the review page of the new call for proposals
    const sort_options = {
        defaultSortName: 'commencement',  // default sort column name
        defaultSortOrder: 'desc'  // default sort order
    };

    return (
        <div>
            <BootstrapTable data={props.calls.toArray()} options={sort_options} striped search pagination hover>
                <TableHeaderColumn dataField="name" isKey={true}>Call for Proposal Name</TableHeaderColumn>
                {props.user.isAdmin() ? (
                    <TableHeaderColumn dataField="committee" dataFormat={
                        // Renders the committee name, rather than the committee id
                        // eslint-disable-next-line no-unused-vars
                        (cell, row) => props.committees.firstOrDefault(c => c.id === cell).name
                    }>Committee</TableHeaderColumn>
                ) : null}

                <TableHeaderColumn dataField="committee" dataFormat={
                    // Renders the current proposal state
                    (cell, row) => <ListFromArray items={[
                        props.user.isAuthor(row) ? 'Author' : null,
                        props.user.isCollaborator(row) ? 'Collaborator' : null,
                        props.user.isMember(cell.id) ? 'Committee' : null
                    ]}/>
                }>My Roles</TableHeaderColumn>

                <TableHeaderColumn
                    dataField="commencement"
                    dataFormat={formatDateTime}
                >
                    Submission Open
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="termination"
                    dataFormat={formatDateTime}
                >
                    Submission Deadline
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="reviewCommencement"
                    dataFormat={formatDateTime}
                >
                    Review Start</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="reviewTermination"
                    dataFormat={formatDateTime}
                >
                    Review Deadline
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="view_calls"
                    dataFormat={
                        // Creates an add button that adds the form on this row to the list of forms
                        (cell, row) => <ListFromArray items={[
                            // eslint-disable-next-line react/jsx-key
                            <Link to={`/calls/${row.id}/`}>View Call</Link>,
                            // eslint-disable-next-line react/jsx-key
                            <Link to={`/calls/${row.id}/proposals/`}>View Proposals</Link>
                        ]}
                        />

                    }>View</TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default CallList;