import React, {Component} from 'react';


import {requestPasswordChange} from '../../stores/auth/store';
import {connect} from 'react-redux';
import {Button, Col, Panel} from 'react-bootstrap';
import ServerErrors from '../../utils/ServerErrors';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapInput} from '../../utils/BootstrapInputs';

// import {Route, Link} from 'react-router-dom'

class ChangePassword extends Component {
    constructor() {
        super();

        this.state = {
            done: false
        };
    }

    render() {
        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <Panel header={<h3>Change Password</h3>} className="login-panel">
                        {this.state.done ? (
                            <p>Your password has been changed.</p>
                        ) : (
                            <div>
                                <ServerErrors errors={this.props.errors}/>
                                <LocalForm
                                    onSubmit={
                                        ({oldpassword, password1, password2}) => {
                                            this.props.requestPasswordChange(
                                                oldpassword,
                                                password1,
                                                password2,
                                                () => {
                                                    this.setState({
                                                        done: true
                                                    });
                                                });
                                        }
                                    }
                                    initialState={{oldpassword: '', password1: '', password2: ''}}>
                                    <Control
                                        placeholder="Current Password"
                                        component={BootstrapInput}
                                        model=".oldpassword"
                                        required
                                        type="password"
                                        maxLength={1000}
                                    />
                                    <Control
                                        placeholder="Password"
                                        component={BootstrapInput}
                                        model=".password1"
                                        required
                                        type="password"
                                        maxLength={1000}
                                    />
                                    <Control
                                        placeholder="Confirm Password"
                                        component={BootstrapInput}
                                        model=".password2"
                                        required
                                        type="password"
                                        maxLength={1000}
                                    />
                                    <Button type="submit" bsSize="large" bsStyle="success" block>Change
                                        Password</Button>
                                </LocalForm>
                            </div>
                        )}
                    </Panel>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = ({misc}) => ({
    errors: misc.errors
});

export default connect(mapStateToProps, {
    requestPasswordChange,
})(ChangePassword);
