import React from 'react';
import ReactDOM from 'react-dom';

// Register service worker breaks the apache api/admin handler, so for now it's been removed
//import registerServiceWorker from './registerServiceWorker';
import {App} from './App';

ReactDOM.render(<App/>, document.getElementById('root'));

// Continued removal of the register service worker code
//registerServiceWorker();

// This function is to undo the register service worker code in existing browsers that have already loaded the service
// worker.
navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
        registration.unregister();
    }
});