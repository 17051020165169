import React, {Component} from 'react';
import TinyMCE from './TinyMce';
import uuid from '../utils/uuid';

const config = {
    plugins: 'link image imagetools code paste',
    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code image',
    // eslint-disable-next-line camelcase
    browser_spellcheck: true,
    rows: '5000'
};


class JsonSchemaTinyMce extends Component {
    handleChange = (event, editor) => {
        //console.log("change", editor.getContent())
        this.props.onChange(editor.getContent());
    }

    render() {
        const {id, value, onBlur, options} = this.props;
        return (
            <div>
                {/*<ControlLabel>{label}</ControlLabel>*/}

                <TinyMCE
                    config={config}
                    content={value}
                    id={id + uuid().toString()}
                    imageBlobUploads
                    onBlur={onBlur}
                    onChange={this.handleChange}
                    onKeyup={this.handleChange}
                    onKeydown={this.handleChange}
                    options={options}
                />
            </div>
        );
    }

}

export default JsonSchemaTinyMce;
