import icepick from 'icepick';

import localStorage from '../utils/localStorage';

const defaultOptions = icepick.freeze({
    credentials: 'same-origin',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

export async function get(url, options = {}) {
    options = icepick.merge(
        defaultOptions,
        options,
    );
    if (localStorage.token) {
        options = icepick.assocIn(options, ['headers', 'Authorization'], `Token ${localStorage.token}`);
    }
    url = `/api/v1${url}`;
    const response = await fetch(url, options);
    if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        error.json = null;

        try {
            error.json = await response.json();
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
        throw error;
    }

    return await response.json();
}

export function post(url, body, options = {}) {
    options = {
        ...options,
        method: 'POST',
        body: JSON.stringify(body),
    };
    return get(url, options);
}

export async function del(url, body, options = {}) {
    options = {
        ...options,
        method: 'DELETE',
        body: JSON.stringify(body),
    };
    return get(url, options);
}

export async function patch(url, body, options = {}) {
    options = {
        ...options,
        method: 'PATCH',
        body: JSON.stringify(body),
    };
    return get(url, options);
}