import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestSingleCall} from '../../stores/calls/store';
import {Button, PageHeader, Table} from 'react-bootstrap';
import {exportToCsv, humanizeLowerCaseWithUnderscores, navigate} from '../../utils/generalUtils';
import {requestAllReviews} from '../../stores/reviews/store';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {requestProposal} from '../../stores/proposals/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import PermissionDenied from '../PermissionDenied';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import Enumerable from 'linq/linq';
import fileDownload from 'js-file-download';
import {DEBUG} from '../../App';

/* eslint no-unused-vars: 0 */

class ListReviews extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestSingleCall(this.props.match.params.callId, () =>
            this.props.requestProposal(this.props.match.params.proposalId, () =>
                this.props.requestAllReviews(this.props.match.params.callId, this.props.match.params.proposalId, () =>
                    this.setState({
                        isLoading: false
                    })
                )
            )
        );
    }

    canReview() {
        // Can't review this proposal if it's outside the review period
        if (!this.props.call.canSubmitReview())
            return false;

        // Can't review this proposal if the proposal is still able to be submitted, i.e. has an extension
        if (this.props.call.canSubmitProposal(this.props.proposal.authorId) && !DEBUG)
            return false;

        // Can't review this proposal if we're the author
        if (this.props.proposal.authorId === this.props.localUser.id && !DEBUG)
            return false;

        // Can't review this proposal if the proposal is closed review and we're not a reviewer
        if (!this.props.call.isReviewOpen && !this.props.proposal.reviewers.any(r => r.id === this.props.localUser.id))
            return false;

        // User can review this proposal
        return true;
    }

    isAuthor(review) {
        // Check if we're the author of this review
        return review.authorId === this.props.localUser.id;
    }

    hasReview() {
        // Check if there is a review authored by the local user
        return this.props.reviews.any(r => r.authorId === this.props.localUser.id);
    }

    render() {
        if (!this.props.localUser.isValid || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isMember(this.props.call.committee))
            return (<PermissionDenied/>);

        const score_column_names = [];
        let total_score_data = {};

        // Map the reviewers to the call
        const reviews = this.props.reviews.select(
            p => {
                // ASTACTA-91 - Show average score columns
                if (this.props.localUser.isChair(this.props.call.committee) && p.scores) {
                    p.scores.forEach((s) => {
                        p[s.name] = s.value.toFixed(2);

                        if (s.name === '__result__')
                            p.score = s.value.toFixed(2);
                        else
                            score_column_names.push(s.name);

                        p[`score_${s.name}`] = s.value.toFixed(2);

                        if (!total_score_data[s.name])
                            total_score_data[s.name] = [];

                        total_score_data[s.name].push(s.value.toFixed(2));
                    });
                }
                return p;
            }
        ).toArray();

        total_score_data = Enumerable.from(total_score_data);

        let tableCols = [
            /* Review Author */
            (<TableHeaderColumn dataField="author" key="author">Reviewer</TableHeaderColumn>)
        ];

        if (!this.props.call.canSubmitProposal(this.props.proposal.authorId)
            && this.props.localUser.isChair(this.props.call.committee)) {
            new Set(score_column_names).forEach(c =>
                tableCols.push(
                    (
                        <TableHeaderColumn dataField={`score_${c}`} dataSort={true} key={`score_${c}`}>
                            {humanizeLowerCaseWithUnderscores(c)}
                        </TableHeaderColumn>
                    )
                )
            );
        }

        tableCols = tableCols.concat([
            /* The average score for this review */
            !this.props.call.canSubmitProposal('ANY') && this.props.localUser.isChair(this.props.call.committee) ? (
                <TableHeaderColumn dataField="score" dataSort={true} key="score" dataFormat={
                    // Inserts the reviewer list
                    (cell, row) => cell
                }>Review Score</TableHeaderColumn>
            ) : null,
            (
                <TableHeaderColumn dataField="view" isKey={true} key="view" dataFormat={
                    // Creates an add button that adds the form on this row to the list of forms
                    (cell, row) => (
                        <Link
                            to={`/calls/${this.props.call.id}/proposals/${this.props.proposal.id}/reviews/${row.id}/`}>
                            {this.canReview() && this.isAuthor(row) ? 'Edit' : 'View'}
                        </Link>
                    )
                }/>
            )
        ]);

        const str_reviewers = this.props.proposal.reviewers.select(e => e.name).toArray().join(', ');

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: this.props.call.name,
                            url: `/calls/${this.props.call.id}/`
                        },
                        {
                            name: 'Proposals',
                            url: `/calls/${this.props.call.id}/proposals/`
                        },
                        {
                            name: this.props.proposal.proposalName,
                            url: `/calls/${this.props.call.id}/proposals/${this.props.proposal.id}/`
                        },
                        {
                            name: 'Reviews',
                            url: `/calls/${this.props.call.id}/proposals/${this.props.proposal.id}/reviews/`
                        }
                    ]
                }/>
                <PageHeader>
                    Reviews for proposal {this.props.proposal.proposalName} for {this.props.call.name}

                    {/* Add new review */}
                    {this.canReview() ? (
                        <Button className="pull-right"
                            onClick={() => navigate(this, `/calls/${this.props.match.params.callId}/proposals/${this.props.match.params.proposalId}/review/`)}>
                            {this.hasReview() ? 'Edit' : 'Add'} Review
                        </Button>
                    ) : null}
                </PageHeader>
                {this.props.localUser.isChair(this.props.call.committee) ? (
                    <div>
                        <h4>Reviewers: {str_reviewers}</h4>
                        <hr/>
                    </div>
                ) : null}
                {/* List all reviews for this call */}
                <BootstrapTable data={reviews} striped search pagination hover>
                    {tableCols}
                </BootstrapTable>
                {/* Show the review totals if the current user is a chair */}
                {this.props.localUser.isChair(this.props.call.committee) ? (
                    <div>
                        <hr/>
                        <h4>Review Totals</h4>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    {
                                        total_score_data.select(v => (
                                            <td key={v.key}>
                                            Average {humanizeLowerCaseWithUnderscores(v.key)}
                                            </td>
                                        )).toArray()
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        total_score_data.select(v => (
                                            <td key={v.key}>
                                                {
                                                    v.value.length ?
                                                        (Enumerable.from(v.value).select(
                                                            val => parseFloat(val)).sum() / v.value.length
                                                        ).toFixed(2) : 0
                                                }
                                            </td>)
                                        ).toArray()
                                    }
                                </tr>
                            </tbody>
                        </Table>
                        <Button onClick={() => {
                            // Export CSV with following headers
                            const headers = ['Proposal Name', 'Author', 'Reviewer']; // score fields, total score

                            const score_columns = new Set(score_column_names);

                            score_columns.forEach(c => {
                                headers.push(`${humanizeLowerCaseWithUnderscores(c)} (${c})`);
                            });

                            headers.push('Total Score');

                            const rowData = [];
                            Enumerable.from(reviews).forEach(p => {
                                // Push the author
                                const row = [this.props.proposal.proposalName, this.props.proposal.author, p.author];

                                // Push the score columns
                                score_columns.forEach(sc => row.push(p[sc] || 0));

                                // Push the total score
                                row.push(p.score || 0);

                                // Add the row to the csv
                                rowData.push(row);
                            });

                            const csvData = exportToCsv(
                                headers,
                                rowData
                            );
                            fileDownload(csvData, 'Review Summary.csv');
                        }}>Download Review Summary</Button>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({calls, reviews, auth, proposals}, ownProps) => ({
    call: calls.currentCall,
    reviews: reviews.all,
    proposal: proposals.current,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestAllReviews,
    requestSingleCall,
    requestProposal
})(ListReviews);
