import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    requestCreateUserCommitteeRole,
    requestSingleUser, requestUpdateUserCommitteeRole,
    requestUserCommitteeRoles
} from '../../../stores/users/store';
import {Button, PageHeader} from 'react-bootstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';
import {requestCommittees} from '../../../stores/committees/store';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapDateTimePicker, BootstrapSelect} from '../../../utils/BootstrapInputs';
import Enumerable from 'linq';
import {navigate} from '../../../utils/generalUtils';
import PermissionDenied from '../../PermissionDenied';
import ServerErrors from '../../../utils/ServerErrors';
import PageLoading from '../../../components/PageLoading';

class EditRole extends Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            isNew: true,
            roleData: {
                commencement: new Date(),
                termination: new Date()
            }
        };
    }

    componentWillMount() {
        // Ask the server for the details for the current user
        this.props.requestSingleUser(this.props.match.params.userId, () =>
            // Fetch all committees
            this.props.requestCommittees(() =>
                // Ask the server for the committee roles for this user
                this.props.requestUserCommitteeRoles(this.props.match.params.userId, (roles) => {
                    // Check if we are editing a role
                    if (this.props.match.params.roleId) {
                        const role = roles.firstOrDefault(r => r.id === parseInt(this.props.match.params.roleId, 10));
                        this.setState({
                            ...this.state,
                            loading: false,
                            isNew: false,
                            roleData: {
                                committee: role.committeeId,
                                role: role.role,
                                commencement: role.commencement,
                                termination: role.termination
                            }
                        });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }
                })
            )
        );
    }

    submitEditRole() {
        if (this.state.isNew) {
            // Submit the new role to the server
            this.props.requestCreateUserCommitteeRole({
                ...this.state.roleData,
                userId: this.props.user.id
            }, () => navigate(this, `/users/${this.props.user.id}/`));
        }
        else {
            this.props.requestUpdateUserCommitteeRole({
                ...this.state.roleData,
                userId: this.props.match.params.roleId
            }, () => navigate(this, `/users/${this.props.user.id}/`));
        }
    }

    getCommittee(id) {
        return this.props.committees.firstOrDefault(c => c.id === parseInt(id, 10));
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.loading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        // Create the committee options
        const availableCommitteeOptions = Enumerable.from([(<option key={0} value="">---</option>)])
            .concat(
                this.props.committees.select(
                    (c) =>
                        (
                            <option key={c.id} value={c.id}>{c.name}</option>
                        )
                )
            );

        // Create the role options
        const roleOptions = [
            (<option key={0} value="">---</option>),
            (<option key="CHAIR" value="CHAIR">CHAIR</option>),
            (<option key="MEMBER" value="MEMBER">MEMBER</option>)
        ];

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Users',
                            url: '/users'
                        },
                        {
                            name: this.props.user.username,
                            url: `/users/${this.props.user.id}/`
                        },
                        {
                            name: this.state.isNew ? 'New Role' : `Editing role for ${this.getCommittee(this.state.roleData.committee).name} as ${this.state.roleData.role}`,
                            url: this.state.isNew ? `/users/${this.props.user.id}/roles/new` : this.props.match.url
                        }
                    ]
                }/>

                <PageHeader>
                    {this.state.isNew ? 'New Role' : `Editing role for ${this.getCommittee(this.state.roleData.committee).name} as ${this.state.roleData.role}`}
                </PageHeader>

                <LocalForm initialState={this.state.roleData}
                    onChange={
                        (data) => {
                            this.setState(
                                {
                                    ...this.state,
                                    roleData: {
                                        ...data
                                    }
                                }
                            );
                        }
                    }
                    onSubmit={
                        () => this.submitEditRole()
                    }
                    validators={{
                        '': {
                            terminationDateTimeAfterCommencementDateTime:
                                (vals) => vals.termination >= vals.commencement
                        }
                    }}>

                    {/* Any errors */}
                    <ServerErrors errors={this.props.errors}/>

                    {/* The committee for this new role */}
                    <Control label="Committee" type="select" component={BootstrapSelect}
                        options={availableCommitteeOptions.toArray()}
                        model=".committee"
                        required
                    />

                    {/* The role for this new role */}
                    <Control label="Role" type="select" component={BootstrapSelect} options={roleOptions}
                        model=".role"
                        required
                    />

                    {/* The opening date and time of the call */}
                    <Control label="Date/Time role is effective from" component={BootstrapDateTimePicker}
                        model=".commencement"
                        required
                        maxLength={200}
                    />

                    {/* The closing date and time of the call */}
                    <Control label="Date/Time role is effective until" component={BootstrapDateTimePicker}
                        model=".termination"
                        required
                        maxLength={200}
                        error={this.state.roleData.termination < this.state.roleData.commencement ?
                            'Termination date can not be before the commencement date' : null}
                    />

                    {/* Submit button */}
                    <Button type="submit">{this.state.isNew ? 'Create Role' : 'Update Role'}</Button>

                </LocalForm>
            </div>
        );
    }
}

const mapStateToProps = ({users, committees, auth, misc}) => ({
    user: users.currentUser,
    committeeRoles: users.currentUserCommitteeRoles,
    committees: committees,
    localUser: auth.localUser,
    errors: misc.errors
});

export default connect(mapStateToProps, {
    requestSingleUser,
    requestUserCommitteeRoles,
    requestCommittees,
    requestCreateUserCommitteeRole,
    requestUpdateUserCommitteeRole
})(EditRole);
