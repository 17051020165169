import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LazilyLoad extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.load();
    }

    componentDidUpdate(previous) {
        const shouldLoad = !!Object.keys(this.props.modules).filter((key) =>
            this.props.modules[key] !== previous.modules[key]).length;
        if (shouldLoad) {
            this.load();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    load() {
        this.setState({
            isLoaded: false,
        });

        const {modules} = this.props;
        const keys = Object.keys(modules);

        return Promise.all(keys.map((key) => modules[key]()))
            .then((values) => (keys.reduce((agg, key, index) => {
                agg[key] = values[index];
                return agg;
            }, {})))
            .then((result) => {
                if (!this._isMounted) return null;
                this.setState({modules: result, isLoaded: true});
                return result;
            });
    }

    render() {
        if (!this.state.isLoaded) return <div/>;
        return React.Children.only(this.props.children(this.state.modules));
    }
}

LazilyLoad.propTypes = {
    children: PropTypes.func.isRequired,
    modules: PropTypes.object.isRequired,
};

// eslint-disable-next-line react/display-name
export const LazilyLoadFactory = (Component, modules) => (props) => (
    <LazilyLoad modules={modules}>
        {(mods) => <Component {...mods} {...props} />}
    </LazilyLoad>
);

export const importLazy = (promise) => (
    promise.then((result) => result.default)
);

export default LazilyLoad;
