import React, {Component} from 'react';
import localStorage from '../utils/localStorage';
import XHRUploader from '../extra/react-xhr-uploader';
import {defer} from '../utils/generalUtils';

class PdfUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploading: false
        };
    }
    // eslint-disable-next-line no-unused-vars
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.state.uploading && !nextState.uploading;
    }

    render() {
        return <div>
            {
                this.props.value &&
                <div>
                    <div className="row">
                        <div className="col-md-2">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/api/v1/proposal_response_pdf/?token=${localStorage.token}&proposal=${this.props.formContext.proposalId}&file=${this.props.value}`}>
                                <span
                                    className="fa fa-download"
                                />
                        &nbsp;Download
                            </a>
                        </div>
                        {this.props.formContext.canEdit &&
                        <div className="col-md-2">
                            <button className="btn btn-xs btn-danger" onClick={() => {
                                this.props.onChange(undefined);
                                defer(() => this.props.formContext.noValidateSave());
                            }}>Delete
                            </button>
                        </div>
                        }
                    </div>
                    <hr/>
                </div>
            }
            {
                this.props.formContext.canEdit &&
                <XHRUploader
                    url="/api/v1/proposal_response_pdf/"
                    maxFiles={1}
                    formData={{
                        proposal: this.props.formContext.proposalId,
                        token: localStorage.token,
                        field: this.props.id.substr('root_'.length),
                        form: this.props.formContext.formId
                    }}
                    onStart={() => {
                        this.setState({
                            uploading: true
                        });
                        this.props.formContext.onStartUpload();
                    }}
                    onComplete={(filename, response) => {
                        this.setState({
                            uploading: false
                        });
                        if (response.status === 'ok') {
                            this.props.onChange(response.fileId);
                            defer(() => this.props.formContext.noValidateSave());
                        } else {
                            // eslint-disable-next-line no-alert
                            alert('There was an issue uploading the file: ' + response.status);
                            this.props.formContext.onCancelUpload();
                        }
                    }}
                    onCancel={() => {
                        this.setState({
                            uploading: false
                        });
                        this.props.formContext.onCancelUpload();
                    }}
                />
            }
        </div>;
    }
}

export default PdfUpload;