import localStorage from '../../utils/localStorage';
import {post} from '../../utils/api';

export async function logout() {
    const response = post('/auth/logout/');
    delete localStorage.token;
    return response;
}

export const register = (data) => post('/auth/registration/', data);

export const registerVerify = (key) => post('/auth/registration/verify-email/', {key});

export async function login(username, password) {
    if (loggedIn()) {
        return true;
    }
    return await post('/auth/login/', {username, password});
}

export const loggedIn = () => !!localStorage.token;

