import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';

import uuid from '../utils/uuid';
import {LazilyLoadFactory} from '../utils/LazilyLoad';

/* eslint react/no-danger: 0 */
/* eslint react/no-find-dom-node: 0 */

function ucFirst(str) {
    return str[0].toUpperCase() + str.substring(1);
}


// Include all of the Native DOM and custom events from:
// https://github.com/tinymce/tinymce/blob/master/tools/docs/tinymce.Editor.js#L5-L12
const EVENTS = [
    'focusin', 'focusout', 'click', 'dblclick', 'mousedown', 'mouseup',
    'mousemove', 'mouseover', 'beforepaste', 'paste', 'cut', 'copy',
    'selectionchange', 'mouseout', 'mouseenter', 'mouseleave', 'keydown',
    'keypress', 'keyup', 'contextmenu', 'dragend', 'dragover', 'draggesture',
    'dragdrop', 'drop', 'drag', 'BeforeRenderUI', 'SetAttrib', 'PreInit',
    'PostRender', 'init', 'deactivate', 'activate', 'NodeChange',
    'BeforeExecCommand', 'ExecCommand', 'show', 'hide', 'ProgressState',
    'LoadContent', 'SaveContent', 'BeforeSetContent', 'SetContent',
    'BeforeGetContent', 'GetContent', 'VisualAid', 'remove', 'submit', 'reset',
    'BeforeAddUndo', 'AddUndo', 'change', 'undo', 'redo', 'ClearUndos',
    'ObjectSelected', 'ObjectResizeStart', 'ObjectResized', 'PreProcess',
    'PostProcess', 'focus', 'blur', 'dirty',
];

// Note: because the capitalization of the events is weird, we're going to get
// some inconsistently-named handlers, for example compare:
// 'onMouseleave' and 'onNodeChange'
const HANDLER_NAMES = EVENTS.map((event) => 'on' + ucFirst(event));

// add handler propTypes
const propTypesFns = {};

// eslint-disable-next-line jest/require-hook
HANDLER_NAMES.forEach((name) => {
    propTypesFns[name] = PropTypes.func;
});

class TinyMCE extends Component {
    static propTypes = {
        className: PropTypes.string,
        config: PropTypes.object,
        content: PropTypes.string,
        id: PropTypes.string,
        imageBlobUploads: PropTypes.bool,
        tinymce: PropTypes.object.isRequired,
        ...propTypesFns
    }

    static defaultProps = {
        config: {},
        content: '',
        imageBlobUploads: false,
    }
    _init = (config, content) => {
        if (this._isInit) {
            this._remove();
        }

        if (this.props.imageBlobUploads) {
            config = {
                ...config,
                // eslint-disable-next-line camelcase
                file_picker_callback: this.filePickerCallback,
                // eslint-disable-next-line camelcase
                paste_data_images: true,
                // eslint-disable-next-line camelcase
                file_picker_types: 'image',
                // eslint-disable-next-line camelcase
                images_upload_handler: function (blobInfo, success) {
                    // no upload, just return the blobInfo.blob() as base64 data
                    success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
                },
            };
        }

        // hide the textarea that is me so that no one sees it
        findDOMNode(this).style.hidden = 'hidden';

        // Map any extra attributes
        // eslint-disable-next-line no-prototype-builtins
        if (this.props.options && this.props.options.hasOwnProperty('rows'))
            config.height = (24*this.props.options.rows).toString();

        const setupCallback = config.setup;
        const hasSetupCallback = (typeof setupCallback === 'function');

        config.selector = '#' + this.id;
        // eslint-disable-next-line camelcase
        config.skin_url = config.skin_url ? config.skin_url : `${process.env.PUBLIC_URL}/skins/lightgray`;
        config.setup = (editor) => {
            EVENTS.forEach((event, index) => {
                const handler = this.props[HANDLER_NAMES[index]];
                if (typeof handler !== 'function') return;
                editor.on(event, (e) => {
                    // native DOM events don't have access to the editor so we pass it here
                    handler(e, editor);
                });
            });
            // need to set content here because the textarea will still have the
            // old `this.props.content`
            if (content) {
                editor.on('init', () => {
                    editor.setContent(content);
                });
            }
            if (hasSetupCallback) {
                setupCallback(editor);
            }
        };

        this.props.tinymce.init(config);

        findDOMNode(this).style.hidden = '';

        this._isInit = true;
    }
    _remove = () => {
        this.props.tinymce.EditorManager.execCommand('mceRemoveEditor', true, this.id);
        this._isInit = false;
    }
    filePickerCallback = (cb) => {
        const tinymce = this.props.tinymce;
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {
            let file = this.files[0];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                // Note: Now we need to register the blob in TinyMCEs image blob
                // registry. In the next release this part hopefully won't be
                // necessary, as we are looking to handle it internally.
                let id = 'blobid' + (new Date()).getTime();
                let blobCache = tinymce.activeEditor.editorUpload.blobCache;

                if (file.size > 512 * 1024) {
                    // eslint-disable-next-line no-alert
                    alert('The image cannot be larger than 500KB.');
                    return;
                }

                let blobInfo = blobCache.create(id, file, reader.result);
                blobCache.add(blobInfo);

                // call the callback and populate the Title field with the file name
                cb(blobInfo.base64(), {alt: file.name});
            };
        };

        input.click();
    }

    componentWillMount() {
        this.id = this.id || this.props.id || `react-tinymce-${uuid()}`;
    }

    componentDidMount() {
        const config = clone(this.props.config);
        this._init(config);
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.id, nextProps.id)) {
            this.id = nextProps.id;
        }
        if (!isEqual(this.props.config, nextProps.config) || !isEqual(this.props.id, nextProps.id)) {
            this._init(clone(nextProps.config), nextProps.content);
            return;
        }

        const editor = this.props.tinymce.EditorManager.get(this.id);
        const oldContent = editor.getContent({format: 'html'});
        if (!isEqual(oldContent, nextProps.content)) {
            editor.setContent(nextProps.content);

            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
        }
    }

    shouldComponentUpdate(nextProps) {
        // Specifically don't update on value change only
        const returnValue = (
            !isEqual(this.props.config, nextProps.config)
            || !isEqual(this.props.id, nextProps.id)
        );
        return returnValue;
    }

    componentWillUnmount() {
        this._remove();
    }

    render() {
        return this.props.config.inline ? (
            <div
                className={this.props.className}
                dangerouslySetInnerHTML={{__html: this.props.content}}
                id={this.id}
                spellCheck={this.props.config.browser_spellcheck}
            />
        ) : (
            <textarea
                className={this.props.className}
                defaultValue={this.props.content}
                id={this.id}
            />
        );
    }
}


const TinyMCEWrapper = LazilyLoadFactory(TinyMCE, {
    tinymce: () => import('tinymce/tinymce').then(() => Promise.all([
        import('tinymce/themes/modern/theme'),
        import('tinymce/plugins/link'),
        import('tinymce/plugins/image'),
        import('tinymce/plugins/code'),
        import('tinymce/plugins/imagetools'),
        import('tinymce/plugins/paste'),
        require.context(
            '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
            true,
            /.*/
        ),
    ]).then(() => 
    // Get around Babel making modules readonly (Means tinymce can set the modules settings object to ours)
    // see https://exploringjs.com/es6/ch_modules.html#_imports-are-read-only-views-on-exports
    // see https://babeljs.io/repl/#?browsers=defaults%2C%20not%20ie%2011%2C%20not%20ie_mob%2011&build=
    // &builtIns=false&spec=false&loose=false&code_lz=KYDwDg9gTgLgBAKjgMyhAtnA5MiEsDcQA&debug=false&
    // forceAllTransforms=false&shippedProposals=false&circleciRepo=&evaluate=true&fileSize=false&
    // timeTravel=false&sourceType=module&lineWrap=true&presets=es2015&prettier=false&targets=&
    // version=7.12.12&externalPlugins=
        require('tinymce')
    )),

});

export default TinyMCEWrapper;
export {TinyMCE};
