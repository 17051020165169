import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import JSONEditor from 'jsoneditor';

import 'jsoneditor/dist/jsoneditor.css';

/* eslint react/no-find-dom-node: 0 */
/* eslint react/no-string-refs: 0 */

class JsonEditor extends Component {
    _init(options, initialData)
    {
        findDOMNode(this).style.hidden = 'hidden';
        options.onChange = () => this.onChange();
        this.editor = new JSONEditor(this.refs.container, options, initialData);
        findDOMNode(this).style.hidden = '';
    }

    componentDidMount() {
        this._init(this.props.config, this.props.initialData);
    }

    componentWillUnmount() {
        this.editor.destroy();
    }

    onChange() {
        try {
            if (this.props.onChange)
                this.props.onChange(this.editor.get());
        }
        catch(e) {
            if (this.props.onError)
                this.props.onError(e);
        }
    }

    shouldComponentUpdate(nextProps) {
        try{
            const current = JSON.stringify(this.editor.get());
            return current !== JSON.stringify(nextProps.json);
        }
        catch(e) {
            return false;
        }
    }

    setContent(content) {
        if (this.editor)
            this.editor.set(content);
        else
            window.setTimeout(() => this.setContent(content), 0);
    }

    setSchema(schema) {
        if (this.editor)
            this.editor.setSchema(schema);
        else
            window.setTimeout(() => this.setSchema(schema), 0);
    }

    render() {
        this.setContent(this.props.json || '');
        this.setSchema(this.props.schema || '');

        return (
            <div ref="container" style={this.props.style} />
        );
    }
}

export default JsonEditor;
