/* eslint-disable react/jsx-no-undef,react/jsx-no-undef */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestLogout, requestUserDetails} from '../stores/auth/store';
import {defer, navigate} from '../utils/generalUtils';
import {MenuItem, Navbar, NavDropdown} from 'react-bootstrap';

import logo from '../assets/images/brand.png';

import Sidebar from './Sidebar';

class Header extends Component {
    render() {
        // Ask the server for the current users details if we don't have them yet
        if (!this.props.user.isValid())
            defer(
                () => {
                    this.props.requestUserDetails(user => {
                        // Check if the user has finished registering
                        if (!user.registration_complete)
                        {
                            // Navigate the user to complete their registration
                            defer(() => navigate(this, '/auth/complete_registration'));
                        }
                    });
                }
            );

        // Don't display the header if the user is not logged in.
        if (!this.props.loggedIn || !this.props.user.registration_complete)
            return (null);

        return (
            <div id="wrapper" className="content">
                <Navbar collapseOnSelect fluid={true} style={{margin: 0}}>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <img
                                style={{'marginTop': '-13px'}}
                                src={logo} alt="ADACS Proposal System"
                                title="ADACS Proposal System"
                            />
                            <span>
                            &nbsp;&nbsp; Proposal System
                                {/*<LinkContainer to="/"><a>Time Allocation Project</a></LinkContainer>*/}
                            </span>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <ul className="nav navbar-top-links navbar-right">
                        <a href="/TAC_User_Manual_V1.pdf" target="_blank">Help</a> &nbsp;&nbsp;
                        <NavDropdown eventKey={3}
                            title={<span>{this.props.user.username}</span>}
                            id="navDropDown1">
                            <MenuItem eventKey={1.1} onClick={
                                // Dispatch a logout message if logout is clicked
                                () => navigate(this, '/account/')
                            }><span> <i className="fa fa-user fa-fw"/> My Account </span></MenuItem>
                            <MenuItem eventKey={1.1} onClick={
                                () => navigate(this, '/account/change-password/')
                            }><span> <i className="fa fa-key fa-fw"/> Change Password </span></MenuItem>
                            <MenuItem divider/>
                            <MenuItem eventKey={1.1} onClick={
                                // Dispatch a logout message if logout is clicked
                                () => this.props.requestLogout()
                            }><span> <i className="fa fa-sign-out fa-fw"/> Logout </span></MenuItem>

                        </NavDropdown>
                    </ul>
                    <Sidebar/>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    user: auth.localUser,
    loggedIn: auth.loggedIn
});

export default connect(mapStateToProps, {
    requestUserDetails,
    requestLogout
})(Header);
