import Enumerable from 'linq';

class Proposal {
    constructor(data) {
        // Copy the data in to the proposal
        Object.assign(this, data);

        // Convert the collaborators to an enumerable
        this.collaborators = Enumerable.from(this.collaborators);

        // Convert the reviewers to an enumerable
        this.reviewers = Enumerable.from(this.reviewers);

        // Convert the review scores to an enumerable
        this.reviewScores = Enumerable.from(this.reviewScores);

        this.allReviewScores = this.processReviews(this.allReviewScores);

        this.allReviewComments = this.processReviews(this.allReviewComments);

        // Handle the resource values
        this.processResourceValues();
    }

    processReviews(inputReviews) {
        for (var key in inputReviews) {
            // eslint-disable-next-line no-prototype-builtins
            if (inputReviews.hasOwnProperty(key)) {
                inputReviews[key] = Enumerable.from(inputReviews[key]);
            }
        }
        return Enumerable.from(inputReviews);
    }

    processResourceValues() {
        // Check that the server actually sent us resource values
        if (!this.resourceValues) {
            this.resourceValues = {};
            return;
        }

        // Process the resource data
        let data = {};

        // Iterate over each resource value data
        Enumerable.from(this.resourceValues).forEach(rv => {
            // Get the options for this form
            const options = rv.options;
            const institution = options.institution_field;

            // Check that this data is valid
            if (rv.data && rv.data[institution]) {
                // Create the array to store the data for this institution
                data[rv.data[institution]] = [];
                Enumerable.from(options.data_fields).forEach(df => {
                    // Add this data point to the data of this institution
                    data[rv.data[institution]].push({key: df, value: rv.data[df]});
                });
                // Convert to an enumerable
                data[rv.data[institution]] = Enumerable.from(data[rv.data[institution]]);
            }
            else if (rv.data && institution == null) {
                // Create the array to store the data for this institution
                data['Resources'] = [];
                Enumerable.from(options.data_fields).forEach(df => {
                    // Add this data point to the data of this institution
                    data['Resources'].push({key: df, value: rv.data[df]});
                });
                // Convert to an enumerable
                data['Resources'] = Enumerable.from(data['Resources']);
            }
        });

        // Set the data
        this.resourceValues = Enumerable.from(data);
    }
}

export {
    Proposal
};
