import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Modal, PageHeader, Table} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCommittees, requestDeleteCommittee} from '../../stores/committees/store';
import {defer, navigate} from '../../utils/generalUtils';
import PermissionDenied from '../PermissionDenied';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';

class ListCommittees extends Component {
    constructor() {
        super();

        this.state = {
            model: null,
            showDeleteConfirm: false,
            isLoading: true
        };
    }

    componentWillMount() {
        // Fetch all committees
        this.props.requestCommittees(() =>
            this.setState({
                ...this.state,
                isLoading: false
            })
        );
    }

    renderCommittees() {
        const committees = this.props.committees.select(
            (c) => (
                <tr key={c.id}>
                    <td>
                        {c.name}
                    </td>
                    <td>
                        {this.props.localUser.isAdmin() && c.canDelete ? (
                            <Button onClick={
                                () => this.setState(
                                    {
                                        model: c,
                                        showDeleteConfirm: true
                                    }
                                )
                            }
                            className="pull-right btn-danger">Delete</Button>
                        ) : (
                            <p className="pull-right">In use</p>
                        )}
                    </td>
                    <td>
                        <Link to={`/committees/${c.id}/`}>View</Link>
                    </td>
                </tr>
            )
        ).toArray();

        return (
            <div>
                <Table bordered condensed hover>
                    <thead>
                        <tr>
                            <td>Committee</td>
                            {this.props.localUser.isAdmin() ? (
                                <td>Delete</td>
                            ) : null}
                            <td>View Committee</td>
                        </tr>
                    </thead>
                    <tbody>
                        {committees}
                    </tbody>
                </Table>
                {this.state.showDeleteConfirm ? (
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Do you really want to delete this committee?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({showDeleteConfirm: false})}>Close</Button>
                            <Button className="btn-danger" onClick={() => this.props.requestDeleteCommittee(
                                this.state.model.id,
                                () => defer(() => {
                                    // Ask the server for the committee roles for this user
                                    this.props.requestCommittees();
                                    this.setState({showDeleteConfirm: false});
                                }
                                )
                            )}>Delete</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                ) : null}
            </div>
        );
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Committees',
                            url: '/committees'
                        }
                    ]
                }/>

                <PageHeader>
                    Committees
                    <div className="pull-right">
                        <Button className="pull-right" onClick={() => navigate(this, '/committees/new')}>Add
                            Committee</Button>
                    </div>
                </PageHeader>

                {/* Committee details */}
                {this.renderCommittees()}
            </div>
        );
    }
}

const mapStateToProps = ({committees, auth}) => ({
    committees: committees,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestCommittees,
    requestDeleteCommittee
})(ListCommittees);
