import {call, put, select, takeEvery} from 'redux-saga/effects';
import {del, get, patch, post} from '../../utils/api';
import Enumerable from 'linq';
import LocalUser from '../auth/localUser';
import CommitteeRole from './CommitteeRole';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const USERS_REQUEST = 'astacta/users/request';
const USERS_SUCCESS = 'astacta/users/success';

const MEMBERS_REQUEST = 'astacta/members/request';
const MEMBERS_SUCCESS = 'astacta/members/success';

const SINGLE_USER_REQUEST = 'astacta/single_user/request';
const SINGLE_USER_SUCCESS = 'astacta/single_user/success';

const USER_COMMITTEE_ROLES_REQUEST = 'astacta/user/roles/request';
const USER_COMMITTEE_ROLES_SUCCESS = 'astacta/user/roles/success';

const DELETE_USER_COMMITTEE_ROLE_REQUEST = 'astacta/user/roles/delete/request';
const DELETE_USER_COMMITTEE_ROLE_SUCCESS = 'astacta/user/roles/delete/success';

const CREATE_USER_COMMITTEE_ROLE_REQUEST = 'astacta/user/roles/create/request';
const CREATE_USER_COMMITTEE_ROLE_SUCCESS = 'astacta/user/roles/create/success';

const UPDATE_USER_COMMITTEE_ROLE_REQUEST = 'astacta/user/roles/update/request';
const UPDATE_USER_COMMITTEE_ROLE_SUCCESS = 'astacta/user/roles/update/success';

const UPDATE_USER_DETAILS_REQUEST = 'astacta/user/details/update/request';
const UPDATE_USER_DETAILS_SUCCESS = 'astacta/user/details/update/success';

const UPDATE_USER_HASH_REQUEST = 'astacta/user/hash/update/request';
const UPDATE_USER_HASH_SUCCESS = 'astacta/user/hash/update/success';

// Action creators
const requestUsers = (cb) => ({
    type: USERS_REQUEST,
    cb: cb
});

const receiveUsers = (users, cb) => ({
    type: USERS_SUCCESS,
    cb: cb,
    users: users,
});

const requestCommitteeMembers = (committee, cb) => ({
    type: MEMBERS_REQUEST,
    committee: committee,
    cb: cb
});

const receiveCommitteeMembers = (users, cb) => ({
    type: MEMBERS_SUCCESS,
    cb: cb,
    users: users,
});

const requestSingleUser = (id, cb) => ({
    type: SINGLE_USER_REQUEST,
    id: id,
    cb: cb
});

const receiveSingleUser = (user, cb) => ({
    type: SINGLE_USER_SUCCESS,
    currentUser: user,
    cb: cb
});

const requestUserCommitteeRoles = (id, cb) => ({
    id: id,
    cb: cb,
    type: USER_COMMITTEE_ROLES_REQUEST
});

const receiveUserCommitteeRoles = (roles, cb) => ({
    roles: roles,
    cb: cb,
    type: USER_COMMITTEE_ROLES_SUCCESS,
});

const requestDeleteUserCommitteeRole = (id, cb) => ({
    id: id,
    cb: cb,
    type: DELETE_USER_COMMITTEE_ROLE_REQUEST
});

const receiveDeleteUserCommitteeRole = (roles, cb) => ({
    roles: roles,
    cb: cb,
    type: DELETE_USER_COMMITTEE_ROLE_SUCCESS,
});

const requestCreateUserCommitteeRole = (data, cb) => ({
    data: data,
    cb: cb,
    type: CREATE_USER_COMMITTEE_ROLE_REQUEST
});

const receiveCreateUserCommitteeRole = (response, cb) => ({
    response: response,
    cb: cb,
    type: CREATE_USER_COMMITTEE_ROLE_SUCCESS,
});

const requestUpdateUserCommitteeRole = (data, cb) => ({
    data: data,
    cb: cb,
    type: UPDATE_USER_COMMITTEE_ROLE_REQUEST
});

const receiveUpdateUserCommitteeRole = (response, cb) => ({
    response: response,
    cb: cb,
    type: UPDATE_USER_COMMITTEE_ROLE_SUCCESS,
});

const requestUpdateUserDetails = (data, cb) => ({
    data: data,
    cb: cb,
    type: UPDATE_USER_DETAILS_REQUEST
});

const receiveUpdateUserDetails = (cb) => ({
    cb: cb,
    type: UPDATE_USER_DETAILS_SUCCESS,
});

const requestNewHash = (cb) => ({
    cb: cb,
    type: UPDATE_USER_HASH_REQUEST
});

const receiveNewHash = (cb) => ({
    cb: cb,
    type: UPDATE_USER_HASH_SUCCESS,
});

// Sagas
/***
 * Fetches the list of users from the server
 * @returns
 */
function* fetchUsers({cb}) {
    try {
        let response = yield call(get, '/users/');
        yield put(receiveUsers(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches the list of members for the selected committee from the server
 * @returns
 */
function* fetchMembers({committee, cb}) {
    try {
        let response = yield call(get, `/committee_members/?committee=${committee}`);
        yield put(receiveCommitteeMembers(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches a single user from the server
 * @returns
 */
function* fetchSingleUser({id, cb}) {
    // First check if we already have the list of users from the server
    const state = yield select();
    // Check if this user already exists in the list of users
    const _user = state.users.all.firstOrDefault((c) => c.id === parseInt(id, 10));
    if (_user) {
        // Yes, update the current user from the existing user in the database
        yield put(receiveSingleUser(_user, cb));
    }
    else {
        // We don't have the user yet, so let fetch all users from the server
        try {
            // Issue a users update
            const response = yield call(get, `/users/${id}/`);
            yield put(receiveUsers(response));

            // Find the user that was initially requested
            const _user = Enumerable.from(response).firstOrDefault((c) => c.id === parseInt(id, 10));

            // Process receiving the response
            yield put(receiveSingleUser(_user, cb));
        } catch (error) {
            return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
        }
    }
}

/***
 * Fetches the list of committee roles for a user from the server
 * @returns
 */
function* fetchUserCommitteeRoles({id, cb}) {
    try {
        let response = yield call(get, `/roles/${id}/`);
        // Convert the data types
        response = Enumerable.from(response).select(r => new CommitteeRole(r));
        yield put(receiveUserCommitteeRoles(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to delete a committee role
 * @returns
 */
function* deleteUserCommitteeRole({id, cb}) {
    try {
        let response = yield call(del, `/roles/${id}/`);
        yield put(receiveDeleteUserCommitteeRole(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to create a new committee role
 * @returns
 */
function* createUserCommitteeRole({data, cb}) {
    try {
        let response = yield call(post, `/roles/${data.userId}/`, data);
        yield put(receiveCreateUserCommitteeRole(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to update an existing committee role
 * @returns
 */
function* updateUserCommitteeRole({data, cb}) {
    try {
        let response = yield call(patch, `/roles/${data.userId}/`, data);
        yield put(receiveUpdateUserCommitteeRole(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to update details for the current user
 * @returns
 */
function* updateUserDetails({data, cb}) {
    try {
        yield call(patch, '/user_details/', data);
        yield put(receiveUpdateUserDetails(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Asks the server to generate a new unique hash for the current user
 * @returns
 */
function* updateUserHash({cb}) {
    try {
        yield call(get, '/user_hash/');
        yield put(receiveNewHash(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(USERS_REQUEST, fetchUsers);
}

function* watchSingle() {
    yield takeEvery(SINGLE_USER_REQUEST, fetchSingleUser);
}

function* watchUserCommitteeRoles() {
    yield takeEvery(USER_COMMITTEE_ROLES_REQUEST, fetchUserCommitteeRoles);
}

function* watchDeleteUserCommitteeRole() {
    yield takeEvery(DELETE_USER_COMMITTEE_ROLE_REQUEST, deleteUserCommitteeRole);
}

function* watchCreateUserCommitteeRole() {
    yield takeEvery(CREATE_USER_COMMITTEE_ROLE_REQUEST, createUserCommitteeRole);
}

function* watchUpdateUserCommitteeRole() {
    yield takeEvery(UPDATE_USER_COMMITTEE_ROLE_REQUEST, updateUserCommitteeRole);
}

function* watchUpdateUserDetails() {
    yield takeEvery(UPDATE_USER_DETAILS_REQUEST, updateUserDetails);
}

function* watchNewHash() {
    yield takeEvery(UPDATE_USER_HASH_REQUEST, updateUserHash);
}

function* watchFetchMembers() {
    yield takeEvery(MEMBERS_REQUEST, fetchMembers);
}

const sagas = [
    watchFetch,
    watchSingle,
    watchUserCommitteeRoles,
    watchDeleteUserCommitteeRole,
    watchCreateUserCommitteeRole,
    watchUpdateUserCommitteeRole,
    watchUpdateUserDetails,
    watchNewHash,
    watchFetchMembers
];

// Reducers
const path = 'users';
const reducer = (state = {
    all: Enumerable.empty(),
    members: Enumerable.empty(),
    currentUser: null,
    currentUserCommitteeRoles: Enumerable.empty()
}, action = {}) => {
    switch (action.type) {
    case USERS_SUCCESS:
        state = {
            ...state,
            all: Enumerable.from(action.users).select(u => new LocalUser(u))
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;
    case MEMBERS_SUCCESS:
        state = {
            ...state,
            members: Enumerable.from(action.users).select(u => new LocalUser(u))
        };

        if (action.cb)
            defer(() => action.cb(state.members));

        break;
    case SINGLE_USER_REQUEST:
        // Reset the current user on request
        state = {
            ...state,
            currentUser: null
        };
        break;
    case SINGLE_USER_SUCCESS:
        // Update the current user on success
        state = {
            ...state,
            currentUser: action.currentUser
        };

        if (action.cb)
            defer(() => action.cb(action.currentUser));

        break;
    case USER_COMMITTEE_ROLES_REQUEST:
        // Reset the current committee roles on request
        state = {
            ...state,
            currentUserCommitteeRoles: Enumerable.empty()
        };
        break;
    case USER_COMMITTEE_ROLES_SUCCESS:
        // Update the current committee roles on success
        state = {
            ...state,
            currentUserCommitteeRoles: action.roles
        };

        if (action.cb)
            defer(() => action.cb(action.roles));

        break;
    case UPDATE_USER_DETAILS_SUCCESS:
    case UPDATE_USER_COMMITTEE_ROLE_SUCCESS:
    case CREATE_USER_COMMITTEE_ROLE_SUCCESS:
    case DELETE_USER_COMMITTEE_ROLE_SUCCESS:
    case UPDATE_USER_HASH_SUCCESS:
        // Call the success callback if it is specified
        if (action.cb)
            defer(() => action.cb());

        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestUsers,
    requestSingleUser,
    requestUserCommitteeRoles,
    requestDeleteUserCommitteeRole,
    requestCreateUserCommitteeRole,
    requestUpdateUserCommitteeRole,
    requestUpdateUserDetails,
    requestNewHash,
    requestCommitteeMembers
};
