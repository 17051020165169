import React, {Component} from 'react';

import {connect} from 'react-redux';
import {
    requestAllFormNames,
    requestAllForms,
    requestProposalFormOrder,
    requestDeleteMultiForm
} from '../../stores/forms/store';
import {requestSingleCall} from '../../stores/calls/store';
import {JsonForm} from '../../components/JsonForm';
import {
    Button,
    Col,
    DropdownButton,
    ListGroup,
    ListGroupItem,
    MenuItem,
    Modal,
    PageHeader,
    Panel,
    PanelGroup,
    Row,
    Tab,
    Tabs
} from 'react-bootstrap';
import {defer, navigate} from '../../utils/generalUtils';
import {
    addCollaborator,
    addReviewer,
    checkLockStatus, deleteProposalUpload,
    releaseLockStatus,
    removeCollaborator,
    removeReviewer,
    requestProposal,
    requestUpdateProposalDecisionSummary,
    requestUpdateProposalName,
    setProposalState
} from '../../stores/proposals/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCommitteeMembers} from '../../stores/users/store';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {DEBUG} from '../../App';
import Enumerable from 'linq';
import {toast} from 'react-toastify';
import {Control, LocalForm} from 'react-redux-form';
import JsonSchemaTinyMce from '../../components/JsonSchemaTinyMce';
import PageLoading from '../../components/PageLoading';
import {MULTI_FORM_TYPES} from '../../utils/constants';
import {BootstrapInput, BootstrapSelect} from '../../utils/BootstrapInputs';
import ServerErrors from '../../utils/ServerErrors';
import {resetRequestErrors} from '../../stores/misc/store';
import XHRUploader from '../../extra/react-xhr-uploader';
import localStorage from '../../utils/localStorage';
import SavePDF from '../../utils/SavePDF';
import CustomEvent from '../../utils/CustomEvent';

/* eslint react/no-danger: 0 */
/* eslint no-unused-vars: 0 */

class Proposal extends Component {
    constructor() {
        super();

        this.state = {
            isNew: true,
            proposalId: null,
            formData: {
                stateVal: 0,
                collaborators: Enumerable.empty(),
                proposalName: ''
            },
            showManageCollaborators: false,
            showManageReviewers: false,
            showManageFiles: false,
            saving: false,
            error: false,
            errorForm: null,
            waitingSubmits: Enumerable.empty(),
            locked: true,
            lockedBy: null,
            lockTimer: null,
            activeTab: 0,
            singleSave: false,
            accordionStates: {},
            saveCount: 1,
            showDecisionSummaryModal: false,
            isLoading: true,
            tempProposalName: '',
            disableValidation: false
        };

        this.rendered_forms = {};
    }

    checkLock() {
        // Poll the server to notify we're working with this proposal
        defer(() => this.props.checkLockStatus(
            this.state.proposalId,
            (response) => {
                // Check what the response was from the server
                if (response.holderId === this.props.localUser.id) {
                    defer(
                        () => this.setState({
                            ...this.state,
                            locked: false
                        })
                    );
                } else {
                    defer(
                        () => this.setState({
                            ...this.state,
                            locked: true,
                            lockedBy: response.holder
                        })
                    );
                }
            }
        ));
    }

    releaseLock() {
        // Notify the server we're releasing the lock now
        this.props.releaseLockStatus(this.state.proposalId);
    }

    setLockTimer() {
        // Check if the timer is running, and set it if not
        if (this.state.lockTimer === null) {
            this.setState({
                ...this.state,
                lockTimer: setInterval(() => this.checkLock(), 5000)
            });

            // Set a window onbeforeunload handler to release the lock if the
            window.addEventListener('beforeunload', () => this.releaseLock());

            // Immediately check the server anyway
            this.checkLock();
        }
    }

    noValidateSave(formId) {
        // Called (currently by PDF widgets) to disable validation on a form so it can be saved immediately after
        // setting the file id of the uploaded PDF
        this.setState(
            {
                ...this.state,
                disableValidation: true,
                singleSave: true
            },
            () => {
                this.rendered_forms[formId].jsonForm.onSubmit(new CustomEvent(null));
                defer(() => this.setState(
                    {
                        ...this.state,
                        disableValidation: false
                    })
                );
            }
        );
    }

    componentWillUnmount() {
        // Delete the lock timer if it's set
        if (this.state.lockTimer !== null) {
            clearInterval(this.state.lockTimer);
            this.releaseLock();
        }

        // Cleare the onbeforeunload
        window.removeEventListener('beforeunload', () => this.releaseLock());
    }

    componentWillMount() {
        // Ask the server for the current call and the list of forms in order for the proposal
        this.props.requestSingleCall(this.props.match.params.callId, () =>
            this.props.requestAllForms(() =>
                this.props.requestAllFormNames(() =>
                    this.props.requestProposalFormOrder(
                        this.props.match.params.callId, () => {
                            // Check if we need to fetch proposal data from the server
                            if (this.props.match.params.proposalId) {
                                // Yes, ask the server for the proposal data
                                this.props.requestProposal(this.props.match.params.proposalId,
                                    (data) => {
                                        // Update the form data from the proposal data
                                        this.setState(
                                            {
                                                ...this.state,
                                                formData: data,
                                                isNew: false,
                                                proposalId: parseInt(this.props.match.params.proposalId, 10),
                                                isLoading: false,
                                                tempProposalName: data.proposalName,
                                                proposalTypeId: data.proposalTypeId
                                            },
                                            () => this.setLockTimer()
                                        );
                                    }
                                );
                            } else {
                                this.setState({
                                    ...this.state,
                                    isLoading: false
                                });
                            }
                        }
                    )
                )
            )
        );
    }

    canReview() {
        // Can review if system is being debugged
        if (DEBUG)
            return true;

        // Can't review if the call is not allowing reviews yet
        if (!this.props.call.canSubmitReview())
            return false;

        // Can't review this proposal if the proposal is still able to be submitted, i.e. has an extension
        if (this.props.call.canSubmitProposal(this.state.formData.authorId))
            return false;

        // Can't review a proposal that is not saved
        if (this.state.isNew)
            return false;

        // Can't review a proposal that is not submitted
        if (this.state.formData.stateVal < 1)
            return false;

        // If the local user is an author or collaborator, then we can not review our own proposal
        if (!!this.state.formData.collaborators.any((c) => c.id === this.props.localUser.id) || this.isAuthor())
            return false;

        // Can't review this proposal if the proposal is closed review and we're not a reviewer
        if (!this.props.call.isReviewOpen && !this.state.formData.reviewers.any(r => r.id === this.props.localUser.id))
            return false;

        // Check that this user has at least a member role
        return this.props.localUser.isMember(this.props.call.committee);
    }

    canEdit() {
        if (DEBUG)
            return true;

        // Can't edit if the proposal is currently saving
        if (this.state.saving)
            return false;

        // Can't edit if the proposal is locked by someone else
        if (this.state.locked && !this.state.isNew)
            return false;

        // Can't edit the proposal if it's submitted
        if (this.state.formData.stateVal >= 1)
            return false;

        // Can't save if the proposal no longer allows submissions
        if (this.state.formData.authorId)
            if (!this.props.call.canSubmitProposal(this.state.formData.authorId))
                return false;
            else if (!this.props.call.canSubmitProposal(this.props.localUser.id))
                return false;

        // Can edit if this is new
        if (this.state.isNew)
            return true;

        // Can edit this proposal if this user is the author
        if (this.isAuthor())
            return true;

        // Can't edit if this user is not one of the collaborators
        return !!this.state.formData.collaborators.any((c) => c.id === this.props.localUser.id);
    }

    isAuthor() {
        if (DEBUG)
            return true;

        // If the current user is the author return true
        return this.state.formData.authorId === this.props.localUser.id;
    }

    renderAddCollaborator() {
        // Render the current list of collaborators
        let currentCollaborators;
        if (this.state.formData.collaborators.count())
            currentCollaborators = this.state.formData.collaborators.select((c, idx) => (
                <ListGroupItem key={idx}>
                    {/* Create the item text */}
                    {c.name} - {c.email}

                    {/* Create a remove item button*/}
                    <Button bsSize="xs" bsStyle="default" className="pull-right" onClick={
                        () => this.props.removeCollaborator(this.state.proposalId, c.id, () => {
                            // Copy the existing state
                            let state = {
                                ...this.state
                            };

                            // Remove the collaborator from the list of collaborators
                            state.formData.collaborators = state.formData.collaborators.where(u => u.id !== c.id);

                            // Actually update the state
                            this.setState(state);
                        })
                    }>Remove</Button>
                </ListGroupItem>
            )).toArray();
        else
            currentCollaborators = (
                <p>No collaborators</p>
            );

        return (
            <Modal.Dialog bsSize="large">
                <Modal.Header>
                    <Modal.Title>Manage Collaborators</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <div>
                                <ServerErrors errors={this.props.errors}/>
                                <LocalForm
                                    key={this.state.formData.collaborators.count()}
                                    onChange={() => this.props.resetRequestErrors()}
                                    onSubmit={
                                        ({hash}) => {
                                            this.props.addCollaborator(this.state.proposalId, hash,
                                                (details) => {
                                                    // Copy the existing state
                                                    let state = {
                                                        ...this.state
                                                    };

                                                    // Add the collaborator to the list of collaborators
                                                    state.formData.collaborators =
                                                        state.formData.collaborators.concat(
                                                            [
                                                                {
                                                                    id: details.id,
                                                                    name: details.name,
                                                                    email: details.email
                                                                }
                                                            ]
                                                        );

                                                    // Actually update the state
                                                    this.setState(state);

                                                    // Notify the user
                                                    toast.success(`Collaborator ${details.name} added successfully`);
                                                }
                                            );
                                        }
                                    }
                                    initialState={{hash: ''}}>
                                    <Control
                                        placeholder="Collaborator Hash"
                                        component={BootstrapInput}
                                        model=".hash"
                                        required
                                        maxLength={10}
                                    />
                                    <Button type="submit" block>Add Collaborator</Button>
                                </LocalForm>
                            </div>
                        </Col>
                        <Col md={6}>
                            {/* Render the list of collaborators */}
                            <h4>Current Collaborators</h4>
                            <ListGroup>
                                {currentCollaborators}
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({
                        ...this.state,
                        showManageCollaborators: false
                    })}>Close</Button>
                </Modal.Footer>

            </Modal.Dialog>
        );
    }

    renderAddReviewer() {
        // Create a variable to store available reviewers
        let availableReviewers =
            this.props.members.where(
                u => u.id !== this.state.formData.authorId
                    && !this.state.formData.collaborators.any(c => c.id === u.id)
            );

        // Render the current list of reviewers
        let currentReviewers;
        if (this.state.formData.reviewers.count())
            currentReviewers = this.state.formData.reviewers.select((u, idx) => {
                availableReviewers = availableReviewers.where(ac => u.id !== ac.id);
                const c = this.props.members.firstOrDefault(au => au.id === u.id, null);

                if (c === null)
                    return null;

                return (
                    <ListGroupItem key={idx}>
                        {/* Create the item text */}
                        {c.username} - {c.email}

                        {/* Create a remove item button*/}
                        <Button bsSize="xs" bsStyle="default" className="pull-right" onClick={
                            () => this.props.removeReviewer(this.state.proposalId, c.id, () => {
                                // Copy the existing state
                                let state = {
                                    ...this.state
                                };

                                // Remove the reviewer from the list of reviewers
                                state.formData.reviewers = state.formData.reviewers.where(u => u.id !== c.id);

                                // Actually update the state
                                this.setState(state);
                            })
                        }>Remove</Button>
                    </ListGroupItem>
                );
            }).toArray();
        else
            currentReviewers = (
                <p>No reviewers</p>
            );

        return (
            <Modal.Dialog bsSize="large">
                <Modal.Header>
                    <Modal.Title>Manage Reviewers</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            {/* Render the list of available users */}
                            <h4>Available users</h4>
                            <BootstrapTable data={availableReviewers.toArray()} striped search pagination hover>
                                <TableHeaderColumn dataField="username" isKey={true}>Username</TableHeaderColumn>
                                <TableHeaderColumn dataField="email">Email</TableHeaderColumn>
                                <TableHeaderColumn dataField="add" dataFormat={
                                    // Creates an add button that adds the user on this row to the list of users
                                    (cell, row) => (
                                        <Button
                                            bsStyle="default"
                                            className="pull-right"
                                            onClick={
                                                () => this.props.addReviewer(this.state.proposalId, row.id,
                                                    () => {
                                                        // Copy the existing state
                                                        let state = {
                                                            ...this.state
                                                        };

                                                        // Add the reviewer to the list of reviewers
                                                        state.formData.reviewers = state.formData.reviewers.concat([{
                                                            id: row.id,
                                                            name: row.username
                                                        }]);

                                                        // Actually update the state
                                                        this.setState(state);
                                                    }
                                                )
                                            }
                                        >
                                            Add -&gt;
                                        </Button>
                                    )
                                }>Add</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        <Col md={6}>
                            {/* Render the list of reviewers */}
                            <h4>Current Reviewers</h4>
                            <ListGroup>
                                {currentReviewers}
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({
                        ...this.state,
                        showManageReviewers: false
                    })}>Close</Button>
                </Modal.Footer>

            </Modal.Dialog>
        );
    }

    renderManageFiles() {
        return (
            <Modal.Dialog bsSize="large">
                <Modal.Header>
                    <Modal.Title>Manage Uploaded Files</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            {this.state.formData.uploaded_file ? (
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                            `/api/v1/proposal_file/?token=${localStorage.token}` +
                                            `&proposal=${this.state.proposalId}`
                                        }
                                    >
                                        <span className="fa fa-download"/>
                                        Download {this.state.formData.uploaded_file}
                                    </a>

                                    <span
                                        className="btn btn-danger fa fa-times pull-right"
                                        onClick={
                                            () => this.props.deleteProposalUpload(
                                                this.state.proposalId,
                                                () => this.setState(
                                                    {
                                                        ...this.state,
                                                        formData: {
                                                            ...this.state.formData,
                                                            uploaded_file: null
                                                        }
                                                    }
                                                )
                                            )
                                        }
                                    />
                                </div>
                            ) : (
                                <p>No file is uploaded for this proposal</p>
                            )
                            }
                            <hr/>
                            {
                                !this.state.isNew && this.canEdit() ?
                                    (
                                        <XHRUploader
                                            url="/api/v1/proposal_file/"
                                            maxFiles={1}
                                            formData={
                                                {
                                                    call: this.props.call.id,
                                                    proposal: this.state.proposalId,
                                                    token: localStorage.token
                                                }
                                            }
                                            onComplete={
                                                (filename) => this.setState(
                                                    {
                                                        ...this.state,
                                                        formData: {
                                                            ...this.state.formData,
                                                            uploaded_file: filename
                                                        }
                                                    }
                                                )
                                            }
                                        />) : null
                            }
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={
                        () => this.setState(
                            {
                                ...this.state,
                                showManageFiles: false
                            }
                        )
                    }
                    >
                        Close
                    </Button>
                </Modal.Footer>

            </Modal.Dialog>
        );
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        // Get the proposal form order
        let proposalFormOrder = this.props.proposalFormOrder.where((e, i) => e.typeId === this.state.proposalTypeId);
        if (proposalFormOrder.count())
            proposalFormOrder = proposalFormOrder.first().forms;

        // Render the tabs
        const forms = Enumerable.from(proposalFormOrder).select(
            (id, index) => {
                // Get the form and form name for this form id
                const form = this.props.forms.firstOrDefault((f) => f.id === id);
                const formName = this.props.formNames.firstOrDefault((n) => n.id === form.name);
                const strFormName = formName.display_name;

                // Check if this form is a proposal form, or a multi form
                let renderedForm;
                if (!MULTI_FORM_TYPES.any(t => t === formName.type)) {
                    renderedForm = this.renderProposalForm(form, index);
                } else {
                    renderedForm = (
                        <div>
                            {this.renderMultiForm(form, index, strFormName)}
                            <Button
                                onClick={() => {
                                    const formData = {
                                        ...this.state.formData
                                    };
                                    formData[form.id] = formData[form.id] || [];
                                    formData[form.id].push(null);
                                    this.setState({
                                        ...this.state,
                                        formData: formData
                                    });
                                }}
                                disabled={!this.canEdit()}>Add Another</Button>
                        </div>
                    );
                }

                return (
                    <Tab title={strFormName} eventKey={index} key={index}>
                        {renderedForm}
                    </Tab>
                );
            }
        ).toArray();

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: this.props.call.name,
                            url: `/calls/${this.props.call.id}/`
                        },
                        {
                            name: 'Proposals',
                            url: `/calls/${this.props.call.id}/proposals/`
                        },
                        {
                            name: this.state.isNew ? 'New' : this.state.formData.proposalName,
                            url: `/calls/${this.props.call.id}/` + (this.state.isNew ? 'new' : `proposals/${this.state.proposalId}/`)
                        }
                    ]
                }/>
                <PageHeader>
                    Edit Proposal
                    <div className="pull-right form-inline">
                        {this.state.locked && this.state.lockedBy ?
                            (
                                <Button
                                    className="btn-danger"
                                    disabled bsSize="xsmall"
                                    style={{'marginRight': '10px'}}
                                >
                                    Locked by {this.state.lockedBy}
                                </Button>
                            ) : null
                        }

                        {this.state.formData.proposalName && this.state.formData.proposalName.length ?
                            (
                                <div style={{'display': 'inline'}}>
                                    <label style={{'fontSize': '14px'}}>Proposal Name:&nbsp;</label>

                                    <input
                                        className="form-control" value={this.state.tempProposalName}
                                        onChange={(v) => this.setState({
                                            ...this.state,
                                            tempProposalName: v.target.value
                                        })}
                                        maxLength={100}
                                    />

                                    <Button
                                        onClick={() =>
                                            this.props.requestUpdateProposalName(
                                                this.props.call.id,
                                                this.state.isNew,
                                                this.state.proposalId,
                                                this.state.tempProposalName,
                                                (response) => {
                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            proposalId: response.proposalId,
                                                            isNew: false,
                                                            formData: {
                                                                ...this.state.formData,
                                                                proposalName: response.proposalName,
                                                                author: response.author,
                                                                authorId: response.authorId
                                                            }
                                                        },
                                                        () => this.setLockTimer()
                                                    );
                                                })}
                                        disabled={!this.state.tempProposalName.length || !this.canEdit()}
                                    >Update</Button>

                                    &nbsp;
                                </div>
                            ) : null}
                        <DropdownButton id="manage-proposal" title="Manage" pullRight>
                            <SavePDF
                                callId={this.props.call.id}
                                proposalId={this.state.proposalId}
                                component={(<MenuItem/>)}
                                extra={{disabled: !this.state.proposalId}}
                            />
                            {this.canReview() ? (
                                <MenuItem
                                    onClick={() => navigate(this, `${this.props.match.url}review/`)}
                                    disabled={!this.state.proposalId}>
                                    Add/Edit Review
                                </MenuItem>
                            ) : null}
                            {this.canReview() ? (
                                <MenuItem
                                    onClick={() => navigate(this, `${this.props.match.url}reviews/`)}>
                                    View Reviews
                                </MenuItem>
                            ) : null}
                            {this.isAuthor() ? (
                                <MenuItem
                                    onClick={() => this.setState(
                                        {
                                            ...this.state,
                                            showManageCollaborators:
                                                !this.state.locked
                                                && this.props.call.canSubmitProposal(this.state.formData.authorId)
                                        }
                                    )
                                    }
                                    disabled={
                                        this.state.locked
                                        || !this.props.call.canSubmitProposal(this.state.formData.authorId)
                                    }
                                >
                                    Manage Collaborators
                                </MenuItem>
                            ) : null}
                            {/*
                            Reviewers can be managed if the current user is the chair,
                            and the call is closed review,
                            and we're outside the submission time
                            */}
                            {this.props.localUser.isChair(this.props.call.committee) ? (
                                <MenuItem
                                    onClick={
                                        () => this.props.requestCommitteeMembers(
                                            this.props.call.committee,
                                            () => this.setState(
                                                {
                                                    ...this.state,
                                                    showManageReviewers:
                                                        !this.props.call.canSubmitProposal(
                                                            this.state.formData.authorId
                                                        )
                                                }
                                            )
                                        )
                                    }
                                    disabled={this.props.call.canSubmitProposal(this.state.formData.authorId)}
                                >
                                    Manage Reviewers
                                </MenuItem>
                            ) : null}

                            <MenuItem
                                onClick={
                                    () => this.setState({
                                        showManageFiles: true
                                    })
                                }
                                disabled={this.state.isNew}
                            >
                                Manage Uploaded Files
                            </MenuItem>

                            {/* A chair can set a proposal decision summary for a proposal to indicate why it
                            did or didn't success */}

                            {
                                !this.state.isNew
                                && this.props.call.canSubmitProposal(this.state.formData.authorId)
                                && this.isAuthor() ?
                                    (
                                        <MenuItem
                                            onClick={() => this.props.setProposalState(
                                                this.state.proposalId,
                                                this.state.formData.stateVal === 0 ? 1 : 0,
                                                () => {
                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            formData: {
                                                                ...this.state.formData,
                                                                state: this.state.formData.stateVal === 0
                                                                    ? 'Submitted' : 'Draft',
                                                                stateVal: this.state.formData.stateVal === 0 ? 1 : 0
                                                            }
                                                        }
                                                    );

                                                    // Notify the user
                                                    toast.success(
                                                        this.state.formData.stateVal === 0
                                                            ? 'Proposal Retracted' : 'Proposal Submitted'
                                                    );

                                                })} disabled={this.state.locked}>
                                            {
                                                this.state.formData.stateVal === 0
                                                    ? 'Submit Proposal' : 'Retract Proposal'
                                            }
                                        </MenuItem>
                                    ) : null}
                        </DropdownButton>
                    </div>
                </PageHeader>
                {(!this.state.formData.proposalName || !this.state.formData.proposalName.length) && this.canEdit() ?
                    (
                        <div>
                            <Col md={4} mdOffset={4}>
                                <Panel header={<h3>Create Proposal</h3>} className="login-panel">
                                    <LocalForm
                                        onSubmit={({proposalName, proposalTypeId}) => {
                                            proposalTypeId = parseInt(proposalTypeId);
                                            this.props.requestUpdateProposalName(
                                                this.props.call.id,
                                                this.state.isNew,
                                                this.state.proposalId,
                                                proposalName,
                                                proposalTypeId,
                                                (response) => {
                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            proposalId: response.proposalId,
                                                            proposalTypeId: proposalTypeId,
                                                            isNew: false,
                                                            formData: {
                                                                ...this.state.formData,
                                                                proposalName: proposalName,
                                                                author: response.author,
                                                                authorId: response.authorId
                                                            },
                                                            tempProposalName: proposalName
                                                        },
                                                        () => this.setLockTimer()
                                                    );
                                                });
                                        }
                                        }
                                        initialState={{proposalName: ''}}>
                                        <Control
                                            placeholder="Proposal Name"
                                            component={BootstrapInput}
                                            model=".proposalName"
                                            required
                                            maxLength={100}
                                        />
                                        <Control
                                            placeholder="Proposal Type"
                                            component={BootstrapSelect}
                                            options={
                                                Enumerable.from(
                                                    [
                                                        (
                                                            <option key={0} value="">---</option>
                                                        )
                                                    ]
                                                ).concat(
                                                    this.props.proposalFormOrder.select((p, i) => (
                                                        <option
                                                            key={p.typeId}
                                                            value={p.typeId}
                                                        >
                                                            {p.typeName}
                                                        </option>)
                                                    )
                                                ).toArray()}
                                            model=".proposalTypeId"
                                            required
                                        />
                                        <Button type="submit" bsSize="large" bsStyle="success" block>Create
                                            Proposal</Button>
                                    </LocalForm>
                                </Panel>
                            </Col>
                        </div>
                    ) : (
                        <div>
                            {
                                this.props.localUser.isChair(this.props.call.committee)
                                && !this.props.call.canSubmitProposal(this.state.formData.authorId)
                                && !this.props.call.closed ?
                                    (
                                        <PanelGroup>
                                            <Panel collapsible defaultExpanded header="Decision Summary">
                                                <LocalForm
                                                    initialState={this.state.formData}
                                                    onSubmit={
                                                        ({decisionSummary}) =>
                                                            this.props.requestUpdateProposalDecisionSummary(
                                                                this.state.proposalId,
                                                                decisionSummary,
                                                                () => {
                                                                    toast.success('Proposal Decision Summary Updated');
                                                                }
                                                            )
                                                    }
                                                >

                                                    {/* The decision summary */}
                                                    <Control
                                                        component={JsonSchemaTinyMce}
                                                        model=".decisionSummary"
                                                    />

                                                    <Button type="submit">Save</Button>
                                                </LocalForm>
                                            </Panel>
                                        </PanelGroup>
                                    ) :
                                    // Only display the decision summary if it's actually been set
                                    this.state.formData.decisionSummary
                                    && this.state.formData.decisionSummary.length ?
                                        (
                                            <PanelGroup>
                                                <Panel collapsible defaultExpanded header="Decision Summary">
                                                    <div
                                                        dangerouslySetInnerHTML={
                                                            {'__html': this.state.formData.decisionSummary}
                                                        }
                                                    />
                                                </Panel>
                                            </PanelGroup>
                                        ) : null
                            }
                            <Tabs
                                id="proposal-forms"
                                activeKey={this.state.activeTab}
                                onSelect={(key, event) => this.setState({...this.state, activeTab: key})}>
                                {forms}
                            </Tabs>
                            {this.state.showManageCollaborators ? this.renderAddCollaborator() : null}
                            {this.state.showManageReviewers ? this.renderAddReviewer() : null}
                            {this.state.showManageFiles ? this.renderManageFiles() : null}
                        </div>
                    )
                }
            </div>
        );
    }

    // eslint-disable-next-line no-unused-vars
    onFormError(form, errors) {
        this.setState({
            ...this.state,

            // Set an error state
            error: true,

            // Set the current tab to the tab with the error
            activeTab: this.props.proposalFormOrder.first(
                // eslint-disable-next-line no-unused-vars
                (e, i) => e.typeId === this.state.proposalTypeId
            ).forms.indexOf(form.id),

            // Stop saving
            saving: false
        });

        // Notify the user of the error
        toast.error('Save failed. There is an error with a form.');
    }

    renderProposalForm(form, index) {
        return <JsonForm
            id={form.id}
            formKey={this.state.saveCount}
            refSubmit={
                (jsonForm) => {
                    if (jsonForm) {
                        this.rendered_forms[form.id] =
                            {
                                jsonForm: jsonForm,
                                index: index,
                                form: form
                            };
                    }
                }
            }
            postURL="/proposal_responses/"
            initialData={this.state.formData[form.id] || null}
            formContext={{
                proposalId: this.state.proposalId,
                formId: form.id,
                noValidateSave: () => this.noValidateSave(form.id),
                onStartUpload: () => this.setState({...this.state, saving: true}),
                onCancelUpload: () => this.setState({...this.state, saving: false}),
                canEdit: this.canEdit()
            }}
            disabled={!this.canEdit()}
            noValidate={this.state.disableValidation}
            onError={(errors) => this.onFormError(form, errors)}
            onSubmit={() => {
                let saveHash;
                if (this.state.singleSave) {
                    saveHash = Math.random() * Math.pow(2, 31) | 0;

                    this.setState({
                        ...this.state,
                        saving: true,
                        waitingSubmits: Enumerable.empty(),
                        saveHash: saveHash
                    });
                } else {
                    const waitingSubmits =
                        this.state.waitingSubmits.count() ?
                            this.state.waitingSubmits.where(f => f.key !== form.id.toString()) :
                            Enumerable.from(this.rendered_forms).where(i => i.key !== form.id.toString());

                    saveHash = this.state.waitingSubmits.count() ?
                        this.state.saveHash : (Math.random() * Math.pow(2, 31) | 0);

                    this.setState({
                        ...this.state,
                        saving: true,
                        waitingSubmits: waitingSubmits,
                        saveHash: saveHash
                    });
                }

                return {
                    // The call we are saving a proposal response for
                    call: this.props.call.id,

                    // The proposal type
                    proposalTypeId: this.state.proposalTypeId,

                    // The proposal id if it is set
                    proposal: this.state.proposalId,

                    // If the server should actually create a new proposal or not
                    new: this.state.isNew,

                    // Set the save hash
                    hash: saveHash,
                };
            }}
            onSuccess={
                // Update the data and the proposal id
                (data, response) => {
                    // Copy the form data from the state
                    const formData = {
                        ...this.state.formData
                    };

                    // Update the data for this form in the form data
                    formData[form.id] = data;

                    // Set the author and authorId from the response
                    formData.author = response.author;
                    formData.authorId = response.authorId;

                    // Update the state
                    this.setState(
                        {
                            ...this.state,

                            // Once the form has saved, the proposal is no longer new
                            isNew: false,

                            // Set the proposal id
                            proposalId: response.proposalId,

                            // Update the form data
                            formData: formData,

                            // Update the saving status
                            saving: this.state.waitingSubmits.any(),

                            // Update the save counter if needed
                            saveCount: this.state.waitingSubmits.any() ?
                                this.state.saveCount : this.state.saveCount + 1,

                            // Clear the error if there is one
                            error: null,

                            // Not saving a single form anymore
                            singleSave: false,
                        },
                        () => this.setLockTimer()
                    );

                    if (this.state.waitingSubmits.count() && !this.state.singleSave) {
                        defer(() => this.state.waitingSubmits.first().value.jsonForm.onSubmit(new CustomEvent(null)));
                    } else {
                        toast.success('Saved successfully.');
                    }
                }
            }
            onChange={
                // Update the form data
                // This actually is handled because changes to other forms are lost
                // if this one is submitted
                (data) => {
                    // Copy the form data from the state
                    const formData = {
                        ...this.state.formData
                    };

                    // Set the data for this form
                    formData[form.id] = data;

                    // Update the state
                    this.setState(
                        {
                            ...this.state,

                            // Update the form data
                            formData: formData
                        }
                    );
                }
            }
            buttons={(
                <div>
                    <Button
                        disabled={!this.canEdit()}
                        onClick={() => {
                            if (!this.state.formData.proposalName.length)
                                toast.error('Please set a Proposal Name before saving any pages.');
                            else
                                this.rendered_forms[form.id].jsonForm.onSubmit(new CustomEvent(null));
                        }}
                    >
                        {this.state.saving ? (<div>Saving...
                            <li className="fa fa-spin fa-spinner"/>
                        </div>) : 'Save All'}
                    </Button>
                    <Button
                        disabled={!this.canEdit()}
                        onClick={() => {
                            if (!this.state.formData.proposalName.length)
                                toast.error('Please set a Proposal Name before saving any pages.');
                            else {
                                // Remember that we're only saving this form
                                this.setState({
                                    ...this.state,
                                    singleSave: true
                                }, () => {
                                    // Submit the current form, to trigger a save all
                                    this.rendered_forms[form.id].jsonForm.onSubmit(new CustomEvent(null));
                                });
                            }
                        }}
                    >
                        {this.state.saving ? (<div>Saving...
                            <li className="fa fa-spin fa-spinner"/>
                        </div>) : 'Save'}
                    </Button>
                </div>
            )}
        />;
    }

    deleteMultiForm(form, dataIndex) {
        // eslint-disable-next-line no-alert
        if (window.confirm('Do you really want to delete this entry?')) {
            // Construct the new form data locally
            const formData = {
                ...this.state.formData
            };

            // Remove the form being deleted
            formData[form.id].splice(dataIndex, 1);

            // Reset the rendered forms
            this.rendered_forms = {};

            // Update the state with the new form data
            this.setState({
                ...this.state,
                formData: formData
            });

            // Tell the server to delete the multi form with this index
            this.props.requestDeleteMultiForm(this.props.call.id, this.state.proposalId, form.id, dataIndex);
        }
    }

    renderMultiForm(form, index, strFormName) {
        const formData = (this.state.formData[form.id] && this.state.formData[form.id].length) ?
            this.state.formData[form.id] : [null];
        const forms = Enumerable.from(formData).select((formData, dataIndex) => (
            <Panel
                key={dataIndex}
                header={
                    <div>
                        <i
                            className={!this.state.accordionStates[form.id.toString() + '_' + dataIndex.toString()] ?
                                'fa-lg fa fa-caret-right' : 'fa-lg fa fa-caret-down'}
                            aria-hidden="true"/>
                        {` ${strFormName} (${dataIndex + 1})`}
                        <Button
                            onClick={(ev) => {
                                this.deleteMultiForm(form, dataIndex);
                                ev.preventDefault();
                                ev.stopPropagation();
                            }}
                            className="pull-right btn-danger"
                            bsSize="xsmall"
                            disabled={
                                !this.state.formData[form.id]
                                || this.state.formData[form.id].length <= 1
                                || !this.canEdit()
                            }
                        >Delete</Button>
                    </div>
                }
                collapsible
                onSelect={() => {
                    const accordionStates = this.state.accordionStates;

                    accordionStates[form.id.toString() + '_' + dataIndex.toString()] =
                        // eslint-disable-next-line no-prototype-builtins
                        accordionStates.hasOwnProperty(form.id.toString() + '_' + dataIndex.toString()) ?
                            !accordionStates[form.id.toString() + '_' + dataIndex.toString()]
                            : true;

                    this.setState({
                        ...this.state,
                        accordionStates: accordionStates
                    });
                }}
                eventKey={form.id.toString() + '_' + dataIndex.toString()}>
                <JsonForm
                    key={dataIndex}
                    formKey={this.state.saveCount}
                    id={form.id}
                    refSubmit={
                        (jsonForm) => {
                            if (jsonForm) {
                                this.rendered_forms[form.id.toString() + '_' + dataIndex.toString()] =
                                    {
                                        jsonForm: jsonForm,
                                        index: index,
                                        form: form
                                    };
                            }
                        }
                    }
                    postURL="/proposal_responses/"
                    initialData={formData || null}
                    formContext={{
                        proposalId: this.state.proposalId,
                        formId: form.id,
                        noValidateSave: () => this.noValidateSave(form.id.toString() + '_' + dataIndex.toString()),
                        onStartUpload: () => this.setState({...this.state, saving: true}),
                        onCancelUpload: () => this.setState({...this.state, saving: false}),
                        canEdit: this.canEdit()
                    }}
                    disabled={!this.canEdit()}
                    noValidate={this.state.disableValidation}
                    onError={(errors) => this.onFormError(form, errors)}
                    onSubmit={() => {
                        let saveHash;
                        if (this.state.singleSave) {
                            saveHash = Math.random() * Math.pow(2, 31) | 0;

                            this.setState({
                                ...this.state,
                                saving: true,
                                waitingSubmits: Enumerable.empty(),
                                saveHash: saveHash
                            });
                        } else {
                            const waitingSubmits =
                                this.state.waitingSubmits.count() ?
                                    this.state.waitingSubmits.where(
                                        f => f.key !== form.id.toString() + '_' + dataIndex.toString()
                                    ) :
                                    Enumerable.from(this.rendered_forms).where(
                                        i => i.key !== form.id.toString() + '_' + dataIndex.toString()
                                    );

                            saveHash = this.state.waitingSubmits.count() ?
                                this.state.saveHash : (Math.random() * Math.pow(2, 31) | 0);

                            this.setState({
                                ...this.state,
                                saving: true,
                                waitingSubmits: waitingSubmits,
                                saveHash: saveHash
                            });
                        }

                        return {
                            // The call we are saving a proposal response for
                            call: this.props.call.id,

                            // The proposal type
                            proposalTypeId: this.state.proposalTypeId,

                            // The proposal id if it is set
                            proposal: this.state.proposalId,

                            // If the server should actually create a new proposal or not
                            new: this.state.isNew,

                            // Set the index of the form
                            index: dataIndex,

                            // Set the save hash
                            hash: saveHash,

                            // If we're doing a single save
                            singleSave: this.state.singleSave
                        };
                    }}
                    onSuccess={
                        // Update the data and the proposal id
                        (data, response) => {
                            // Copy the form data from the state
                            const formData = {
                                ...this.state.formData
                            };

                            // Update the data for this form in the form data
                            formData[form.id] = formData[form.id] || [null];
                            formData[form.id][dataIndex] = data;

                            // Set the author and authorId from the response
                            formData.author = response.author;
                            formData.authorId = response.authorId;

                            // Update the state
                            this.setState(
                                {
                                    ...this.state,

                                    // Once the form has saved, the proposal is no longer new
                                    isNew: false,

                                    // Set the proposal id
                                    proposalId: response.proposalId,

                                    // Update the form data
                                    formData: formData,

                                    // Update the saving status
                                    saving: this.state.waitingSubmits.any(),

                                    // Update the save counter
                                    saveCount: this.state.waitingSubmits.any() ?
                                        this.state.saveCount : this.state.saveCount + 1,

                                    // Clear the error if there is one
                                    error: null,

                                    // Not saving a single form anymore
                                    singleSave: false,
                                },
                                () => this.setLockTimer()
                            );

                            if (this.state.waitingSubmits.count() && !this.state.singleSave) {
                                defer(
                                    () => this.state.waitingSubmits.first().value.jsonForm.onSubmit(
                                        new CustomEvent(null)
                                    )
                                );
                            } else {
                                toast.success('Saved successfully.');
                            }
                        }
                    }
                    onChange={
                        // Update the form data
                        // This actually is handled because changes to other forms are lost
                        // if this one is submitted
                        (data) => {
                            // Copy the form data from the state
                            const formData = {
                                ...this.state.formData
                            };

                            // Set the data for this form
                            formData[form.id] = formData[form.id] || [null];
                            formData[form.id][dataIndex] = data;

                            // Update the state
                            this.setState(
                                {
                                    ...this.state,

                                    // Update the form data
                                    formData: formData
                                }
                            );
                        }
                    }
                    buttons={(
                        <div>
                            <Button
                                disabled={!this.canEdit()}
                                onClick={() => {
                                    if (!this.state.formData.proposalName.length)
                                        toast.error('Please set a Proposal Name before saving any pages.');
                                    else
                                        // Submit the current form, to trigger a save all
                                        this.rendered_forms[form.id.toString() + '_' + dataIndex.toString()]
                                            .jsonForm.onSubmit(new CustomEvent(null));
                                }}
                            >
                                {this.state.saving ? (<div>Saving...
                                    <li className="fa fa-spin fa-spinner"/>
                                </div>) : 'Save All'}
                            </Button>
                            <Button
                                disabled={!this.canEdit()}
                                onClick={() => {
                                    if (!this.state.formData.proposalName.length)
                                        toast.error('Please set a Proposal Name before saving any pages.');
                                    else {
                                        // Remember that we're only saving this form
                                        this.setState(
                                            {
                                                ...this.state,
                                                singleSave: true
                                            }, () => {
                                                // Submit the current form, to trigger a save all
                                                this.rendered_forms[form.id.toString() + '_' + dataIndex.toString()]
                                                    .jsonForm.onSubmit(new CustomEvent(null));
                                            }
                                        );
                                    }
                                }}
                            >
                                {this.state.saving ? (<div>Saving...
                                    <li className="fa fa-spin fa-spinner"/>
                                </div>) : 'Save'}
                            </Button>
                        </div>
                    )}
                />
            </Panel>)
        ).toArray();

        return (
            <PanelGroup>
                {forms}
            </PanelGroup>
        );
    }
}

const mapStateToProps = ({calls, forms, auth, users, misc}) => ({
    call: calls.currentCall,
    proposalFormOrder: forms.proposalFormOrder,
    forms: forms.all,
    formNames: forms.names,
    localUser: auth.localUser,
    members: users.members,
    errors: misc.errors
});

export default connect(mapStateToProps, {
    requestAllFormNames,
    requestProposalFormOrder,
    requestSingleCall,
    requestAllForms,
    requestProposal,
    addCollaborator,
    removeCollaborator,
    setProposalState,
    addReviewer,
    removeReviewer,
    checkLockStatus,
    releaseLockStatus,
    requestUpdateProposalDecisionSummary,
    requestUpdateProposalName,
    requestDeleteMultiForm,
    requestCommitteeMembers,
    resetRequestErrors,
    deleteProposalUpload
})(Proposal);
