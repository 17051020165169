import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import ServerErrors from '../../utils/ServerErrors';
import {requestPasswordReset} from '../../stores/auth/store';
import {Button, Col, Image, Panel} from 'react-bootstrap';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapInput} from '../../utils/BootstrapInputs';
import logo from '../../assets/images/logo.png';

class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            done: false
        };
    }

    render() {
        const {errors} = this.props;

        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <div className="text-center">
                        <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                        <h1>Proposal System</h1>
                    </div>

                    <Panel header={<h3>Password Reset</h3>} className="login-panel">
                        {this.state.done ? (
                            <p>
                                Please check your email for a password reset email.
                            </p>
                        ) : (
                            <div>
                                <ServerErrors errors={errors}/>
                                <LocalForm
                                    onSubmit={
                                        ({email}) => {
                                            this.props.requestPasswordReset(email, () => {
                                                this.setState({
                                                    done: true
                                                });
                                            });
                                        }
                                    }
                                    initialState={{email: ''}}>
                                    <Control
                                        placeholder="Email"
                                        component={BootstrapInput}
                                        model=".email"
                                        required
                                        type="email"
                                        maxLength={254}
                                    />
                                    <p>Know your password? <Link to="/auth/login">Login</Link></p>
                                    <Button
                                        type="submit"
                                        bsSize="large"
                                        bsStyle="success"
                                        block
                                    >
                                        Reset Password
                                    </Button>
                                </LocalForm>
                            </div>
                        )}
                    </Panel>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = ({misc}) => ({
    errors: misc.errors
});

export default connect(mapStateToProps, {
    requestPasswordReset,
})(ResetPassword);
