import React, {Component} from 'react';
import {Button, PageHeader} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {navigate} from '../../utils/generalUtils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {requestCalls} from '../../stores/calls/store';
import {requestCommittees} from '../../stores/committees/store';
import PageLoading from '../../components/PageLoading';
import CallList from './components/CallList';

class BaseListCalls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestCommittees(
            () => this.props.requestCalls(
                () => this.setState({
                    isLoading: false
                }),
                this.props.which_calls
            )
        );
    }


    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading)
            return (<PageLoading/>);

        return (
            <div>
                <Breadcrumbs items={this.props.breadcrumbData}/>

                <PageHeader>
                    {this.props.pageTitle}
                    {this.props.localUser.isChair() ?
                        (<Button className="pull-right" onClick={() => navigate(this, '/calls/new')}>
                            Add Call for Proposal
                        </Button>) : null}
                </PageHeader>
                
                <CallList 
                    calls={this.props.calls}
                    committees={this.props.committees}
                    user = {this.props.localUser}
                />
                <div>
                    <hr/>
                    <Button className="pull-right" onClick={() => navigate(this, (this.props.which_calls === 'open') ?
                        '/calls/closed' : '/calls/')}>
                        {(this.props.which_calls === 'open') ? 'See Closed Calls' : 'See Open Calls'}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({calls, auth, committees}) => 
    // Set the state accordingly
    ({
        calls: calls.all,
        localUser: auth.localUser,
        committees: committees
    })
;

const ListCalls = withRouter(connect(mapStateToProps, {requestCalls, requestCommittees})(BaseListCalls));


const ListAllCalls = () => (
    <ListCalls
        pageTitle = "All Call for Proposals"
        breadcrumbData = {[
            {name:'Call for Proposals', url:'/calls'},
            {name:'All', url:'/calls/all/'},
        ]}
        which_calls = "all"
    />
);

const ListOpenCalls = () => (
    <ListCalls
        pageTitle = "Current Call for Proposals"
        breadcrumbData = {[
            {name:'Call for Proposals', url:'/calls'},
        ]}
        which_calls = "open"
    />
);

const ListClosedCalls = () => (
    <ListCalls
        pageTitle = "Call for Proposals History"
        breadcrumbData = {[
            {name:'Call for Proposals', url:'/calls'},
            {name:'History', url:'/calls/closed/'},
        ]}
        which_calls = "closed"
    />
);
                
export {ListAllCalls, ListOpenCalls, ListClosedCalls};
