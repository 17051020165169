import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class Sidebar extends Component {
    render() {
        if (!this.props.loggedIn)
            return (null);

        return (
            <div className="navbar-default sidebar" style={{marginLeft: '-20px'}} role="navigation">
                <div className="sidebar-nav navbar-collapse collapse">
                    <ul className="nav in" id="side-menu">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard fa-fw"/> &nbsp;Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link to="/calls">
                                <i className="fa fa-tasks fa-fw"/> &nbsp;Call for Proposals
                            </Link>
                        </li>
                        {this.props.user.isAdmin() ? (
                            <li>
                                <Link to="/users">
                                    <i className="fa fa-users fa-fw"/> &nbsp;Manage Users
                                </Link>
                            </li>
                        ) : null}
                        {this.props.user.isAdmin() ? (
                            <li>
                                <Link to="/committees">
                                    <i className="fa fa-building fa-fw"/> &nbsp;Manage Committees
                                </Link>
                            </li>
                        ) : null}
                        {this.props.user.isAdmin() ? (
                            <li>
                                <Link to="/forms">
                                    <i className="fa fa-address-card fa-fw"/> &nbsp;Form Builder
                                </Link>
                            </li>
                        ) : null}
                        <li>
                            <Link to="/account">
                                <i className="fa fa-user fa-fw"/> &nbsp;My Account
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    user: auth.localUser,
    loggedIn: auth.loggedIn
});

export default connect(mapStateToProps, {})(Sidebar);