import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {Provider} from 'react-redux';

import Header from './components/Header';
import Content from './components/Content';
import store from './store';

import './assets/css/bootstrap.min.css';
import './assets/css/sb-admin.css';
// import './assets/css/bootstrap-theme.min.css'
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import './assets/css/font-awesome.min.css';
import './assets/css/extra.css';
import 'react-toastify/dist/ReactToastify.min.css';

const DEBUG = false;

function findGetParameter(parameterName) {
    var result = null, tmp = [];
    var items = window.location.search.substr(1).split('&');
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split('=');
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

// Check if there is an incoming auth token
if (findGetParameter('auth_token'))
{
    // Update the token in local storage
    localStorage.token = findGetParameter('auth_token');

    // Reload the page without the auth parameter
    window.location.href = window.location.href.substring(0, window.location.href.indexOf('?'));
}


const App = () => (
    <Provider store={store}>
        <BrowserRouter>
            <div className="App">
                <Route component={
                    (props) => (
                        <div>
                            <Header {...props}/>
                            {Content({...props, store})}
                        </div>
                    )
                } store={store}/>
            </div>
        </BrowserRouter>
    </Provider>
);

export {
    App,
    DEBUG};
