import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Modal, PageHeader, Row, Table} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCommittees} from '../../stores/committees/store';
import {requestNewHash, requestUpdateUserDetails} from '../../stores/users/store';
import {Control, LocalForm} from 'react-redux-form';
import {
    BootstrapCheckbox, BootstrapInput, BootstrapSelect, isLdapSafe, isValidEmail,
    isValidPhone
} from '../../utils/BootstrapInputs';
import ServerErrors from '../../utils/ServerErrors';
import {resetRequestErrors} from '../../stores/misc/store';
import {requestUserDetails} from '../../stores/auth/store';
import {defer} from '../../utils/generalUtils';
import PageLoading from '../../components/PageLoading';

/* eslint react/no-string-refs: 0 */

class MyAccount extends Component {
    constructor() {
        super();

        this.state = {
            showUpdateDialog: false,
            updateField: null,
            updateHeader: null,
            updateComponent: null,
            isLoading: true
        };
    }

    componentWillMount() {
        // Fetch all committees
        this.props.requestCommittees(() =>
            this.setState({
                ...this.state,
                isLoading: false
            })
        );
    }

    updateDetail(field) {
        const detail = {
            'first_name': {
                'header': 'First Name',
                'component': () => (
                    <Control
                        placeholder="First Name"
                        component={BootstrapInput}
                        model=".field"
                        required
                        maxLength={30}
                        error={
                            !isLdapSafe(this.state.field) ?
                                '\\ , : ? | * & ; # < > " = [ ] { } ( ) are restricted characters for this field.'
                                : null
                        }
                    />
                )
            },
            'last_name': {
                'header': 'Last Name',
                'component': () => (
                    <Control
                        placeholder="Last Name"
                        component={BootstrapInput}
                        model=".field"
                        required
                        maxLength={30}
                        error={
                            !isLdapSafe(this.state.field) ?
                                '\\ , : ? | * & ; # < > " = [ ] { } ( ) are restricted characters for this field.'
                                : null
                        }
                    />
                )
            },
            'email': {
                'header': 'Email Address',
                'component': () => (
                    <Control
                        placeholder="Email Address"
                        component={BootstrapInput}
                        model=".field"
                        required
                        type="email"
                        maxLength={254}
                        error={
                            !isLdapSafe(this.state.field) ?
                                '\\ , : ? | * & ; # < > " = [ ] { } ( ) are restricted characters for this field.'
                                : !isValidEmail(this.state.field) ? 'Please enter a valid email address' : null
                        }
                    />
                )
            },
            'institution': {
                'header': 'Institution',
                'component': () => (
                    <Control
                        placeholder="Institution"
                        component={BootstrapInput}
                        model=".field"
                        required
                        maxLength={100}
                        error={
                            !isLdapSafe(this.state.field) ?
                                '\\ , : ? | * & ; # < > " = [ ] { } ( ) are restricted characters for this field.'
                                : null
                        }
                    />
                )
            },
            'genderChoice': {
                'header': 'Gender',
                'component': () => (
                    <Control
                        placeholder="Gender"
                        component={BootstrapSelect}
                        options={[
                            (<option key={0} value="">---</option>),
                            (<option key={1} value="MALE">Male</option>),
                            (<option key={2} value="FEMALE">Female</option>),
                            (<option key={3} value="OTHER">Other/Prefer not to say</option>),
                        ]}
                        model=".field"
                        required
                    />
                )
            },
            'phone_number': {
                'header': 'Phone Number',
                'component': () => (
                    <Control
                        placeholder="Phone Number"
                        component={BootstrapInput}
                        model=".field"
                        required
                        maxLength={30}
                        error={
                            !isLdapSafe(this.state.field) ?
                                '\\ , : ? | * & ; # < > " = [ ] { } ( ) are restricted characters for this field.'
                                : !isValidPhone(this.state.field) ? 'Please enter a valid phone number' : null}
                    />
                )
            },
            'is_student': {
                'header': 'Current Student Status',
                'component': () => (
                    <Control.checkbox
                        label="Are you a student?"
                        component={BootstrapCheckbox}
                        model=".field"
                    />
                )
            }
        }[field];

        this.props.resetRequestErrors();

        this.setState({
            ...this.state,
            showUpdateDialog: true,
            updateField: field,
            updateHeader: detail['header'],
            updateComponent: detail['component'],
            field: this.props.localUser[field]
        });
    }

    renderUserDetails() {
        return (
            <Table bordered condensed hover>
                <tbody>
                    <tr>
                        {/* Display the committee */}
                        <td>User Name</td>
                        <td>{this.props.localUser.username}</td>
                    </tr>
                    <tr>
                        <td>First Name</td>
                        <td>
                            {this.props.localUser.first_name}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('first_name')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>
                            {this.props.localUser.last_name}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('last_name')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Email Address</td>
                        <td>
                            {this.props.localUser.email}
                            {this.props.localUser.is_aaf ? null : (
                                <a
                                    className="pull-right"
                                    onClick={() => this.updateDetail('email')}
                                >
                                    <i className="fa fa-pencil fa-fw"/>
                                </a>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Institution</td>
                        <td>
                            {this.props.localUser.institution}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('institution')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>
                            {this.props.localUser.gender}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('genderChoice')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>
                            {this.props.localUser.phone_number}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('phone_number')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Is Student</td>
                        <td>
                            {this.props.localUser.is_student ? 'Yes' : 'No'}
                            <a
                                className="pull-right"
                                onClick={() => this.updateDetail('is_student')}
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Unique ID</td>
                        <td>
                            <b>{this.props.localUser.hash}</b>
                            <a
                                className="pull-right"
                                onClick={() => this.props.requestNewHash(
                                    () => {
                                        this.props.requestUserDetails();
                                    })
                                }
                            >
                                <i className="fa fa-pencil fa-fw"/>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    renderCommitteeRoles() {
        const roles = this.props.localUser.roles.select(
            (role) => (
                <tr key={role.id}>
                    <td>
                        {this.props.committees.firstOrDefault((c) => c.id === role.committee).name}
                    </td>
                    <td>
                        {role.role}
                    </td>
                </tr>
            )
        ).toArray();

        return (
            <Table bordered condensed hover>
                <thead>
                    <tr>
                        <td>Committee</td>
                        <td>Role</td>
                    </tr>
                </thead>
                <tbody>
                    {roles}
                </tbody>
            </Table>
        );
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'My Account',
                            url: '/account'
                        }
                    ]
                }/>

                <PageHeader>
                    My Account
                </PageHeader>

                <Row>
                    <Col xs={6}>
                        {/* User details */}
                        <h4>User Details</h4>
                        {this.renderUserDetails()}
                    </Col>
                    <Col xs={6}>
                        {/* User committee roles*/}
                        <div>
                            <h4>Current Roles</h4>
                        </div>
                        {this.renderCommitteeRoles()}
                    </Col>
                </Row>

                {this.state.showUpdateDialog ? (
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title>Update {this.state.updateHeader}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LocalForm
                                onSubmit={
                                    ({field}) => {
                                        const data = {
                                            ...this.props.localUser
                                        };
                                        data[this.state.updateField] = field;
                                        this.props.resetRequestErrors();
                                        this.props.requestUpdateUserDetails(data, () => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    showUpdateDialog: false
                                                }
                                            );
                                            defer(() => this.props.requestUserDetails());
                                        });
                                    }
                                }
                                onChange={
                                    (data) => this.setState(
                                        {
                                            ...this.state,
                                            field: data.field
                                        }
                                    )
                                }
                                initialState={{field: this.props.localUser[this.state.updateField]}}>
                                <ServerErrors errors={this.props.errors}/>
                                {this.state.updateComponent()}
                                <button ref='updateSubmitButton' style={{'display': 'none'}} type="submit"/>
                            </LocalForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({showUpdateDialog: false})}>Close</Button>
                            <Button className="btn-default" onClick={() => {
                                this.refs.updateSubmitButton.click();
                            }}>Update</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({committees, auth, misc}) => ({
    errors: misc.errors,
    committees: committees,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestCommittees,
    requestUpdateUserDetails,
    resetRequestErrors,
    requestUserDetails,
    requestNewHash
})(MyAccount);
