// Action types
import {takeEvery} from 'redux-saga/effects';

const ROUTE_CHANGE = 'astacta/route/change';
const REQUEST_ERROR = 'astacta/request_error';
const RESET_REQUEST_ERRORS = 'astacta/request_error/reset';

// Action creators
const routeChange = () => (
    {
        type: ROUTE_CHANGE
    }
);

const resetRequestErrors = () => (
    {
        type: RESET_REQUEST_ERRORS
    }
);

function* watchRouteChange() {
    yield takeEvery(ROUTE_CHANGE, () => {});
}

function* watchRequestError() {
    yield takeEvery(REQUEST_ERROR, () => {});
}

function* watchResetRequestError() {
    yield takeEvery(RESET_REQUEST_ERRORS, () => {});
}

const sagas = [
    watchRouteChange,
    watchRequestError,
    watchResetRequestError
];

// Reducers
const path = 'misc';

const defaultState = {
    errors: null
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
    case ROUTE_CHANGE:
        // Clear the error on route change
        state = {
            ...state,
            errors: null,
        };
        break;
    case REQUEST_ERROR:
        state = {
            ...state,
            errors: action.errors,
        };

        delete state.errors.status;
        break;
    case RESET_REQUEST_ERRORS:
        state = {
            ...state,
            errors: null
        };
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    routeChange,
    resetRequestErrors,
    ROUTE_CHANGE,
    REQUEST_ERROR
};