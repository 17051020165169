import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestUsers} from '../../stores/users/store';
import {PageHeader, Tab, Tabs} from 'react-bootstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import {requestCommittees} from '../../stores/committees/store';
import {formatDateTime} from '../../utils/generalUtils';
import PermissionDenied from '../PermissionDenied';
import {requestCalls} from '../../stores/calls/store';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';

class ViewCommittee extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        // Ask the server for all users
        this.props.requestUsers(() =>
            // Ask the server for all CFPs
            this.props.requestCalls(() =>
                // Fetch all committees
                this.props.requestCommittees(() =>
                    this.setState({
                        isLoading: false
                    })
                )
            )
        );
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        // Get the committee
        const committee =
            this.props.committees.firstOrDefault(
                c => c.id === parseInt(this.props.match.params.committeeId, 10
                )
            );

        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Committees',
                            url: '/committees'
                        },
                        {
                            name: committee.name,
                            url: `/committees/${committee.id}`
                        }
                    ]
                }/>

                <PageHeader>
                    View Committee - {committee.name}
                </PageHeader>

                <Tabs id="committee_details" defaultActiveKey={0}>
                    <Tab title="Members" eventKey={0} key={0}>
                        {this.renderUsers(committee)}
                    </Tab>
                    <Tab title="Calls" eventKey={1} key={1}>
                        {this.renderCalls(committee)}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    renderCalls(committee) {
        const calls = this.props.calls.where(c => c.committee === committee.id);
        const currentCalls = calls.where(c => !c.closed);
        const oldCalls = calls.where(c => c.closed);

        return (
            <div>
                <h3>Current Calls</h3>
                <BootstrapTable data={currentCalls.toArray()} striped search pagination hover>
                    <TableHeaderColumn dataField="name" isKey={true}>Call for Proposal Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="closed" dataFormat={
                        // Renders the current proposal state
                        // eslint-disable-next-line no-unused-vars
                        (cell, row) => cell ? 'Closed' : 'Open'
                    }>State</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="commencement"
                        dataFormat={formatDateTime}
                    >
                        Call Commencement Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="termination"
                        dataFormat={formatDateTime}
                    >
                        Call Termination Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="reviewCommencement"
                        dataFormat={formatDateTime}
                    >
                        Review Commencement Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="reviewTermination"
                        dataFormat={formatDateTime}
                    >
                        Review Termination Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="view_calls"
                        dataFormat={
                            // Creates an add button that adds the form on this row to the list of forms
                            (cell, row) => (<Link to={`/calls/${row.id}/`}>View Call</Link>)
                        }
                    />
                    <TableHeaderColumn dataField="view_proposals" dataFormat={
                        // Creates an add button that adds the form on this row to the list of forms
                        (cell, row) => (<Link to={`/calls/${row.id}/proposals/`}>View Proposals</Link>)
                    }/>
                </BootstrapTable>
                <hr/>
                <h3>Closed Calls</h3>
                <BootstrapTable data={oldCalls.toArray()} striped search pagination hover>
                    <TableHeaderColumn dataField="name" isKey={true}>Call for Proposal Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="closed" dataFormat={
                        // Renders the current proposal state
                        // eslint-disable-next-line no-unused-vars
                        (cell, row) => cell ? 'Closed' : 'Open'
                    }>State</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="commencement"
                        dataFormat={formatDateTime}
                    >
                        Call Commencement Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="termination"
                        dataFormat={formatDateTime}
                    >
                        Call Termination Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="reviewCommencement"
                        dataFormat={formatDateTime}
                    >
                        Review Commencement Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="reviewTermination"
                        dataFormat={formatDateTime}
                    >
                        Review Termination Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="view_calls"
                        dataFormat={
                            // Creates an add button that adds the form on this row to the list of forms
                            (cell, row) => (
                                <Link to={`/calls/${row.id}/`}>
                                    View Call
                                </Link>
                            )
                        }
                    />
                    <TableHeaderColumn
                        dataField="view_proposals"
                        dataFormat={
                            // Creates an add button that adds the form on this row to the list of forms
                            (cell, row) => (
                                <Link to={`/calls/${row.id}/proposals/`}>
                                    View Proposals
                                </Link>
                            )
                        }
                    />
                </BootstrapTable>
            </div>
        );
    }

    renderUsers(committee) {
        const users = this.props.users.where(c => c.roles.any(r => r.committee === committee.id)).select(c => {
            const roles = c.roles.where(r => r.committee === committee.id).select(r => r.role).toArray().join(', ');
            return {
                ...c,
                rolesStr: roles
            };
        });
        return (
            <BootstrapTable data={users.toArray()} striped search pagination hover>
                <TableHeaderColumn dataField="username">User Name</TableHeaderColumn>
                <TableHeaderColumn dataField="email" isKey={true}>Email Address</TableHeaderColumn>
                <TableHeaderColumn dataField="rolesStr">Role</TableHeaderColumn>
                <TableHeaderColumn dataField="view" dataFormat={
                    // Creates an add button that adds the form on this row to the list of forms
                    (cell, row) => (<Link to={`/users/${row.id}/`}>View</Link>)
                }/>
            </BootstrapTable>
        );
    }
}

const mapStateToProps = ({users, committees, auth, calls}) => ({
    users: users.all,
    calls: calls.all,
    committees: committees,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestUsers,
    requestCalls,
    requestCommittees
})(ViewCommittee);
