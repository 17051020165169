import React from 'react';
import {connect} from 'react-redux';
import {Col, Image, Panel} from 'react-bootstrap';
import logo from '../../assets/images/logo.png';

const RegisterComplete = () => (
    <div>
        <Col md={4} mdOffset={4}>
            <div className="text-center">
                <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                <h1>Proposal System</h1>
            </div>

            <Panel header={<h3>Registration Complete</h3>} className="login-panel">
                <div>Please check your email inbox for a verification email</div>
            </Panel>
        </Col>
    </div>
);

export default connect()(RegisterComplete);
