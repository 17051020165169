import React, {Component} from 'react';

import {FORM_TYPE_CHOICES} from '../../utils/constants';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Button, Modal, PageHeader, Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';
import {defer, navigate} from '../../utils/generalUtils';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
    requestAllFormNames, requestAllForms, requestFormDelete, requestFormNameDelete,
    requestFormNameSave
} from '../../stores/forms/store';
import PermissionDenied from '../PermissionDenied';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapInput, BootstrapSelect} from '../../utils/BootstrapInputs';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';

/* eslint react/no-string-refs: 0 */

class ListForms extends Component {
    constructor() {
        super();

        this.state = {
            showFormNameDialog: false,
            formName: {
                name: '',
                display_name: '',
                id: null,
                type: 0
            },
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestAllForms(() =>
            this.props.requestAllFormNames(() =>
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            )
        );
    }


    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading)
            return (<PageLoading/>);

        // Prevent users that are not admins from managaging forms
        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        const typeOptions = FORM_TYPE_CHOICES.select(d =>
            (<option value={d.key} key={d.key}>{d.value}</option>)
        ).toArray();

        const formData = this.props.forms.select(f => {
            const name = this.props.formNames.firstOrDefault(c => c.id === f.name);
            return {
                ...f,
                name_str: name.name,
                display_str: name.display_name,
                type_str: FORM_TYPE_CHOICES.firstOrDefault(k => k.key === name.type.toString(), null).value
            };
        });

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Form Builder',
                            url: '/forms'
                        }
                    ]
                }/>

                <Tabs id="form-builder">
                    <Tab eventKey={0} title="Forms">
                        <PageHeader>
                            Forms
                            <Button className="pull-right" onClick={() => navigate(this, '/forms/new')}>
                                Add Form
                            </Button>
                        </PageHeader>

                        <BootstrapTable data={formData.toArray()} striped search pagination hover>
                            <TableHeaderColumn dataField="name_str">Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="display_str">Display Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="version" isKey={true}>Version</TableHeaderColumn>
                            <TableHeaderColumn dataField="type_str">Form Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="edit" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => (
                                    <Link to={`/forms/${row.id}/`}>
                                            Edit
                                    </Link>
                                )
                            }/>
                            <TableHeaderColumn dataField="delete" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => !row.isUsed ?
                                    (
                                        <Button bsStyle="default" className="pull-right btn-danger"
                                            onClick={() => {
                                                this.props.requestFormDelete(row.id,
                                                    () => defer(() => {
                                                        this.props.requestAllForms();
                                                        this.props.requestAllFormNames();

                                                        toast.success('Form deleted.');
                                                    })
                                                );
                                            }}>
                                                Delete
                                        </Button>
                                    ) : 'In use'
                            }/>
                        </BootstrapTable>
                    </Tab>
                    <Tab eventKey={1} title="Form Names">
                        <PageHeader>
                            Form Names
                            <Button className="pull-right" onClick={
                                () =>
                                    this.setState(
                                        {
                                            ...this.state,
                                            showFormNameDialog: true,
                                            formName: {
                                                name: '',
                                                display_name: '',
                                                id: null,
                                                type: 0
                                            }
                                        }
                                    )
                            }>
                                Add Form Name
                            </Button>
                        </PageHeader>

                        <BootstrapTable data={this.props.formNames.toArray()} striped search pagination hover>
                            <TableHeaderColumn dataField="name" isKey={true}>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="display_name">Display Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="type" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                // eslint-disable-next-line no-unused-vars
                                (cell, row) => {
                                    const f = FORM_TYPE_CHOICES.firstOrDefault(k => k.key === cell.toString(), null);
                                    if (!f)
                                        return 'Unknown Form Type';
                                    return f.value;
                                }
                            }>Form Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="edit" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => (
                                    <a href onClick={e => {
                                        this.setState(
                                            {
                                                ...this.state,
                                                showFormNameDialog: true,
                                                formName: row
                                            }
                                        );
                                        e.preventDefault();
                                    }}>
                                            Edit
                                    </a>
                                )
                            }/>
                            <TableHeaderColumn dataField="delete" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => !row.isUsed ?
                                    (
                                        <Button bsStyle="default" className="pull-right btn-danger"
                                            onClick={() => {
                                                this.props.requestFormNameDelete(row.id,
                                                    () => defer(() => {
                                                        this.props.requestAllForms();
                                                        this.props.requestAllFormNames();

                                                        toast.success('Form name deleted.');
                                                    })
                                                );
                                            }}>
                                                Delete
                                        </Button>
                                    ) : 'In use'
                            }/>
                        </BootstrapTable>
                    </Tab>
                </Tabs>
                {
                    this.state.showFormNameDialog ? (
                        <Modal.Dialog bsSize="large">
                            <Modal.Header>
                                <Modal.Title>{this.state.formName.id === null ? 'Add' : 'Update'} Form
                                    Name</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <LocalForm
                                    initialState={this.state.formName}
                                    onChange={
                                        (data) => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    formName: {
                                                        name: data.name,
                                                        display_name: data.display_name,
                                                        id: this.state.formName.id,
                                                        type: parseInt(data.type, 10)
                                                    }
                                                }
                                            );
                                        }
                                    }
                                    onSubmit={
                                        // eslint-disable-next-line no-unused-vars
                                        (data) => {
                                            this.props.requestFormNameSave(
                                                this.state.formName.id,
                                                this.state.formName.name,
                                                this.state.formName.display_name,
                                                this.state.formName.type,
                                                () => {
                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            showFormNameDialog: false
                                                        }
                                                    );

                                                    defer(() => {
                                                        this.props.requestAllForms();
                                                        this.props.requestAllFormNames();
                                                    });

                                                    toast.success('Form name saved.');
                                                }
                                            );
                                        }
                                    }>
                                    <Control
                                        label="Form Name"
                                        component={BootstrapInput}
                                        model=".name"
                                        required
                                        maxLength={200}
                                    />

                                    <Control
                                        label="Display Name"
                                        component={BootstrapInput}
                                        model=".display_name"
                                        required
                                        maxLength={200}
                                    />

                                    <Control
                                        label="Form Type" type="select"
                                        component={BootstrapSelect}
                                        options={typeOptions}
                                        model=".type"
                                        required
                                        disabled={!!this.state.formName.id}
                                    />

                                    <button ref="submit_form" type="submit" style={{'display': 'none'}}/>
                                </LocalForm>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => this.refs.submit_form.click()}>Save</Button>
                                <Button onClick={() => this.setState({
                                    ...this.state,
                                    showFormNameDialog: false
                                })}>Close</Button>
                            </Modal.Footer>

                        </Modal.Dialog>
                    ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({forms, auth}) => 
    // Set the state accordingly
    ({
        forms: forms.all,
        formNames: forms.names,
        localUser: auth.localUser
    })
;


export default connect(
    mapStateToProps,
    {
        requestAllForms,
        requestAllFormNames,
        requestFormDelete,
        requestFormNameSave,
        requestFormNameDelete
    }
)(ListForms);
