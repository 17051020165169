import React from 'react';
import Enumerable from 'linq';

const ServerErrors = ({errors}) => {
    let rendered;
    if (errors)
        rendered = Enumerable.from(errors).select(
            (error, id) => (<ul key={id} className="list-unstyled text-danger">{
                typeof error.value === Array ?
                    Enumerable.from(error.value).select(
                        (e, index) => (
                            <li key={index}>
                                <b>
                                    {e}
                                </b>
                            </li>
                        )
                    ).toArray() :
                    (
                        <li>
                            <b>
                                {error.value}
                            </b>
                        </li>
                    )
            }</ul>)
        ).toArray();
    else
        rendered = null;

    return (
        <div>
            {rendered}
        </div>
    );
};

export default ServerErrors;
