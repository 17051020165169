import React, {Component} from 'react';
import JsonEditor from '../../components/JsonEditor';
import {Button, Col, PageHeader, Panel, PanelGroup, Row} from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import {requestAllFormNames, requestForm, requestFormSave} from '../../stores/forms/store';
import {connect} from 'react-redux';
import {Control, LocalForm} from 'react-redux-form';
import Breadcrumbs from '../../components/Breadcrumbs';
import {BootstrapInput, BootstrapSelect} from '../../utils/BootstrapInputs';
import Enumerable from 'linq';
import {toast} from 'react-toastify';
import {
    FORM_TYPE_OPTIONS,
    FORM_TYPE_PROPOSAL_RESOURCE,
    FORM_TYPE_PROPOSAL_RESOURCE_MULTI,
    FORM_TYPE_REVIEW_SCORE
} from '../../utils/constants';
import PageLoading from '../../components/PageLoading';
import {customWidgets} from '../../components/JsonForm';
import {ErrorBoundary} from 'react-error-boundary';

/* eslint react/no-string-refs: 0 */

function FormSchemaErrorFallback({error}) {
    return (
        <div>
            <p>There is an error in the schema</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const scoreOptionsSchema = {
    type: 'object',
    properties: {
        method: {
            type: 'string',
            enum: [
                'average',
                'total'
            ]
        },
        fields: {
            type: 'array',
            items: {
                type: 'string'
            }
        }
    },
    additionalProperties: false,
    required: [
        'method',
        'fields'
    ]
};

const resourceOptionsSchema = {
    type: 'object',
    properties: {
        institution_field: {
            type: ['string', 'null'],

        },
        data_fields: {
            type: 'array',
            items: {
                type: 'string'
            }
        }
    },
    additionalProperties: false,
    required: [
        'institution_field',
        'data_fields'
    ]
};

const defaultScoreOptions = {
    method: 'average',
    fields: []
};

const defaultResourceOptions = {
    institution_field: null,
    data_fields: []
};

class EditForm extends Component {
    constructor() {
        super();

        this.state = {
            formSchema: {},
            uiSchema: {},
            name: null,
            version: '',
            isNew: true,
            loading: true,
            formId: null,
            hasError: false,
            options: {}
        };
    }

    componentWillMount() {
        // Get all the form names from the server
        this.props.requestAllFormNames(
            () => {
                // Check if we need to fetch form data from the server
                if (this.props.match.params.formId) {
                    // Yes, ask the server for the form data
                    this.props.requestForm(this.props.match.params.formId,
                        (form) => {
                            // Update the form data from the response
                            this.setState(
                                {
                                    ...this.state,
                                    formSchema: form.jsonSchema,
                                    uiSchema: form.uiSchema,
                                    name: this.props.formNames.first(e => e.id === parseInt(form.name, 10)),
                                    version: form.version,
                                    isNew: false,
                                    loading: false,
                                    formId: form.id,
                                    options: form.options
                                }
                            );
                        }
                    );
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        );
    }

    render() {
        if (
            !this.props.localUser.isValid() ||
            (!this.state.isNew && !this.state.name) ||
            this.state.loading
        ) {
            return (<PageLoading/>);
        }

        let options = Enumerable.from([(<option key={0} value="">---</option>)])
            .concat(
                this.props.formNames.select((c) => (<option value={c.id} key={c.id}>{c.name}</option>))
            );

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Forms',
                            url: '/forms'
                        },
                        {
                            name: this.state.isNew ? 'New' : this.state.name.name + ' - ' + this.state.version,
                            url: ''
                        }
                    ]
                }/>
                <PageHeader>
                    {this.state.isNew ? 'New Form' : this.state.name.name + ' - ' + this.state.version}
                    <div className="pull-right">
                        <Button onClick={
                            () => this.refs.submit_form.click()
                        }>Save</Button>
                    </div>
                </PageHeader>
                <Row>
                    <LocalForm
                        ref="form_details"
                        initialState={{
                            name: this.state.name ? this.state.name.id : '',
                            version: this.state.version
                        }}
                        onChange={
                            (data) => {
                                let options = this.state.options;
                                let name;
                                if (data.name)
                                    name = this.props.formNames.first(e => e.id === parseInt(data.name, 10));

                                if (name && (!this.state.name || name.id !== this.state.name.id)) {
                                    if (name.type === FORM_TYPE_REVIEW_SCORE)
                                        options = defaultScoreOptions;
                                    else if (
                                        name.type === FORM_TYPE_PROPOSAL_RESOURCE
                                        || name.type === FORM_TYPE_PROPOSAL_RESOURCE_MULTI
                                    )
                                        options = defaultResourceOptions;
                                }

                                this.setState(
                                    {
                                        ...this.state,
                                        name: name,
                                        version: data.version,
                                        options: options
                                    }
                                );
                            }
                        }
                        onSubmit={
                            () => {
                                if (this.state.hasError) {
                                    toast.error('Form not saved, please check the schema for errors');
                                } else {
                                    this.props.requestFormSave(
                                        {
                                            formId: this.state.formId,
                                            isNew: this.state.isNew,
                                            jsonSchema: this.state.formSchema,
                                            uiSchema: this.state.uiSchema,
                                            name: this.state.name.id,
                                            version: this.state.version,
                                            options: this.state.options
                                        },
                                        (response) => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    isNew: false,
                                                    formId: response.formId
                                                }
                                            );

                                            toast.success('Form saved.');
                                        }
                                    );
                                }
                            }
                        }>
                        <Control
                            label="Form Name" type="select"
                            component={BootstrapSelect}
                            options={options.toArray()}
                            model=".name"
                            required
                            disabled={!this.state.isNew}
                        />

                        <Control
                            label="Form Version"
                            component={BootstrapInput}
                            model=".version"
                            required
                            maxLength={20}
                        />

                        <button ref="submit_form" type="submit" style={{'display': 'none'}}/>
                    </LocalForm>
                </Row>
                {this.state.name && FORM_TYPE_OPTIONS.contains(this.state.name.type) ? (
                    <Row>
                        <PanelGroup>
                            <Panel
                                collapsible
                                header="Form Options"
                            >
                                <Col md={6}>
                                    <JsonEditor
                                        onChange={
                                            schema => {
                                                this.setState(
                                                    {
                                                        ...this.state,
                                                        options: schema,
                                                        hasError: false
                                                    }
                                                );
                                            }
                                        }
                                        config={{
                                            mode: 'code'
                                        }}
                                        schema={
                                            this.state.name.type === FORM_TYPE_REVIEW_SCORE ?
                                                scoreOptionsSchema : resourceOptionsSchema
                                        }
                                        json={this.state.options}
                                        style={{'height': '400px'}}
                                        onError={
                                            () => {
                                                this.setState({
                                                    ...this.state,
                                                    hasError: true
                                                });
                                            }
                                        }
                                    />
                                </Col>
                                <Col md={6}>
                                    <JsonEditor
                                        onChange={
                                            schema => {
                                                this.setState(
                                                    {
                                                        ...this.state,
                                                        options: schema,
                                                        hasError: false
                                                    }
                                                );
                                            }
                                        }
                                        config={{
                                            mode: 'tree',
                                        }}
                                        schema={
                                            this.state.name.type === FORM_TYPE_REVIEW_SCORE ?
                                                scoreOptionsSchema : resourceOptionsSchema
                                        }
                                        json={this.state.options}
                                        style={{'height': '400px'}}
                                        onError={
                                            () => {
                                                this.setState({
                                                    ...this.state,
                                                    hasError: true
                                                });
                                            }
                                        }
                                    />
                                </Col>
                            </Panel>
                        </PanelGroup>
                    </Row>
                ) : null}
                <Row>
                    <Col md={6}>
                        <Row>
                            <h3>Form Schema</h3>
                            <Col md={6}>
                                <JsonEditor
                                    onChange={
                                        schema => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    formSchema: schema,
                                                    hasError: false
                                                }
                                            );
                                        }
                                    }
                                    config={{
                                        mode: 'code'
                                    }}
                                    json={this.state.formSchema}
                                    style={{'height': '400px'}}
                                    onError={
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                hasError: true
                                            });
                                        }
                                    }
                                />
                            </Col>
                            <Col md={6}>
                                <JsonEditor
                                    onChange={
                                        schema => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    formSchema: schema,
                                                    hasError: false
                                                }
                                            );
                                        }
                                    }
                                    config={{
                                        mode: 'tree'
                                    }}
                                    json={this.state.formSchema}
                                    style={{'height': '400px'}}
                                    onError={
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                hasError: true
                                            });
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h3>UI Schema</h3>
                            <Col md={6}>
                                <JsonEditor
                                    onChange={
                                        schema => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    uiSchema: schema,
                                                    hasError: false
                                                }
                                            );
                                        }
                                    }
                                    json={this.state.uiSchema}
                                    config={{
                                        mode: 'code'
                                    }}
                                    style={{'height': '400px'}}
                                    onError={
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                hasError: true
                                            });
                                        }
                                    }
                                />
                            </Col>
                            <Col md={6}>
                                <JsonEditor
                                    onChange={
                                        schema => {
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    uiSchema: schema,
                                                    hasError: false
                                                }
                                            );
                                        }
                                    }
                                    json={this.state.uiSchema}
                                    config={{
                                        mode: 'tree'
                                    }}
                                    style={{'height': '400px'}}
                                    onError={
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                hasError: true
                                            });
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <h3>Form</h3>
                        <ErrorBoundary
                            FallbackComponent={FormSchemaErrorFallback}
                            resetKeys={[this.state.formSchema, this.state.uiSchema]}
                        >
                            <Form
                                initialData={{}}
                                schema={this.state.formSchema}
                                uiSchema={this.state.uiSchema}
                                widgets={customWidgets}
                            >
                                <button type="submit" style={{'display': 'none'}}/>
                            </Form>
                        </ErrorBoundary>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({forms, auth}) => ({
    formNames: forms.names,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestForm,
    requestAllFormNames,
    requestFormSave
})(EditForm);
