import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestAllFormNames, requestAllForms, requestReviewFormOrder} from '../../stores/forms/store';
import {requestSingleCall} from '../../stores/calls/store';
import {JsonForm} from '../../components/JsonForm';
import {Button, Tab, Tabs, PageHeader} from 'react-bootstrap';
import {requestReview} from '../../stores/reviews/store';
import {requestProposal} from '../../stores/proposals/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import PermissionDenied from '../PermissionDenied';
import {defer} from '../../utils/generalUtils';
import Enumerable from 'linq';
import {toast} from 'react-toastify';
import PageLoading from '../../components/PageLoading';
import CustomEvent from '../../utils/CustomEvent';

/* eslint no-unused-vars: 0 */

class Review extends Component {
    constructor() {
        super();

        this.state = {
            isNew: true,
            reviewId: null,
            formData: {},
            saved: false,
            saving: false,
            waitingSubmits: Enumerable.empty(),
            error: false,
            activeTab: 0,
            saveCount: 1,
            isLoading: true,
            proposalTypeId: null
        };
    }

    componentWillMount() {
        // Ask the server for the current call and the list of forms in order for the review
        this.props.requestSingleCall(this.props.match.params.callId, () =>
            this.props.requestProposal(this.props.match.params.proposalId, proposal =>
                this.props.requestAllForms(() =>
                    this.props.requestAllFormNames(() =>
                        this.props.requestReviewFormOrder(this.props.match.params.callId, () =>
                            // Ask the server for the review data, if any
                            this.props.requestReview(
                                this.props.match.params.proposalId,
                                this.props.match.params.reviewId || 0,
                                (data) => {
                                    // Update the form data from the review data
                                    this.setState(
                                        {
                                            ...this.state,
                                            isNew: false,
                                            formData: data,
                                            isLoading: false,
                                            proposalTypeId: proposal.proposalTypeId
                                        }
                                    );
                                },
                                () => {
                                    this.setState({
                                        ...this.state,
                                        isLoading: false,
                                        proposalTypeId: proposal.proposalTypeId
                                    });
                                }
                            )
                        )
                    )
                )
            )
        );

    }

    getFormName(id) {
        const form = this.props.forms.firstOrDefault((f) => f.id === id);
        return this.props.formNames.firstOrDefault((n) => n.id === form.name).display_name;
    }

    onFormError(id, errors) {
        this.setState({
            ...this.state,

            // Set an error state
            error: true,

            // Set the current tab to the tab with the error
            activeTab: this.props.reviewFormOrder.first(
                (e, i) => e.typeId === this.state.proposalTypeId
            ).forms.indexOf(id),

            // Stop saving
            saving: false,
            saved: false
        });

        toast.error('There was an error saving the review');
    }

    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isMember(this.props.call.committee))
            return (<PermissionDenied/>);

        this.submit_buttons = {};

        // Get the review form order
        let reviewFormOrder = this.props.reviewFormOrder.where((e, i) => e.typeId === this.state.proposalTypeId);
        if (reviewFormOrder.count())
            reviewFormOrder = Enumerable.from(reviewFormOrder.first().forms);

        const forms = reviewFormOrder.select(
            (id, index) => (
                <Tab title={this.getFormName(id)} eventKey={index} key={index}>
                    <JsonForm
                        id={id}
                        formKey={this.state.saveCount}
                        refSubmit={(button) => this.submit_buttons[index] = button}
                        postURL="/review_responses/"
                        extraData={
                            {
                                // The call we are saving a review response for
                                call: this.props.call.id,

                                // The proposal id this review is for
                                proposal: this.props.match.params.proposalId,
                            }
                        }
                        initialData={this.state.formData[id] || null}
                        onError={(errors) => this.onFormError(id, errors)}
                        onSubmit={() => {
                            const waitingSubmits =
                                this.state.waitingSubmits.count() ?
                                    this.state.waitingSubmits.where(r => r !== index) :
                                    Enumerable.range(0, reviewFormOrder.count()).where(i => i !== index);
                            this.setState({
                                ...this.state,
                                saving: true,
                                waitingSubmits: waitingSubmits
                            });
                        }}
                        onSuccess={
                            // Update the data and the review id
                            (data, response) => {
                                // Copy the form data from the state
                                const formData = {
                                    ...this.state.formData
                                };

                                // Update the data for this form in the form data
                                formData[id] = data;

                                // Copy author and authorId from server response
                                formData.authorId = response.authorId;
                                formData.author = response.author;

                                // Update the state
                                this.setState(
                                    {
                                        ...this.state,

                                        // Form is not new anymore
                                        isNew: false,

                                        // Update the form data
                                        formData: formData,

                                        saved: true,
                                        saving: this.state.waitingSubmits.any(),

                                        // Update the save counter if needed
                                        saveCount: this.state.waitingSubmits.any() ?
                                            this.state.saveCount : this.state.saveCount + 1,
                                    }
                                );

                                if (this.state.waitingSubmits.count()) {
                                    defer(() => {
                                        try {
                                            this.submit_buttons[this.state.waitingSubmits.first()]
                                                .onSubmit(new CustomEvent(null));
                                        } catch (Error) {
                                            toast.error('There was an error saving the review');
                                            this.setState({
                                                ...this.state,
                                                waitingSubmits: Enumerable.empty()
                                            });
                                        }
                                    });
                                } else {
                                    toast.success('Review Saved Successfully');
                                }
                            }
                        }
                        onChange={
                            // Update the form data
                            // This actually is handled because changes to other forms are lost
                            // if this one is submitted
                            (data) => {
                                // Copy the form data from the state
                                const formData = {
                                    ...this.state.formData
                                };

                                // Set the data for this form
                                formData[id] = data;

                                // Update the state
                                this.setState(
                                    {
                                        ...this.state,

                                        // Update the form data
                                        formData: formData
                                    }
                                );
                            }
                        }
                        buttons={(
                            <div>
                                <Button
                                    type="submit"
                                    disabled={!((
                                        this.state.isNew
                                        || this.state.formData.authorId === this.props.localUser.id
                                    ) && this.props.call.canSubmitReview())}>
                                    {this.state.saving ? (<div>Saving...
                                        <li className="fa fa-spin fa-spinner"/>
                                    </div>) : (this.state.saved ? 'Saved' : 'Save')}
                                </Button>
                            </div>
                        )}
                    />
                </Tab>
            )
        ).toArray();

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: this.props.call.name,
                            url: `/calls/${this.props.call.id}/`
                        },
                        {
                            name: 'Proposals',
                            url: `/calls/${this.props.call.id}/proposals/`
                        },
                        {
                            name: this.props.proposal.proposalName,
                            url: `/calls/${this.props.call.id}/proposals/${this.props.proposal.id}/`
                        },
                        {
                            name: 'Reviews',
                            url: `/calls/${this.props.call.id}/proposals/${this.props.proposal.id}/reviews/`
                        },
                        {
                            name: (
                                !this.props.call.closed
                                || this.props.localUser.isChair(this.props.call.committee)
                            ) ? (this.state.isNew ? 'New' : this.state.formData.author) : this.state.formData.id,
                            url: ''
                        }
                    ]
                }/>
                <PageHeader>Edit Review</PageHeader>
                <Tabs id="review-forms" activeKey={this.state.activeTab}
                    onSelect={(key, event) => this.setState({...this.state, activeTab: key})}>
                    {forms}
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = ({calls, forms, auth, proposals}, ownProps) => ({
    call: calls.currentCall,
    reviewFormOrder: forms.reviewFormOrder,
    forms: forms.all,
    formNames: forms.names,
    proposal: proposals.current,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestAllFormNames,
    requestReviewFormOrder,
    requestSingleCall,
    requestAllForms,
    requestReview,
    requestProposal
})(Review);
