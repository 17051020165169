import React, {Component} from 'react';
import Widget from './Widget';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {requestCalls} from '../../stores/calls/store';
import {requestCommittees} from '../../stores/committees/store';
import {requestAllProposals} from '../../stores/proposals/store';
import {requestAllReviews, requestPendingReviews} from '../../stores/reviews/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {formatDateTime} from '../../utils/generalUtils';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import SavePDF from '../../utils/SavePDF';

class Dashboard extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestCommittees(() =>
            this.props.requestCalls(() =>
                this.props.requestAllProposals(0, () =>
                    this.props.requestAllReviews(0, 0, () =>
                        this.props.requestPendingReviews(() =>
                            this.setState({
                                isLoading: false
                            }))
                    )
                )
            )
        );
    }

    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        const items = [
            {
                name: 'Dashboard',
                url: '/'
            }
        ];

        let proposals;
        try {
            proposals = this.props.proposals.select(p => {
                const call = this.props.calls.firstOrDefault(c => c.id === p.callId);
                const committee = this.props.committees.firstOrDefault(c => c.id === call.committee);
                return {
                    callId: call.id,
                    proposalId: p.id,
                    committee: committee.name,
                    call: call.name,
                    submission_deadline: call.termination,
                    state: p.state
                };
            }).toArray();
        } catch (e) {
            // If an error occurred, we're still waiting for data from the server
            return (
                <p>Loading...</p>
            );
        }

        const reviews = this.props.reviews.select(r => {
            const call = this.props.calls.firstOrDefault(c => c.id === r.callId);
            const committee = this.props.committees.firstOrDefault(c => c.id === call.committee);
            return {
                callId: call.id,
                reviewId: r.id,
                proposalId: r.proposalId,
                proposalAuthor: r.proposalAuthor,
                committee: committee.name,
                call: call.name,
                submission_deadline: call.reviewTermination,
            };
        }).toArray();

        return (
            <div>
                <Breadcrumbs items={items}/>
                <Row>
                    <Col md={3}>
                        <Widget type="panel-green" icon="fa fa-tasks fa-5x" linkTo="/calls"
                            count={this.props.calls.count(c => !c.closed).toString()}
                            headerText="Open Call for Proposals" footerText="View Details"/>
                    </Col>
                    {this.props.localUser.isMember() && this.props.pendingReviews.count() ? (
                        <Col md={3}>
                            <Widget type="panel-yellow" icon="fa fa-tasks fa-5x" linkTo="/pending_reviews/"
                                count={this.props.pendingReviews.count().toString()}
                                headerText="Open Pending Reviews" footerText="View Details"/>
                        </Col>
                    ) : null}
                </Row>
                <hr/>
                <Row>
                    <Col md={this.props.localUser.isMember() ? 6 : 12}>
                        <h3>My Proposals</h3>
                        <BootstrapTable data={proposals} striped search pagination hover>
                            <TableHeaderColumn dataField="committee">Committee</TableHeaderColumn>
                            <TableHeaderColumn dataField="call" isKey={true}>Call for Proposal Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="submission_deadline" dataFormat={formatDateTime}>Submission
                                Deadline</TableHeaderColumn>
                            <TableHeaderColumn dataField="state">State</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pdf"
                                key="pdf"
                                dataFormat={
                                    (cell, row) => (
                                        <SavePDF
                                            callId={row.callId}
                                            proposalId={row.proposalId}
                                        />)
                                }
                            >Download</TableHeaderColumn>
                            <TableHeaderColumn dataField="view_calls" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => (
                                    <Link to={`/calls/${row.callId}/proposals/${row.proposalId}/`}>
                                        {new Date() < row.submission_deadline ? 'Edit' : 'View'}
                                    </Link>
                                )
                            }/>
                        </BootstrapTable>
                    </Col>
                    {this.props.localUser.isMember() ? (
                        <Col md={6}>
                            <h3>My Reviews</h3>
                            <BootstrapTable data={reviews} striped search pagination hover>
                                <TableHeaderColumn dataField="committee">Committee</TableHeaderColumn>
                                <TableHeaderColumn dataField="call" isKey={true}>Call for Proposal
                                    Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="proposalAuthor">Proposal Author</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="submission_deadline"
                                    dataFormat={formatDateTime}>
                                    Submission Deadline
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="view_calls" dataFormat={
                                    // Creates an add button that adds the form on this row to the list of forms
                                    (cell, row) => (
                                        <Link
                                            to={`/calls/${row.callId}/proposals/${row.proposalId}/reviews/${row.reviewId}/`}>
                                            {new Date() < row.submission_deadline ? 'Edit' : 'View'}
                                        </Link>
                                    )
                                }/>
                            </BootstrapTable>
                        </Col>
                    ) : null}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({calls, auth, committees, proposals, reviews}) => 
    // Set the state accordingly
    ({
        calls: calls.all,
        localUser: auth.localUser,
        committees: committees,
        proposals: proposals.all,
        reviews: reviews.all,
        pendingReviews: reviews.pending
    })
;


export default connect(
    mapStateToProps,
    {
        requestCalls,
        requestCommittees,
        requestAllProposals,
        requestAllReviews,
        requestPendingReviews
    }
)(Dashboard);
