import React, {Component} from 'react';
import {Route, Switch,} from 'react-router-dom';


import {ListAllCalls, ListOpenCalls, ListClosedCalls} from '../pages/calls/ListCalls';
//import ListOpenCalls from '../pages/calls/ListOpenCalls'
import PrivateRoute from '../utils/PrivateRoute';
import Auth from '../pages/auth/Auth';
import EditCall from '../pages/calls/EditCall';
import ViewCall from '../pages/calls/ViewCall';
import EditProposal from '../pages/proposals/EditProposal';
import ListProposals from '../pages/proposals/ListProposals';
import EditReview from '../pages/reviews/EditReview';
import ListReviews from '../pages/reviews/ListReviews';
import ListUsers from '../pages/users/ListUsers';
import Dashboard from '../pages/Dashboard/Dashboard';
import EditUser from '../pages/users/EditUser';
import EditRole from '../pages/users/roles/EditRole';
import {routeChange} from '../stores/misc/store';
import {connect} from 'react-redux';
import ManageCall from '../pages/calls/ManageCall';
import PendingReviews from '../pages/reviews/PendingReviews';
import ListCommittees from '../pages/committees/ListCommittees';
import NewCommittee from '../pages/committees/NewCommittee';
import MyAccount from '../pages/users/MyAccount';
import ChangePassword from '../pages/auth/ChangePassword';
import EditForm from '../pages/forms/EditForm';
import ViewCommittee from '../pages/committees/ViewCommittee';
import ListForms from '../pages/forms/ListForms';
import {ToastContainer} from 'react-toastify';

class SidebarFix extends Component {
    // Used to render the content containing div, only if the user is logged in
    render() {
        if (this.props.loggedIn && this.props.user.registration_complete)
            return (
                <div id="page-wrapper" className="page-content">
                    {this.props.children}
                    <ToastContainer
                        position="top-right"
                        type="default"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                    />
                </div>
            );
        else
            return (
                <div>
                    {this.props.children}
                    <ToastContainer
                        position="top-right"
                        type="default"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                    />
                </div>
            );
    }
}

const mapStateToProps = ({auth}) => ({
    user: auth.localUser,
    loggedIn: auth.loggedIn
});

const WrappedSidebarFix = connect(mapStateToProps)(SidebarFix);

const Content = (props) => {
    const {history, store} = props;

    function onChange() {
        store.dispatch(routeChange());
    }

    history.listen(onChange);
    return (
        <WrappedSidebarFix>
            <br/>
            <Switch>
                {/* Auth */}
                <Route path="/auth" component={Auth}/>

                {/* Calls */}
                <PrivateRoute exact path="/calls" component={ListOpenCalls}/>
                <PrivateRoute exact strict path="/calls/all" component={ListAllCalls}/>
                <PrivateRoute exact strict path="/calls/closed" component={ListClosedCalls}/>
                <PrivateRoute exact strict path="/calls/new" component={EditCall}/>
                <PrivateRoute exact strict path="/calls/:callId/update" component={EditCall}/>
                <PrivateRoute exact strict path="/calls/:callId/manage" component={ManageCall}/>
                <PrivateRoute exact strict path="/calls/:callId/" component={ViewCall}/>

                {/* Proposals */}
                <PrivateRoute exact strict path="/calls/:callId/new" component={EditProposal}/>
                <PrivateRoute exact strict path="/calls/:callId/proposals/:proposalId/" component={EditProposal}/>
                <PrivateRoute exact strict path="/calls/:callId/proposals/" component={ListProposals}/>

                {/* Reviews */}
                <PrivateRoute exact strict path="/calls/:callId/proposals/:proposalId/review/" component={EditReview}/>
                <PrivateRoute exact strict path="/calls/:callId/proposals/:proposalId/reviews/:reviewId/"
                    component={EditReview}/>
                <PrivateRoute exact strict path="/calls/:callId/proposals/:proposalId/reviews/"
                    component={ListReviews}/>
                <PrivateRoute exact strict path="/pending_reviews/" component={PendingReviews}/>

                {/* Users */}
                <PrivateRoute exact path="/users" component={ListUsers}/>
                <PrivateRoute exact strict path="/users/:userId/" component={EditUser}/>
                <PrivateRoute exact strict path="/users/:userId/roles/:roleId/" component={EditRole}/>
                <PrivateRoute exact strict path="/users/:userId/roles/new" component={EditRole}/>

                {/* Committees */}
                <PrivateRoute exact path="/committees" component={ListCommittees}/>
                <PrivateRoute exact strict path="/committees/:committeeId/" component={ViewCommittee}/>
                <PrivateRoute exact strict path="/committees/new" component={NewCommittee}/>

                {/* Home */}
                <PrivateRoute exact path="/" component={Dashboard}/>

                {/* My Account */}
                <PrivateRoute exact path="/account/" component={MyAccount}/>
                <PrivateRoute exact path="/account/change-password/" component={ChangePassword}/>

                {/* Form Builder */}
                <PrivateRoute exact path="/forms/" component={ListForms}/>
                <PrivateRoute exact strict path="/forms/:formId/" component={EditForm}/>
                <PrivateRoute exact strict path="/forms/new" component={EditForm}/>

                {/* 404 Not Found*/}
                <Route render={() => <div>The page you are looking for can not be found.</div>}/>
            </Switch>
        </WrappedSidebarFix>
    );
};

export default Content;
