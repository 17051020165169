import React, {Component} from 'react';
import Form from 'react-jsonschema-form';
import {connect} from 'react-redux';

import JsonSchemaTinyMce from './JsonSchemaTinyMce';
import {requestForm, requestSubmit} from '../stores/forms/store';
import {defer} from '../utils/generalUtils';
import JsonSchemaTextArea from './JsonSchemaTextArea';
import PdfUpload from './PdfUpload';

// eslint-disable-next-line react/no-danger
const HTMLWidget = (props) => (<div dangerouslySetInnerHTML={{ __html: props.options.html || '' }} />);

const customWidgets = {
    tinymce: JsonSchemaTinyMce,
    textarea: JsonSchemaTextArea,
    pdf: PdfUpload,
    html: HTMLWidget,
};

class JsonFormImpl extends Component {
    handleSubmit = (data) => {
        let submissionData = {
            ...this.props.extraData,
            form: this.props.id
        };

        submissionData = {
            ...submissionData,
            ...(this.props.onSubmit(submissionData) || {}),
        };

        this.props.requestSubmit(
            submissionData,
            data.formData,
            this.props.postURL,
            (response) => this.props.onSuccess(data.formData, response)
        );
    };

    handleChange = (data) => {
        // Check if there is an on change handler set
        if (this.props.onChange)
        // Yep, fire the on change handler with the data
            this.props.onChange(data.formData);
    };

    handleError = (errors) => {
        // Check if there is an on change handler set
        if (this.props.onError)
        // Yep, fire the on change handler with the data
            this.props.onError(errors);
    };

    constructor(props) {
        super(props);
        this.state = {
            jsonSchema: {},
            initialData: {},
            uiSchema: {}
        };
    }

    componentWillMount() {
        defer(() => this.props.requestForm(this.props.id));
    }

    render() {
        // If we don't yet have the form details from the server, let the user know we are waiting
        if (!this.props.jsonSchema) {
            return <div>Loading...</div>;
        }

        // We have the form data from the server, render the form
        return (
            <Form schema={this.props.jsonSchema}
                uiSchema={this.props.uiSchema}
                formData={this.props.initialData}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onError={this.handleError}
                widgets={customWidgets}
                ref={this.props.refSubmit}
                key={this.props.formKey || null}
                formContext={this.props.formContext}
                noHtml5Validate={this.props.noValidate}
                noValidate={this.props.noValidate}
                disabled={this.props.disabled}
            >
                {this.props.buttons}
            </Form>
        );
    }
}

const mapStateToProps = ({forms}, ownProps) => {
    // Find the form we are rendering
    let form = forms.all
        .firstOrDefault(f => f.id === ownProps.id);

    // Set the initial data if there is any
    let initialData = ownProps.initialData || form.initialData;

    // Set the state accordingly
    return {
        // Copy the form properties in to the props
        ...form,
        // Set the initial data
        initialData: initialData,
        // This stops the form id overriding the props id
        id: ownProps.id,
    };
};

const JsonForm = connect(mapStateToProps, {requestForm, requestSubmit})(JsonFormImpl);

export {
    JsonForm,
    customWidgets
};
