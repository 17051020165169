import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import JsFileDownloader from 'js-file-downloader';
import localStorage from './localStorage';

class SavePDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    downloadPdf() {
        this.setState({
            ...this.state,
            isLoading: true
        },
        () => {
            new JsFileDownloader(
                {
                    url: `/api/v1/download_proposal_extract/${this.props.proposalId}/`,
                    headers: [
                        {name: 'Authorization', value: `Token ${localStorage.token}`}
                    ]
                }
            )
                .then(() => {
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    // eslint-disable-next-line no-alert
                    alert('There was an error generating or downloading the PDF');
                });
        });
    }

    render() {
        return React.cloneElement(
            this.props.component || (<Button/>),
            Object.assign({}, {
                onClick: () => !this.props.localUser.isValid() || this.state.isLoading ? () => {
                } : this.downloadPdf(),
                children: [!this.props.localUser.isValid() || this.state.isLoading ?
                    'Processing...' : (this.props.text || 'Save PDF')]
            }, this.props.extra || {}, {disabled: !this.props.localUser.isValid() || this.state.isLoading})
        );
    }
}

const mapStateToProps = ({auth}) => ({
    localUser: auth.localUser
});

export default connect(mapStateToProps, {})(SavePDF);
