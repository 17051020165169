import moment from 'moment';
import React from 'react';

function defer(fn) {
    // Defers execution of fn
    setTimeout(fn, 0);
}

function navigate(_this, path) {
    // Navigates the current page to the requested path
    // Uses a setTimeout to avoid mid-dispatch issues etc
    defer(() => _this.props.history.push(path));
}

function formatDateTime(date) {
    return moment(date).format('ddd MMM D YYYY h:mm a');
}

function formatDateTimeSimple(date) {
    return moment(date).format('DD/MM/YY HH:mm');
}

// Spot the variable name changed from stack overflow
// https://stackoverflow.com/a/21792507
function humanizeLowerCaseWithUnderscores(str) {
    let frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function createExcelFunction(cols, row, func) {
    // Creates an Excel function from a list of column numbers, a row number and a function name
    function colNumToExcel(col) {
        // Converts the column number to the Excel alphabetical format - i.e. col number 28 => AB
        // This function is a Javascript adaptation of the function found at:
        // https://www.geeksforgeeks.org/find-excel-column-name-given-number/
        const str = [];
        while (col > 0) {
            const rem = col % 26;
            if (rem == 0) {
                str.push('Z');
                col = Math.floor(col / 26) - 1;
            } else {
                str.push(String.fromCharCode(rem - 1 + 'A'.charCodeAt(0)));
                col = Math.floor(col / 26);
            }
        }
        const col_str = str.reverse().join('');
        return col_str;
    }
    const str_row = row.toString();
    const str_cells = [];
    for (let i = 0; i < cols.length; i++) {
        str_cells.push(colNumToExcel(cols[i]) + str_row);
    }

    const cells = str_cells.join(',');

    return `=${func}(${cells})`;
}

function exportToCsv(headers, rows) {
    const processRow = function (row) {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }

            let result = innerValue.replace(/"/g, '""');
            if (result.search(/([",\n])/g) >= 0) {
                result = '"' + result + '"';
            }
            if (j > 0) {
                finalVal += ',';
                finalVal += result;
            } else {
                finalVal += result;
            }
        }
        return finalVal + '\n';
    };
    let csvFile = '';
    if (headers && headers.length)
        csvFile += processRow(headers);

    for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    return csvFile;
}

function ListFromArray(props) {
    return (
        <ul className="list-unstyled">
            {props.items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                    {item}
                </li>
            ))}
        </ul>
    );
}

export {
    navigate,
    defer,
    formatDateTime,
    formatDateTimeSimple,
    humanizeLowerCaseWithUnderscores,
    exportToCsv,
    createExcelFunction,
    ListFromArray
};