import React from 'react';
import {Button} from 'react-bootstrap';
import { processScores, processComments, processResources, processAll } from './ExcelDataProcessors';

const ProposalTypeDownloader = ({proposals, callName, type}) => {
    const typeProposals = proposals.where(p => p.proposalTypeId === type.id);
    return (
        <div>
            <h4>{type.name}</h4>
            <Button className='increased-margin' onClick={() => processScores(typeProposals, callName)}>
          Download Score Summary
            </Button>
            <Button className='increased-margin' onClick={() => processComments(typeProposals, callName)}>
          Download Comments 
            </Button>
            <Button className='increased-margin' onClick={() => processResources(typeProposals, callName)}>
          Download Resources 
            </Button>
            <Button className='increased-margin' onClick={() => processAll(typeProposals, callName)}>
          Download All 
            </Button>
        </div>
    );
};

const ProposalDownloader = ({proposals, callName }) => {
    // We never want to include draft proposals in the downloaded file. 
    const processedProposals = proposals.where(p => p.state !== 'Draft' || p.isReviewed);
    const proposalTypes = processedProposals 
        .select(p => ({id: p.proposalTypeId, name: p.proposalTypeName}))
        .distinct(p => p.id);

    return (
        <div>
            {proposalTypes.select(type => 
                <ProposalTypeDownloader proposals={processedProposals} callName={callName} type={type} key={type.id} />
            )}
        </div>
    );
};

export default ProposalDownloader;
