import {call, put, takeEvery} from 'redux-saga/effects';
import {del, get, post} from '../../utils/api';
import Enumerable from 'linq';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const COMMITTEES_REQUEST = 'astacta/committees/request';
const COMMITTEES_SUCCESS = 'astacta/committees/success';

const CREATE_COMMITTEE_REQUEST = 'astacta/committees/new/request';
const CREATE_COMMITTEE_SUCCESS = 'astacta/committees/new/success';

const DELETE_COMMITTEE_REQUEST = 'astacta/committees/delete/request';
const DELETE_COMMITTEE_SUCCESS = 'astacta/committees/delete/success';

// Action creators
const requestCommittees = (cb) => ({
    type: COMMITTEES_REQUEST,
    cb: cb
});

const receiveCommittees = (committees, cb) => ({
    type: COMMITTEES_SUCCESS,
    committees: committees,
    cb: cb
});

const requestCreateCommittee = (name, cb) => ({
    name: name,
    cb: cb,
    type: CREATE_COMMITTEE_REQUEST
});

const receiveCreateCommittee = (response, cb) => ({
    response: response,
    cb: cb,
    type: CREATE_COMMITTEE_SUCCESS,
});

const requestDeleteCommittee = (id, cb) => ({
    id: id,
    cb: cb,
    type: DELETE_COMMITTEE_REQUEST
});

const receiveDeleteCommittee = (response, cb) => ({
    response: response,
    cb: cb,
    type: DELETE_COMMITTEE_SUCCESS,
});

// Sagas
/***
 * Fetches the list of committees from the server server
 * @returns
 */
function* fetchCommittees({cb}) {
    try {
        const response = yield call(get, '/committees/');
        yield put(receiveCommittees(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches the list of committees from the server server
 * @returns
 */
function* deleteCommittee({id, cb}) {
    try {
        const response = yield call(del, '/committees/', {id: id});
        yield put(receiveDeleteCommittee(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches the list of committees from the server server
 * @returns
 */
function* createCommittee({name, cb}) {
    try {
        const response = yield call(post, '/committees/', {name: name});
        yield put(receiveCreateCommittee(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(COMMITTEES_REQUEST, fetchCommittees);
}

function* watchDelete() {
    yield takeEvery(DELETE_COMMITTEE_REQUEST, deleteCommittee);
}

function* watchCreate() {
    yield takeEvery(CREATE_COMMITTEE_REQUEST, createCommittee);
}

const sagas = [
    watchFetch,
    watchCreate,
    watchDelete
];

// Reducers
const path = 'committees';
const reducer = (state = Enumerable.empty(), action = {}) => {
    switch (action.type) {
    case COMMITTEES_SUCCESS:
        state = Enumerable.from(action.committees);

        if (action.cb)
            defer(() => action.cb(state));

        break;
    case CREATE_COMMITTEE_SUCCESS:
    case DELETE_COMMITTEE_SUCCESS:
        if (action.cb)
            defer(() => action.cb());
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestCommittees,
    requestCreateCommittee,
    requestDeleteCommittee
};

