import {call, put, takeEvery} from 'redux-saga/effects';
import {del, get, post} from '../../utils/api';
import Enumerable from 'linq';
import {Proposal} from './Proposal';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const PROPOSAL_REQUEST = 'astacta/proposals/request';
const PROPOSAL_SUCCESS = 'astacta/proposals/success';

const ALL_PROPOSALS_REQUEST = 'astacta/allproposals/request';
const ALL_PROPOSALS_SUCCESS = 'astacta/allproposals/success';

const ADD_COLLABORATOR_REQUEST = 'astacta/collaborators/add/request';
const ADD_COLLABORATOR_SUCCESS = 'astacta/collaborators/add/success';

const REMOVE_COLLABORATOR_REQUEST = 'astacta/collaborators/remove/request';
const REMOVE_COLLABORATOR_SUCCESS = 'astacta/collaborators/remove/success';

const SET_PROPOSAL_STATE_REQUEST = 'astacta/state/set/request';
const SET_PROPOSAL_STATE_SUCCESS = 'astacta/state/set/success';

const ADD_REVIEWER_REQUEST = 'astacta/reviewers/add/request';
const ADD_REVIEWER_SUCCESS = 'astacta/reviewers/add/success';

const REMOVE_REVIEWER_REQUEST = 'astacta/reviewers/remove/request';
const REMOVE_REVIEWER_SUCCESS = 'astacta/reviewers/remove/success';

const LOCK_STATUS_REQUEST = 'astacta/proposals/lock/request';
const LOCK_STATUS_SUCCESS = 'astacta/proposals/lock/success';

const RELEASE_LOCK_REQUEST = 'astacta/proposals/release/lock/request';
const RELEASE_LOCK_SUCCESS = 'astacta/proposals/release/lock/success';

const PROPOSAL_UPDATE_DECISION_SUMMARY_REQUEST = 'astacta/update_decision_summary/request';
const PROPOSAL_UPDATE_DECISION_SUMMARY_SUCCESS = 'astacta/update_decision_summary/success';

const PROPOSAL_UPDATE_NAME_REQUEST = 'astacta/update_proposal_name/request';
const PROPOSAL_UPDATE_NAME_SUCCESS = 'astacta/update_proposal_name/success';

const PROPOSAL_DELETE_UPLOAD_REQUEST = 'astacta/delete_proposal_upload/request';
const PROPOSAL_DELETE_UPLOAD_SUCCESS = 'astacta/delete_proposal_upload/success';

// Action creators
const requestProposal = (id, cb) => ({
    type: PROPOSAL_REQUEST,
    id,
    cb
});

const receiveProposal = (proposal, cb) => ({
    result: proposal,
    cb: cb,
    type: PROPOSAL_SUCCESS,
});

const requestAllProposals = (callId, cb) => ({
    type: ALL_PROPOSALS_REQUEST,
    callId: callId,
    cb: cb
});

const receiveAllProposals = (allProposals, cb) => ({
    type: ALL_PROPOSALS_SUCCESS,
    allProposals: allProposals,
    cb: cb
});

const addCollaborator = (proposalId, hash, cb) => ({
    proposalId: proposalId,
    hash: hash,
    cb: cb,
    type: ADD_COLLABORATOR_REQUEST
});

const receiveAddCollaborator = (id, name, email, cb) => ({
    type: ADD_COLLABORATOR_SUCCESS,
    cb: cb,
    id: id,
    name: name,
    email: email
});

const removeCollaborator = (proposalId, userId, cb) => ({
    proposalId: proposalId,
    userId: userId,
    cb: cb,
    type: REMOVE_COLLABORATOR_REQUEST
});

const receiveRemoveCollaborator = (cb) => ({
    cb: cb,
    type: REMOVE_COLLABORATOR_SUCCESS
});

const setProposalState = (proposalId, state, cb) => ({
    proposalId: proposalId,
    state: state,
    cb: cb,
    type: SET_PROPOSAL_STATE_REQUEST
});

const receiveSetProposalState = (cb) => ({
    cb: cb,
    type: SET_PROPOSAL_STATE_SUCCESS
});

const addReviewer = (proposalId, userId, cb) => ({
    proposalId: proposalId,
    userId: userId,
    cb: cb,
    type: ADD_REVIEWER_REQUEST
});

const receiveAddReviewer = (cb) => ({
    cb: cb,
    type: ADD_REVIEWER_SUCCESS
});

const removeReviewer = (proposalId, userId, cb) => ({
    proposalId: proposalId,
    userId: userId,
    cb: cb,
    type: REMOVE_REVIEWER_REQUEST
});

const receiveRemoveReviewer = (cb) => ({
    cb: cb,
    type: REMOVE_REVIEWER_SUCCESS
});

const checkLockStatus = (proposalId, cb) => ({
    proposalId: proposalId,
    cb: cb,
    type: LOCK_STATUS_REQUEST
});

const receiveCheckLockStatus = (response, cb) => ({
    response: response,
    cb: cb,
    type: LOCK_STATUS_SUCCESS
});

const releaseLockStatus = (proposalId) => ({
    proposalId: proposalId,
    type: RELEASE_LOCK_REQUEST
});

const receiveReleaseLockStatus = () => ({
    type: RELEASE_LOCK_SUCCESS
});

const requestUpdateProposalDecisionSummary = (proposalId, summary, onSuccess) => ({
    proposalId: proposalId,
    summary: summary,
    onSuccess: onSuccess,
    type: PROPOSAL_UPDATE_DECISION_SUMMARY_REQUEST
});

const receiveUpdateProposalDecisionSummary = (onSuccess) => ({
    cb: onSuccess,
    type: PROPOSAL_UPDATE_DECISION_SUMMARY_SUCCESS
});

const requestUpdateProposalName = (callId, isNew, proposalId, name, type, onSuccess) => ({
    callId: callId,
    isNew: isNew,
    proposalId: proposalId,
    name: name,
    ptype: type,
    onSuccess: onSuccess,
    type: PROPOSAL_UPDATE_NAME_REQUEST
});

const receiveUpdateProposalName = (response, onSuccess) => ({
    response: response,
    cb: onSuccess,
    type: PROPOSAL_UPDATE_NAME_SUCCESS
});

const deleteProposalUpload = (proposalId, onSuccess) => ({
    proposalId: proposalId,
    cb: onSuccess,
    type: PROPOSAL_DELETE_UPLOAD_REQUEST
});

const receiveDeleteProposalUpload = (onSuccess) => ({
    cb: onSuccess,
    type: PROPOSAL_DELETE_UPLOAD_SUCCESS
});

// Sagas
/***
 * Fetches a form definition from the server
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* fetchProposal({id, cb}) {
    try {
        const response = yield call(get, `/proposals/${id}/`);

        yield put(receiveProposal(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches all proposals from the server
 * @returns
 */
function* fetchAllProposals({callId, cb}) {
    try {
        const response = yield call(get, `/calls/${callId}/proposals/`);

        yield put(receiveAllProposals(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Adds a collaborator to a proposal
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* requestAddCollaborator({proposalId, hash, cb}) {
    try {
        const response = yield call(post, '/collaborators/', {proposalId, hash});

        yield put(receiveAddCollaborator(response.id, response.name, response.email, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Removes a collaborator from a proposal
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* requestRemoveCollaborator({proposalId, userId, cb}) {
    try {
        yield call(del, '/collaborators/', {proposalId, userId});

        yield put(receiveRemoveCollaborator(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Sets the state of a proposal
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* requestSetProposalState({proposalId, state, cb}) {
    try {
        yield call(post, '/proposal_state/', {proposalId, state});

        yield put(receiveSetProposalState(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Adds a reviewer to a proposal
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* requestAddReviewer({proposalId, userId, cb}) {
    try {
        yield call(post, '/reviewers/', {proposalId, userId});

        yield put(receiveAddReviewer(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Removes a reviewer from a proposal
 * @param id: The id of the form to fetch from the server
 * @param cb: Callback function to trigger on success
 * @returns
 */
function* requestRemoveReviewer({proposalId, userId, cb}) {
    try {
        yield call(del, '/reviewers/', {proposalId, userId});

        yield put(receiveRemoveReviewer(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Tries to acquire a proposal lock from the server
 * @returns
 */
function* requestCheckLockStatus({proposalId, cb}) {
    try {
        const response = yield call(get, `/lock/${proposalId}/`);

        yield put(receiveCheckLockStatus(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Tries to release a proposal lock
 * @returns
 */
function* requestReleaseLockStatus({proposalId}) {
    try {
        const response = yield call(del, `/lock/${proposalId}/`);

        yield put(receiveReleaseLockStatus(response));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Updates a proposal decision summary
 * @returns
 */
function* updateSummary({proposalId, summary, onSuccess}) {
    try {
        yield call(post, '/update_decision_summary/', {proposalId: proposalId, summary: summary});
        yield put(receiveUpdateProposalDecisionSummary(onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Updates a proposal name
 * @returns
 */
function* updateName({callId, isNew, proposalId, name, ptype, onSuccess}) {
    try {
        const response = yield call(post, '/update_proposal_name/',
            {callId: callId, isNew: isNew, proposalId: proposalId, name: name, type: ptype}
        );
        yield put(receiveUpdateProposalName(response, onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Tries to release a proposal lock
 * @returns
 */
function* requestProposalDeleteUpload({proposalId, cb}) {
    try {
        yield call(del, '/proposal_file/', {token: localStorage.token, 'proposal': proposalId});
        yield put(receiveDeleteProposalUpload(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(PROPOSAL_REQUEST, fetchProposal);
}

function* watchAllProposal() {
    yield takeEvery(ALL_PROPOSALS_REQUEST, fetchAllProposals);
}

function* watchAddCollaborator() {
    yield takeEvery(ADD_COLLABORATOR_REQUEST, requestAddCollaborator);
}

function* watchRemoveCollaborator() {
    yield takeEvery(REMOVE_COLLABORATOR_REQUEST, requestRemoveCollaborator);
}

function* watchRequestSetProposalState() {
    yield takeEvery(SET_PROPOSAL_STATE_REQUEST, requestSetProposalState);
}

function* watchAddReviewer() {
    yield takeEvery(ADD_REVIEWER_REQUEST, requestAddReviewer);
}

function* watchRemoveReviewer() {
    yield takeEvery(REMOVE_REVIEWER_REQUEST, requestRemoveReviewer);
}

function* watchCheckLockStatus() {
    yield takeEvery(LOCK_STATUS_REQUEST, requestCheckLockStatus);
}

function* watchReleaseLockStatus() {
    yield takeEvery(RELEASE_LOCK_REQUEST, requestReleaseLockStatus);
}

function* watchUpdateSummary() {
    yield takeEvery(PROPOSAL_UPDATE_DECISION_SUMMARY_REQUEST, updateSummary);
}

function* watchUpdateName() {
    yield takeEvery(PROPOSAL_UPDATE_NAME_REQUEST, updateName);
}

function* watchDeleteProposalUpload() {
    yield takeEvery(PROPOSAL_DELETE_UPLOAD_REQUEST, requestProposalDeleteUpload);
}

const sagas = [
    watchFetch,
    watchAllProposal,
    watchAddCollaborator,
    watchRemoveCollaborator,
    watchRequestSetProposalState,
    watchAddReviewer,
    watchRemoveReviewer,
    watchCheckLockStatus,
    watchReleaseLockStatus,
    watchUpdateSummary,
    watchUpdateName,
    watchDeleteProposalUpload
];

// Reducers
const path = 'proposals';
const reducer = (state = {
    all: Enumerable.empty(),
    current: null
}, action = {}) => {
    switch (action.type) {
    case ALL_PROPOSALS_SUCCESS:
        state = {
            ...state,
            all: Enumerable.from(action.allProposals).select(p => new Proposal(p))
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;
    case PROPOSAL_SUCCESS:
        // const all = state.all.where(p => p.id !== action.result.id);
        const result = new Proposal(action.result);
        // state = {
        //     ...state,
        //     all: all.concat([result]).orderBy(p => p.id)
        // };

        state = {
            ...state,
            current: result
        };

        if (action.cb)
            defer(() => action.cb(result));

        break;
    case PROPOSAL_UPDATE_NAME_SUCCESS:
    case LOCK_STATUS_SUCCESS:
        if (action.cb)
            defer(() => action.cb(action.response));
        break;
    case ADD_COLLABORATOR_SUCCESS:
        if (action.cb)
            defer(() => action.cb({id: action.id, name: action.name, email: action.email}));
        break;
    case REMOVE_COLLABORATOR_SUCCESS:
    case ADD_REVIEWER_SUCCESS:
    case REMOVE_REVIEWER_SUCCESS:
    case SET_PROPOSAL_STATE_SUCCESS:
    case PROPOSAL_UPDATE_DECISION_SUMMARY_SUCCESS:
    case PROPOSAL_DELETE_UPLOAD_SUCCESS:
        if (action.cb)
            defer(() => action.cb());
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestProposal,
    requestAllProposals,
    addCollaborator,
    removeCollaborator,
    setProposalState,
    addReviewer,
    removeReviewer,
    checkLockStatus,
    releaseLockStatus,
    requestUpdateProposalDecisionSummary,
    requestUpdateProposalName,
    deleteProposalUpload
};
