import {DEBUG} from '../../App';
import Enumerable from 'linq';

class CallForProposal {
    constructor(data) {
        Object.assign(this, data);


        this.extensions = Enumerable.from(this.extensions);
        this.extensions.forEach(e => {
            e.commencement = new Date(e.commencement);
            e.termination = new Date(e.termination);
        });

        this.authorIds = Enumerable.from(this.authorIds);

        this.collaboratorIds = Enumerable.from(this.collaboratorIds);


    }

    canSubmitProposal(userId) {
        if (DEBUG)
            return true;

        var commencement = this.commencement;
        var termination = this.termination;

        this.extensions.forEach(e => {
            if (userId === e.userId) {
                commencement = e.commencement;
                termination = e.termination;
            }
        });

        const now = new Date();
        return commencement < now && now < termination;
    }

    canSubmitReview() {
        if (DEBUG)
            return true;

        const now = new Date();
        return this.reviewCommencement < now && now < this.reviewTermination;
    }

    canCloseCall() {
        if (DEBUG)
            return true;

        const now = new Date();
        return now > this.reviewTermination;
    }
}

export default CallForProposal;