import React from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

import ServerErrors from '../../utils/ServerErrors';
import {requestLogin} from '../../stores/auth/store';
import {Button, Col, Image, Panel, Row} from 'react-bootstrap';
import {Control, LocalForm} from 'react-redux-form';
import {BootstrapInput} from '../../utils/BootstrapInputs';

import aaf_image from '../../assets/images/aaf_service.png';
import logo from '../../assets/images/logo.png';

const Login = (props) => {
    const {dispatch, errors, loggedIn} = props;
    const {from} = props.location.state || {from: {pathname: '/'}};

    if (loggedIn) {
        return <Redirect to={from}/>;
    }

    return (
        <div>
            <Col md={4} mdOffset={4}>
                <div className="text-center">
                    <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                    <h1>Proposal System</h1>
                </div>

                <Panel header={<h3>Please Sign In</h3>} className="login-panel">
                    <ServerErrors errors={errors}/>
                    <LocalForm
                        onSubmit={
                            ({username, password}) => {
                                dispatch(requestLogin(username, password));
                            }
                        }
                        initialState={{username: '', password: ''}}>
                        <Control
                            placeholder="Username"
                            component={BootstrapInput}
                            model=".username"
                            required
                            maxLength={150}
                        />
                        <Control
                            placeholder="Password"
                            component={BootstrapInput}
                            model=".password"
                            required
                            type="password"
                            maxLength={1000}
                        />
                        <p>No account? <Link to="/auth/register">Register</Link></p>
                        <p>Forgot password? <Link to="/auth/password-reset/">Reset Password</Link></p>
                        <Button type="submit" bsSize="large" bsStyle="success" block>Login</Button>
                    </LocalForm>

                    <hr/>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">Or use</div>
                            <div className="text-center">
                                <a href="https://rapid.aaf.edu.au/jwt/authnrequest/research/HwUHahumoPP4udX_HE9NFw">
                                    <img src={aaf_image} alt="Login"/>
                                </a>
                            </div>
                            <br/>
                            <p>Note: This will take you to the <a
                                href="https://rapid.aaf.edu.au/jwt/authnrequest/research/HwUHahumoPP4udX_HE9NFw">
                                AAF (Australian Access Federation) login page.</a><br/><br/>
                                If you are not yet authorised to use the ADACS Proposal System you will be
                                automatically redirected to the Registration Page after successfully authenticating
                                with your AAF credentials</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={12}>
                            <p>You can find help and documentation <a
                                href="/TAC_User_Manual_V1.pdf" target="_blank">
                            here</a>.</p>
                        </Col>
                    </Row>
                </Panel>
            </Col>
        </div>
    );
};

const mapStateToProps = ({misc, auth}) => ({
    errors: misc.errors,
    loggedIn: auth.loggedIn
});

// Connect the Register component to the dispatcher
const WrappedRegister = connect(mapStateToProps)(Login);

// Export the Register component from this module
export default WrappedRegister;
