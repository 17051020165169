import {call, put, takeEvery} from 'redux-saga/effects';
import {del, post} from '../../utils/api';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const ADD_EXTENSION_REQUEST = 'astacta/extensions/add/request';
const ADD_EXTENSION_SUCCESS = 'astacta/extensions/add/success';

const DELETE_EXTENSION_REQUEST = 'astacta/extensions/delete/request';
const DELETE_EXTENSION_SUCCESS = 'astacta/extensions/delete/success';


// Action creators
const addExtension = (callId, data, onSuccess) => ({
    callId: callId,
    data: data,
    onSuccess: onSuccess,
    type: ADD_EXTENSION_REQUEST
});

const receiveAddExtension = (data, onSuccess) => ({
    data: data,
    onSuccess: onSuccess,
    type: ADD_EXTENSION_SUCCESS
});

const deleteExtension = (callId, extensionId, onSuccess) => ({
    callId: callId,
    extensionId: extensionId,
    onSuccess: onSuccess,
    type: DELETE_EXTENSION_REQUEST
});

const receiveDeleteExtension = (onSuccess) => ({
    onSuccess: onSuccess,
    type: DELETE_EXTENSION_SUCCESS
});

// Sagas
/***
 * Submits the new call form data to the server
 * @returns
 */
function* requestAddExtension({callId, data, onSuccess}) {
    try {
        const response = yield call(post, '/new_extension/', {callId, ...data});
        yield put(receiveAddExtension(response.data, onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* requestDeleteExtension({callId, extensionId, onSuccess}) {
    try {
        yield call(del, '/delete_extension/', {callId, extensionId});

        yield put(receiveDeleteExtension(onSuccess));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchAdd() {
    yield takeEvery(ADD_EXTENSION_REQUEST, requestAddExtension);
}

function* watchDelete() {
    yield takeEvery(DELETE_EXTENSION_REQUEST, requestDeleteExtension);
}

const sagas = [
    watchAdd,
    watchDelete
];

// Reducers
const path = 'extensions';
const reducer = (state = {}, action = {}) => {
    switch (action.type) {
    case ADD_EXTENSION_SUCCESS:
        if (action.onSuccess)
            defer(() => action.onSuccess(action.data));
        break;
    case DELETE_EXTENSION_SUCCESS:
        if (action.onSuccess)
            defer(() => action.onSuccess());
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    addExtension,
    deleteExtension
};
