import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import {selector} from '../stores/auth/store';

const PrivateRoute = ({loggedIn, component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        loggedIn ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: '/auth/login',
                state: {from: props.location}
            }}/>
        )
    )}/>
);

const mapStateToProps = (state) => {
    const {loggedIn} = selector(state);
    return {loggedIn};
};

export default connect(mapStateToProps)(PrivateRoute);
