import React, {Component} from 'react';
import {connect} from 'react-redux';
import {requestSingleCall} from '../../stores/calls/store';
import {addExtension, deleteExtension} from '../../stores/extensions/store';
import {requestUsers} from '../../stores/users/store';
import {
    DropdownButton,
    MenuItem,
    PageHeader,
    Modal,
    Button,
    Col,
    ListGroup,
    ListGroupItem,
    Table
} from 'react-bootstrap';
import {navigate, formatDateTimeSimple} from '../../utils/generalUtils';
import CallDetails from './components/CallDetails';
import Enumerable from 'linq';
import {requestCommittees} from '../../stores/committees/store';
import {resetRequestErrors} from '../../stores/misc/store';
import {BootstrapInput, BootstrapDateTimePicker, BootstrapSelect} from '../../utils/BootstrapInputs';
import {Control, LocalForm} from 'react-redux-form';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageLoading from '../../components/PageLoading';
import ServerErrors from '../../utils/ServerErrors';
import {toast} from 'react-toastify';


class ViewCall extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true,
            showManageExtensions: false,
            extensions: Enumerable.empty()
        };
    }

    componentWillMount() {
        // Request the committees from the server
        this.props.requestCommittees(() =>
            // Request the call from the server
            this.props.requestSingleCall(this.props.match.params.callId,
                (call) => {
                    let state = {
                        ...this.state,
                        callId: call.id,
                        extensions: call.extensions,
                        isLoading: false
                    };
                    if (this.props.localUser.isChair(this.props.call.committee)) {
                        // Request the list of users from the server
                        this.props.requestUsers(() =>
                            this.setState(state)
                        );
                    } else {
                        this.setState(state);
                    }
                }
            )
        );
    }

    renderManageExtensions() {
        // Create the user select options
        let options = Enumerable.from([(<option key={0} value="">---</option>)])
            .concat(
                this.props.users
                    .select((u) => (<option value={u.id} key={u.id}>{u.username}</option>))
            );

        // Render the current list of extensions
        let currentExtensions;
        if (this.state.extensions.count()) {
            currentExtensions = this.state.extensions.select((e, idx) => (
                <ListGroupItem key={idx}>
                    {/* Create table to properly space out the contents of the extension */}
                    <Table striped size="sm">
                        <thead>
                            <tr>
                                <th>{e.userName}</th>
                                <th>
                                    <Button bsSize="xs" bsStyle="default" className="pull-right" onClick={
                                        () => this.props.deleteExtension(this.state.callId, e.id, () => {
                                            // Copy the existing state
                                            let state = {
                                                ...this.state
                                            };

                                            // Remove the extension from the list of extensions
                                            state.extensions = state.extensions.where(ex => ex.id !== e.id);

                                            // Actually update the state
                                            this.setState(state);
                                        })
                                    }>Remove</Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    {`${formatDateTimeSimple(e.commencement)} - ${formatDateTimeSimple(e.termination)}`}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    {e.reason}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </ListGroupItem>
            )).toArray();
        } else {
            currentExtensions = (
                <p>No extensions</p>
            );
        }

        return (
            <Modal.Dialog bsSize="large">
                <Modal.Header>
                    <Modal.Title>Manage Extensions</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Col md={6}>
                        <div>
                            <ServerErrors errors={this.props.errors}/>
                            <LocalForm
                                key={this.state.extensions.count()}
                                onChange={() => this.props.resetRequestErrors()}
                                onSubmit={
                                    (data) => {
                                        this.props.addExtension(this.state.callId, data,
                                            (details) => {
                                                // Copy the existing state
                                                let state = {
                                                    ...this.state
                                                };

                                                // Add the extension to the list of extensions
                                                state.extensions = state.extensions.concat([{
                                                    id: details.id,
                                                    userId: details.userId,
                                                    userName: details.userName,
                                                    commencement: new Date(details.commencement),
                                                    termination: new Date(details.termination),
                                                    reason: details.reason,
                                                }]);

                                                // Actually update the state
                                                this.setState(state);

                                                // Notify the user
                                                toast.success('Extension added successfully');
                                            }
                                        );
                                    }
                                }
                                model="data"
                                initialState={{
                                    commencement: this.props.call.commencement,
                                    termination: this.props.call.termination,
                                    reason: '',
                                    new: true,
                                }}
                            >
                                <Control
                                    label="Commencement date"
                                    component={BootstrapDateTimePicker}
                                    model=".commencement"
                                    required
                                />
                                <Control
                                    label="Termination date"
                                    component={BootstrapDateTimePicker}
                                    model=".termination"
                                    required
                                />
                                <Control
                                    label="User"
                                    type="select"
                                    component={BootstrapSelect}
                                    options={options.toArray()}
                                    model=".user"
                                    required
                                />
                                <Control
                                    placeholder="Reason for extension..."
                                    component={BootstrapInput}
                                    model=".reason"
                                    maxLength={200}
                                />
                                <Button type="submit" block>Add Extension</Button>
                            </LocalForm>
                        </div>
                    </Col>
                    <Col md={6}>
                        {/* Render the list of collaborators */}
                        <h4>Current extensions</h4>
                        <ListGroup className="extension-list">
                            {currentExtensions}
                        </ListGroup>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({
                        ...this.state,
                        showManageExtensions: false
                    })}>Close</Button>
                </Modal.Footer>

            </Modal.Dialog>
        );
    }

    render() {
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: this.props.call.name,
                            url: `/calls/${this.props.call.id}/`
                        }
                    ]
                }/>

                <PageHeader>
                    Call for Proposal - {this.props.call.name}

                    <div className="pull-right">
                        <DropdownButton id="manage-proposal" title="Manage" pullRight>
                            {/* Add new proposal - only if after submission start and before submission end date*/}
                            {this.props.call.canSubmitProposal(this.props.localUser.id) ? (
                                <MenuItem onClick={() => navigate(this, `${this.props.match.url}new`)}>Add
                                    Proposal</MenuItem>
                            ) : null}

                            {/* View proposal */}
                            <MenuItem onClick={() => navigate(this, `${this.props.match.url}proposals/`)}>View
                                Proposals</MenuItem>

                            {/* Can edit the proposal if the local user is the chair of the committee of this
                            proposal */}
                            {this.props.localUser.isChair(this.props.call.committee) && !this.props.call.closed ? (
                                <MenuItem
                                    onClick={() => navigate(this, `${this.props.match.url}update`)}>Edit Call
                                    Details</MenuItem>
                            ) : null}

                            {/* Manage proposal if is the chair */}
                            {this.props.localUser.isChair(this.props.call.committee) ? (
                                <MenuItem
                                    onClick={() => navigate(this, `${this.props.match.url}manage`)}>Manage
                                    Call</MenuItem>
                            ) : null}

                            {this.props.localUser.isChair(this.props.call.committee) ? (
                                <MenuItem
                                    onClick={
                                        () => this.setState(
                                            {...this.state, showManageExtensions: true}
                                        )
                                    }
                                    disabled={this.state.isNew}
                                >
                                    Manage Extensions
                                </MenuItem>
                            ) : null}
                        </DropdownButton>
                    </div>
                </PageHeader>

                {/* Render the call details */}
                <CallDetails
                    getFormName={(f) => this.getFormName(f)}
                    committee={this.props.committees.firstOrDefault((c) => c.id === this.props.call.committee)}
                    name={this.props.call.name}
                    openDateTime={this.props.call.commencement}
                    closeDateTime={this.props.call.termination}
                    reviewOpenDateTime={this.props.call.reviewCommencement}
                    reviewCloseDateTime={this.props.call.reviewTermination}
                    isReviewOpen={this.props.call.isReviewOpen}
                    description={this.props.call.description}
                />
                {this.state.showManageExtensions ? this.renderManageExtensions() : null}
            </div>
        );
    }
}

const mapStateToProps = ({
    calls,
    committees,
    auth,
    users
}) => ({
    call: calls.currentCall,
    committees: committees,
    localUser: auth.localUser,
    users: users.all
});

export default connect(mapStateToProps, {
    requestSingleCall,
    requestCommittees,
    resetRequestErrors,
    requestUsers,
    addExtension,
    deleteExtension
})(ViewCall);
