import React, {Component} from 'react';
import {Alert, Button, Col, ListGroup, ListGroupItem, PageHeader, Panel, Row, Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Control, LocalForm} from 'react-redux-form';
import {requestSingleCall, requestSubmitCall} from '../../stores/calls/store';
import {
    BootstrapCheckbox,
    BootstrapDateTimePicker,
    BootstrapInput,
    BootstrapSelect
} from '../../utils/BootstrapInputs';
import JsonSchemaTinyMce from '../../components/JsonSchemaTinyMce';
import {requestCommittees} from '../../stores/committees/store';
import {
    requestAllFormNames,
    requestAllForms,
    requestProposalFormOrder,
    requestReviewFormOrder
} from '../../stores/forms/store';
import Enumerable from 'linq';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import {defer} from '../../utils/generalUtils';
import CallDetails from './components/CallDetails';
import Breadcrumbs from '../../components/Breadcrumbs';
import PermissionDenied from '../PermissionDenied';
import {requestAllProposals} from '../../stores/proposals/store';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import {PROPOSAL_FORM_TYPES, REVIEW_FORM_TYPES} from '../../utils/constants';

/* eslint no-unused-vars: 0 */

const EDIT_CALL_DETAILS = 0;
const EDIT_CALL_TYPES = 1;
const EDIT_CALL_REVIEW = 2;
const EDIT_CALL_SUCCESS = 3;

class EditCall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: EDIT_CALL_DETAILS,
            isNew: true,
            loading: true,

            // New call data
            committee: '',
            name: '',
            openDateTime: new Date(),
            closeDateTime: new Date(),
            reviewOpenDateTime: new Date(),
            reviewCloseDateTime: new Date(),
            description: '',
            isReviewOpen: false,
            form_order_fetched: false,

            proposalTypes: Enumerable.empty(),

            activeTab: 0,
            activeTabForms: 0,

            proposalForms: null,
            reviewForms: null,
        };
    }

    submitNewCall() {
        let data = {
            new: this.state.isNew,
            id: this.props.match.params.callId || null,
            committee: this.state.committee,
            name: this.state.name,
            openDateTime: this.state.openDateTime,
            closeDateTime: this.state.closeDateTime,
            reviewOpenDateTime: this.state.reviewOpenDateTime,
            reviewCloseDateTime: this.state.reviewCloseDateTime,
            isReviewOpen: this.state.isReviewOpen,
            description: this.state.description,
            proposalTypes: this.state.proposalTypes.select((e, i) => ({
                typeName: e.typeName,
                typeId: e.typeId,
                proposalForms: e.proposalForms.select((f, i2) => f.id).toArray(),
                reviewForms: e.reviewForms.select((f, i2) => f.id).toArray(),
            })).toArray()
        };

        this.props.requestSubmitCall(data, (callId) => {
            this.setState({
                ...this.state,
                callId: callId,
                currentPage: this.state.currentPage + 1
            });
        });
    }

    componentWillMount() {
        // Request the list of committees when the component is first mounted
        this.props.requestCommittees(() =>
            // Request the list of forms
            this.props.requestAllForms(() =>
                // Request the list of form names
                this.props.requestAllFormNames(
                    () => {
                        // Check if we are editing a call
                        if (this.props.match.params.callId) {
                            this.props.requestAllProposals(this.props.match.params.callId);

                            this.props.requestSingleCall(this.props.match.params.callId, (c) => {
                                this.setState(
                                    {
                                        ...this.state,
                                        isNew: false,
                                        loading: false,
                                        committee: c.committee,
                                        name: c.name,
                                        openDateTime: c.commencement,
                                        closeDateTime: c.termination,
                                        reviewOpenDateTime: c.reviewCommencement,
                                        reviewCloseDateTime: c.reviewTermination,
                                        isReviewOpen: c.isReviewOpen,
                                        description: c.description,
                                        closed: c.closed,
                                    }
                                );
                            });
                        } else
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                    }
                )
            )
        );
    }

    getFormName(form) {
        return this.props.allFormNames.firstOrDefault((n) => n.id === form.name);
    }

    // Renders the details page of the new call
    renderDetails() {
        // Create the committee select options
        let options = Enumerable.from([(<option key={0} value="">---</option>)])
            .concat(
                this.props.localUser.getChairCommittees(this.props.committees)
                    .select((c) => (<option value={c.id} key={c.id}>{c.name}</option>))
            );

        return (
            <LocalForm
                initialState={this.state}
                onChange={
                    (data) => {
                        this.setState(
                            {
                                ...this.state,
                                ...data
                            }
                        );
                    }
                }
                onSubmit={
                    () => this.setState(
                        {
                            ...this.state,
                            currentPage: this.state.currentPage + 1
                        }
                    )
                }
                validators={{
                    '': {
                        closeDateTimeAfterOpenDateTime: (vals) => vals.closeDateTime >= vals.openDateTime,
                        reviewOpenDateTimeAfterCloseDateTime: (vals) => vals.reviewOpenDateTime >= vals.closeDateTime,
                        reviewCloseDateTimeAfterReviewOpenDateTime:
                            (vals) => vals.reviewCloseDateTime >= vals.reviewOpenDateTime,
                    }
                }}
            >

                {/* Committee for this call for proposals */}
                <Control
                    label="Committee" type="select"
                    component={BootstrapSelect}
                    options={options.toArray()}
                    model=".committee"
                    required
                    disabled={!this.state.isNew}
                />

                {/* Name of the call for proposals */}
                <Control
                    label="Call for Proposal Name"
                    component={BootstrapInput}
                    model=".name"
                    required
                    maxLength={255}
                />

                {/* The opening date and time of the call */}
                <Control
                    label="Open Date/Time for Proposals"
                    component={BootstrapDateTimePicker}
                    model=".openDateTime"
                    required
                    maxLength={200}
                />

                {/* The closing date and time of the call */}
                <Control
                    label="Close Date/Time for Proposals"
                    component={BootstrapDateTimePicker}
                    model=".closeDateTime"
                    required
                    error={
                        this.state.closeDateTime < this.state.openDateTime ?
                            'Proposal close time must be after proposal open time' : null
                    }
                    maxLength={200}
                />

                {/* The opening date and time of reviews */}
                <Control
                    label="Open Date/Time for Reviews"
                    component={BootstrapDateTimePicker}
                    model=".reviewOpenDateTime"
                    required
                    error={
                        this.state.reviewOpenDateTime < this.state.closeDateTime ?
                            'Review open time must be after proposal close time' : null
                    }
                    maxLength={200}
                />

                {/* The closing date and time of reviews */}
                <Control
                    label="Close Date/Time for Reviews"
                    component={BootstrapDateTimePicker}
                    model=".reviewCloseDateTime"
                    required
                    error={
                        this.state.reviewCloseDateTime < this.state.reviewOpenDateTime ?
                            'Review close time must be after review open time' : null
                    }
                    maxLength={200}
                />

                {/* The call description */}
                <Control
                    label="Description"
                    component={JsonSchemaTinyMce}
                    model=".description"
                />

                {/* If the call is able to be openly reviewed */}
                <Control.checkbox
                    label="Open Review"
                    type="checkbox"
                    component={BootstrapCheckbox}
                    model=".isReviewOpen"
                    disabled={!this.state.isNew}
                />

                {/* Next button (Also validates the form) */}
                <Button bsStyle="primary" className="pull-right" type="submit">Next -&gt;</Button>
            </LocalForm>
        );
    }

    canEditForms() {
        // If the cfp is new, then forms can be removed
        if (this.state.isNew)
            return true;
        // If there are no proposals, then the forms can be edited
        return this.props.proposals.count() === 0;
    }

    // Renders a forms page of the new call
    renderFormPage() {
        const proposalTypes = this.state.proposalTypes.select((e, index) => (
            <Tab title={e.typeName} eventKey={index} key={index}>
                {this.renderFormTypePage(e, index)}
            </Tab>
        )).toArray();

        proposalTypes.push((
            <Tab title="Add New" eventKey={proposalTypes.length} key={proposalTypes.length}>
                <Row>
                    <Col md={4} mdOffset={4}>
                        <Panel header={<h3>Create New Proposal Type</h3>} className="login-panel">
                            <LocalForm
                                onSubmit={
                                    ({proposalTypeName}) => {
                                        const newForms = this.state.proposalTypes.toArray();
                                        newForms.push(
                                            {
                                                typeName: proposalTypeName,
                                                proposalForms: Enumerable.empty(),
                                                reviewForms: Enumerable.empty()
                                            }
                                        );
                                        this.setState(
                                            {
                                                ...this.state,
                                                proposalTypes: Enumerable.from(newForms)
                                            }
                                        );
                                    }
                                }
                                initialState={{proposalTypeName: ''}}>
                                <Control
                                    placeholder="Proposal Type Name"
                                    component={BootstrapInput}
                                    model=".proposalTypeName"
                                    required
                                    maxLength={50}
                                />
                                <Button type="submit" bsSize="large" bsStyle="success" block>Add Proposal
                                    Type</Button>
                            </LocalForm>
                        </Panel>
                    </Col>
                </Row>
            </Tab>
        ));

        return (
            <div>
                <Tabs
                    id="proposal-types" activeKey={this.state.activeTab}
                    onSelect={(key, event) => this.setState({...this.state, activeTab: key, activeTabForms: 0})}>
                    {proposalTypes}
                </Tabs>
                {/* Next button */}
                <Button bsStyle="primary" className="pull-right" onClick={() => this.setState({
                    ...this.state,
                    currentPage: this.state.currentPage + 1
                })} disabled={!this.hasValidFormSetup()}>Next -&gt;</Button>

                {/* Previous button */}
                <Button bsStyle="primary" className="pull-left" onClick={() => this.setState({
                    ...this.state,
                    currentPage: this.state.currentPage - 1
                })}>{'<- Previous'}</Button>
            </div>
        );
    }

    hasValidFormSetup() {
        // If there are no proposal types, then the form is not valid
        if (!this.state.proposalTypes.count())
            return false;

        return this.state.proposalTypes.all(e => e.proposalForms.count() > 0 && e.reviewForms.count() > 0);
    }

    renderFormTypePage(type, index) {
        const proposals = this.props.proposals.where((e) => e.proposalTypeId == type.typeId);
        const hasReviews = proposals.any((p) => p.reviewScores.any());
        const proposalDisabledMessage = 'Editing disabled because there are saved proposals for this proposal type.';
        const reviewDisabledMessage = 'Editind disabled because there are saved reviews for this proposal type.';
        return (
            <div>
                <br/>
                <Tabs
                    id="form-type" activeKey={this.state.activeTabForms}
                    onSelect={(key, event) => this.setState({...this.state, activeTabForms: key})}>
                    <Tab
                        title="Proposal Forms" eventKey={0}
                        key={0}
                    >
                        {
                            this.renderProposalForms(
                                index,
                                'Proposal',
                                'proposalForms',
                                PROPOSAL_FORM_TYPES,
                                !proposals.any(),
                                proposalDisabledMessage
                            )
                        }
                    </Tab>
                    <Tab
                        title="Review Forms" eventKey={1}
                        key={1}>
                        {
                            this.renderProposalForms(
                                index,
                                'Review',
                                'reviewForms',
                                REVIEW_FORM_TYPES,
                                !hasReviews,
                                reviewDisabledMessage
                            )
                        }</Tab>
                    <Tab
                        title="Delete" eventKey={2} key={2} disabled={proposals.any()}>
                        <Row>
                            <Col md={4} mdOffset={4}>
                                <Panel
                                    header={<h3>Are you sure you want to delete this proposal type?</h3>}
                                    className="login-panel"
                                >
                                    <Button bsSize="large" bsStyle="danger" block onClick={() => {
                                        const types = this.state.proposalTypes.toArray();
                                        types.splice(index, 1);
                                        this.setState({
                                            ...this.state,
                                            proposalTypes: Enumerable.from(types),
                                            activeTabForms: 0,
                                            activeTab: 0
                                        });
                                    }
                                    }>Delete</Button>
                                </Panel>
                            </Col>
                            <Col md={4}/>
                        </Row>
                    </Tab>
                </Tabs>
            </div>
        );
    }

    renderProposalForms(index, kind, proposalKey, types, enabled, disabledMessage) {
        // Create the list of valid forms
        const forms = this.props.allForms.where(
            // Check if this form type is in the form types we want to render
            f => types.contains(
                // Get the form name so we can compare the type
                this.props.allFormNames.firstOrDefault(
                    // Check if this form name is the form name for this form
                    n => n.id === f.name
                ).type
            )
        );

        // Create the list of available forms
        let availableProposalForms = forms
            // Exclude any forms already in the consumed forms
            .except(this.state.proposalTypes.elementAt(index)[proposalKey])
            // Iterate over the array and convert the array to a list of objects for the data table
            .select((f) => ({
                o: f,
                name: this.getFormName(f).name,
                version: f.version
            })).toArray();

        // Create a sortable item wrapper around the list group item
        let SortableItem = SortableElement(
            ({value}) => (
                <ListGroupItem>
                    {/* Create the item text */}
                    {this.getFormName(value).name} {value.version}

                    {/* Create a remove item button*/}
                    <Button bsSize="xs" bsStyle="default" className="pull-right" onClick={
                        () => {
                            // Remove this item from the list of forms
                            // Copy the existing state
                            let state = {
                                ...this.state
                            };

                            // Exclude this item from the list of forms
                            this.state.proposalTypes.elementAt(index)[proposalKey] =
                                this.state.proposalTypes.elementAt(index)[proposalKey].except([value]);

                            // Actually update the state
                            this.setState(state);
                        }
                    } disabled={!enabled}>Remove</Button>
                </ListGroupItem>
            )
        );

        // Create a sortable list and map the forms to sortable items
        let SortableList = SortableContainer(
            ({items}) => (
                // Create the list group
                <ListGroup>
                    {
                        // Map the items to sortable items
                        items.map(
                            (f, index) =>
                                // Create the sortable item for this index and item
                                // eslint-disable-next-line react/no-array-index-key
                                <SortableItem key={index} index={index} value={f}/>
                        )
                    }
                </ListGroup>
            )
        );

        // Create a sortable list of forms
        let selectedProposalForms = (
            // Create the sortable list
            <SortableList items={this.state.proposalTypes.elementAt(index)[proposalKey].toArray()} onSortEnd={
                // Reorder the list of forms in the sortable
                ({oldIndex, newIndex}) => {
                    // Copy the old state
                    let state = {
                        ...this.state
                    };

                    // Reorder the items in the state
                    this.state.proposalTypes.elementAt(index)[proposalKey] =
                        Enumerable.from(
                            arrayMove(
                                this.state.proposalTypes.elementAt(index)[proposalKey].toArray(),
                                oldIndex,
                                newIndex
                            )
                        );

                    // Actually update the state
                    this.setState(state);
                }
            }/>
        );

        return (
            <div>
                {!enabled && <Row>
                    <Col md={12}>
                        <Alert bsStyle="warning" style={{margin: '10px 0'}}>{disabledMessage}</Alert>
                    </Col>
                </Row>}
                <Row>
                    <Col md={6}>
                        {/* Render the list of available forms */}
                        <h4>Available {kind} Forms</h4>
                        <BootstrapTable data={availableProposalForms} striped search pagination hover>
                            <TableHeaderColumn dataField="name" isKey={true}>Form Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="version">Version</TableHeaderColumn>
                            <TableHeaderColumn dataField="add" dataFormat={
                                // Creates an add button that adds the form on this row to the list of forms
                                (cell, row) => (
                                    <Button
                                        bsStyle="default"
                                        className="pull-right"
                                        onClick={
                                            () => {
                                                // Copy the existing state
                                                let state = {
                                                    ...this.state
                                                };

                                                // Add the new form to the state
                                                this.state.proposalTypes.elementAt(index)[proposalKey] =
                                                    this.state.proposalTypes.elementAt(index)[proposalKey].concat(
                                                        [row.o]
                                                    );

                                                // Actually update the state
                                                this.setState(state);
                                            }
                                        }
                                        disabled={!enabled}>
                                        Add -&gt;
                                    </Button>
                                )
                            }>Add</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                    <Col md={6}>
                        {/* Render the list of forms in the proposal */}
                        <h4>{kind} Form Order</h4>
                        {selectedProposalForms}
                    </Col>
                </Row>
            </div>
        );
    }

    // Renders the review page of the new call for proposal
    renderReviewPage() {
        return (
            <div>
                <CallDetails
                    getFormName={(f) => this.getFormName(f)}
                    proposalTypes={this.state.proposalTypes}
                    committee={
                        this.props.committees.firstOrDefault(
                            (c) => c.id === parseInt(this.state.committee, 10)
                        )
                    }
                    name={this.state.name}
                    openDateTime={this.state.openDateTime}
                    closeDateTime={this.state.closeDateTime}
                    reviewOpenDateTime={this.state.reviewOpenDateTime}
                    reviewCloseDateTime={this.state.reviewCloseDateTime}
                    isReviewOpen={this.state.isReviewOpen}
                    description={this.state.description}
                />

                {/* Previous button */}
                <Button bsStyle="primary" className="pull-left" onClick={() => this.setState({
                    ...this.state,
                    currentPage: this.state.isNew ? (this.state.currentPage - 1) : EDIT_CALL_TYPES
                })}>{'<- Previous'}</Button>

                {/* Submit button */}
                <Button bsStyle="primary" className="pull-right" onClick={() => this.submitNewCall()}>Finish</Button>
            </div>
        );
    }

    renderSuccessPage() {
        return (
            <div>
                <p>
                    Call for proposal &quot;{this.state.name}&quot; {this.state.isNew ? 'created' : 'updated'}
                    &nbsp;successfully
                </p>
                <Link to={`/calls/${this.state.isNew ? this.state.callId : this.props.match.params.callId}/`}>
                    Continue
                </Link>
            </div>
        );
    }

    render() {
        // Prevent users that are not chairs from creating a proposal
        if (!this.props.localUser.isChair() || this.state.closed)
            return (<PermissionDenied/>);

        let currentPage;
        // Check that there are any committees and any forms
        if (!this.props.localUser.isValid() || this.state.loading) {
            // No committees or no forms, we are loading still
            currentPage = (<PageLoading/>);
        } else {
            // Check if we need to ask the server for the form order
            if (!this.state.isNew && !this.state.form_order_fetched) {
                defer(() => {
                    this.props.requestProposalFormOrder(this.props.match.params.callId, response => {
                        this.setState({
                            ...this.state,
                            proposalForms: Enumerable.from(response)
                        });
                    });

                    this.props.requestReviewFormOrder(this.props.match.params.callId, response => {
                        this.setState({
                            ...this.state,
                            reviewForms: Enumerable.from(response)
                        });
                    });

                    this.setState({
                        ...this.state,
                        form_order_fetched: true
                    });
                });
            }

            if (this.state.proposalForms && this.state.reviewForms) {
                const types = Enumerable.from(this.state.proposalForms).select((p, i) => ({
                    'name': p.typeName,
                    'id': p.typeId
                }));
                const proposalTypes = Enumerable.from(types.select((p, i) => {
                    const proposalIds = this.state.proposalForms.where((f, _) => f.typeId === p.id).first().forms;
                    const proposalForms =
                        Enumerable.from(proposalIds).select(
                            (f, _) => this.props.allForms.firstOrDefault(z => z.id === f)
                        );

                    const reviewIds = this.state.reviewForms.where((f, _) => f.typeId === p.id).first().forms;
                    const reviewForms =
                        Enumerable.from(reviewIds).select(
                            (f, _) => this.props.allForms.firstOrDefault(z => z.id === f)
                        );

                    return {
                        typeId: p.id,
                        typeName: p.name,
                        proposalForms: proposalForms,
                        reviewForms: reviewForms
                    };
                }).toArray());

                defer(() => {
                    this.setState({
                        ...this.state,
                        proposalForms: null,
                        reviewForms: null,
                        proposalTypes: proposalTypes
                    });
                });
            }

            // Render the current page
            switch (this.state.currentPage) {
            case EDIT_CALL_DETAILS:     // The first page is the details form
                currentPage = this.renderDetails();
                break;

            case EDIT_CALL_TYPES:       // The second page is the proposal types and form
                currentPage = this.renderFormPage();
                break;

            case EDIT_CALL_REVIEW:      // The fourth page is the review page
                currentPage = this.renderReviewPage();
                break;

            case EDIT_CALL_SUCCESS:     // The fifth page is the success status page
                currentPage = this.renderSuccessPage();
                break;

            default:
                currentPage = (<p>Something went wrong</p>);
            }
        }

        const items = [
            {
                name: 'Call for Proposals',
                url: '/calls'
            },
            {
                name: this.state.isNew ? 'New' : `${this.state.name}`,
                url: this.state.isNew ? '/calls/new' : `/calls/${this.props.match.params.callId}/`
            }
        ];

        if (!this.state.isNew)
            items.push(
                {
                    name: 'Update',
                    url: ''
                }
            );

        return (
            <div>
                <Breadcrumbs items={items}/>

                <PageHeader>{this.state.isNew ? 'New Call for Proposals' : `Update ${this.state.name}`}</PageHeader>
                {currentPage}
            </div>
        );
    }
}

const mapStateToProps = ({committees, forms, auth, proposals}) => ({
    proposals: proposals.all,
    committees: committees,
    allForms: forms.all,
    allFormNames: forms.names,
    localUser: auth.localUser
});

export default connect(mapStateToProps, {
    requestAllFormNames,
    requestAllForms,
    requestCommittees,
    requestSubmitCall,
    requestSingleCall,
    requestProposalFormOrder,
    requestReviewFormOrder,
    requestAllProposals,
})(EditCall);
