import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {PageHeader} from 'react-bootstrap';
import {connect} from 'react-redux';
import {requestUsers} from '../../stores/users/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import PermissionDenied from '../PermissionDenied';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';

class ListUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {},
            isLoading: true
        };
    }

    componentWillMount() {
        this.props.requestUsers(() =>
            this.setState({
                ...this.state,
                isLoading: false
            })
        );
    }


    render() {
        // Check if we are waiting to be ready
        if (!this.props.localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        if (!this.props.localUser.isAdmin())
            return (<PermissionDenied/>);

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Users',
                            url: '/users'
                        }
                    ]
                }/>

                <PageHeader>
                    Users
                </PageHeader>
                <BootstrapTable data={this.props.users.toArray()} striped search pagination hover>
                    <TableHeaderColumn dataField="username">User Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="is_aaf">Is AAF?</TableHeaderColumn>
                    <TableHeaderColumn dataField="email" isKey={true}>Email Address</TableHeaderColumn>
                    <TableHeaderColumn dataField="institution">Institution</TableHeaderColumn>
                    <TableHeaderColumn dataField="view" dataFormat={
                        // Creates an add button that adds the form on this row to the list of forms
                        (cell, row) => (
                            <Link to={`/users/${row.id}/`}>
                                View
                            </Link>
                        )
                    }/>
                </BootstrapTable>
            </div>
        );
    }
}

const mapStateToProps = ({users, auth}) => 
    // Set the state accordingly
    ({
        users: users.all,
        localUser: auth.localUser
    })
;


export default connect(mapStateToProps, {requestUsers})(ListUsers);
