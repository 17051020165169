import createSagaMiddleware from 'redux-saga';

import {all, spawn} from 'redux-saga/effects';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import * as auth from './stores/auth/store';
import * as forms from './stores/forms/store';
import * as calls from './stores/calls/store';
import * as extensions from './stores/extensions/store';
import * as committees from './stores/committees/store';
import * as proposals from './stores/proposals/store';
import * as reviews from './stores/reviews/store';
import * as users from './stores/users/store';
import * as misc from './stores/misc/store';

const rootReducer = combineReducers({
    [auth.path]: auth.reducer,
    [forms.path]: forms.reducer,
    [calls.path]: calls.reducer,
    [committees.path]: committees.reducer,
    [extensions.path]: extensions.reducer,
    [proposals.path]: proposals.reducer,
    [reviews.path]: reviews.reducer,
    [users.path]: users.reducer,
    [misc.path]: misc.reducer,
});

function* Sagas() {
    yield all([
        ...forms.sagas,
        ...auth.sagas,
        ...calls.sagas,
        ...committees.sagas,
        ...extensions.sagas,
        ...proposals.sagas,
        ...reviews.sagas,
        ...users.sagas,
        ...misc.sagas,
    ].map(saga => spawn(saga)));
}

const sagaMiddleware = createSagaMiddleware();

export default createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            sagaMiddleware,
        )
    )
);

sagaMiddleware.run(Sagas);
