import {call, put, takeEvery} from 'redux-saga/effects';
import {del, get, post} from '../../utils/api';
import Enumerable from 'linq';
import Form from './Form';
import {REQUEST_ERROR} from '../misc/store';
import {defer} from '../../utils/generalUtils';


// Action types
const FORM_REQUEST = 'astacta/forms/request';
const FORM_SUCCESS = 'astacta/forms/success';

const FORM_SAVE_REQUEST = 'astacta/forms/save/request';
const FORM_SAVE_SUCCESS = 'astacta/forms/save/success';

const FORM_DELETE_REQUEST = 'astacta/forms/delete/request';
const FORM_DELETE_SUCCESS = 'astacta/forms/delete/success';

const SUBMIT_REQUEST = 'astacta/forms/submit/request';
const SUBMIT_SUCCESS = 'astacta/forms/submit/success';

const ALL_FORMS_REQUEST = 'astacta/allforms/request';
const ALL_FORMS_SUCCESS = 'astacta/allforms/success';

const FORM_NAMES_REQUEST = 'astacta/formnames/request';
const FORM_NAMES_SUCCESS = 'astacta/formnames/success';

const FORM_NAME_DELETE_REQUEST = 'astacta/formnames/delete/request';
const FORM_NAME_DELETE_SUCCESS = 'astacta/formnames/delete/success';

const FORM_NAME_SAVE_REQUEST = 'astacta/formnames/save/request';
const FORM_NAME_SAVE_SUCCESS = 'astacta/formnames/save/success';

const PROPOSAL_FORM_ORDER_REQUEST = 'astacta/proposal_form_order/request';
const PROPOSAL_FORM_ORDER_SUCCESS = 'astacta/proposal_form_order/success';

const REVIEW_FORM_ORDER_REQUEST = 'astacta/review_form_order/request';
const REVIEW_FORM_ORDER_SUCCESS = 'astacta/review_form_order/success';

const DELETE_MULTI_FORM_REQUEST = 'astacta/delete_multi_form/request';
const DELETE_MULTI_FORM_SUCCESS = 'astacta/delete_multi_form/success';

// Action creators
const requestForm = (id, cb) => ({
    type: FORM_REQUEST,
    id,
    cb
});

const receiveForm = (form, cb) => ({
    response: form,
    type: FORM_SUCCESS,
    cb: cb
});

const requestFormSave = (data, cb) => ({
    type: FORM_SAVE_REQUEST,
    data: data,
    cb: cb
});

const receiveFormSave = (response, cb) => ({
    response: response,
    type: FORM_SAVE_SUCCESS,
    cb: cb
});

const requestFormNameDelete = (id, cb) => ({
    type: FORM_NAME_DELETE_REQUEST,
    id,
    cb
});

const receiveFormNameDelete = (cb) => ({
    type: FORM_NAME_DELETE_SUCCESS,
    cb
});

const requestFormNameSave = (id, name, display_name, nameType, cb) => ({
    type: FORM_NAME_SAVE_REQUEST,
    id,
    name,
    display_name,
    nameType,
    cb
});

const receiveFormNameSave = (cb) => ({
    type: FORM_NAME_SAVE_SUCCESS,
    cb
});

const requestFormDelete = (id, cb) => ({
    type: FORM_DELETE_REQUEST,
    id,
    cb
});

const receiveFormDelete = (cb) => ({
    type: FORM_DELETE_SUCCESS,
    cb
});

const requestProposalFormOrder = (callId, cb) => ({
    type: PROPOSAL_FORM_ORDER_REQUEST,
    callId,
    cb
});

const receiveProposalFormOrder = (order, cb) => ({
    order: order,
    cb: cb,
    type: PROPOSAL_FORM_ORDER_SUCCESS,
});

const requestReviewFormOrder = (callId, cb) => ({
    type: REVIEW_FORM_ORDER_REQUEST,
    callId,
    cb
});

const receiveReviewFormOrder = (order, cb) => ({
    order: order,
    cb: cb,
    type: REVIEW_FORM_ORDER_SUCCESS,
});

const requestAllForms = (cb) => ({
    type: ALL_FORMS_REQUEST,
    cb: cb
});

const receiveAllForms = (allForms, cb) => ({
    type: ALL_FORMS_SUCCESS,
    allForms: allForms,
    cb: cb
});

const requestAllFormNames = (cb) => ({
    type: FORM_NAMES_REQUEST,
    cb: cb
});

const receiveAllFormNames = (allFormNames, cb) => ({
    type: FORM_NAMES_SUCCESS,
    allFormNames: allFormNames,
    cb: cb
});

const requestSubmit = (extra, data, postURL, cb) => ({
    type: SUBMIT_REQUEST,
    extra,
    data,
    postURL,
    cb
});

const receiveSubmit = (response, cb) => ({
    type: SUBMIT_SUCCESS,
    cb: cb,
    response: response
});

const requestDeleteMultiForm = (callId, proposalId, formId, formIndex, cb) => ({
    type: DELETE_MULTI_FORM_REQUEST,
    callId,
    proposalId,
    formId,
    formIndex,
    cb
});

const receiveDeleteMultiForm = (response, cb) => ({
    type: DELETE_MULTI_FORM_SUCCESS,
    cb: cb,
    response: response
});

// Sagas
/***
 * Fetches a form definition from the server
 * @param id: The id of the form to fetch from the server
 * @returns
 */
function* fetchForm({id, cb}) {
    try {
        const response = yield call(get, `/forms/?id=${id}`);

        yield put(receiveForm(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Saves a form on the server
 * @returns
 */
function* fetchFormSave({data, cb}) {
    try {
        const response = yield call(post, '/forms/', data);

        yield put(receiveFormSave(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Deletes a form from the server
 * @param id: The id of the form to delete from the server
 * @returns
 */
function* fetchFormDelete({id, cb}) {
    try {
        yield call(del, '/forms/', {id: id});

        yield put(receiveFormDelete(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Saves a form name on the server
 * @returns
 */
function* fetchFormNameSave({id, name, display_name, nameType, cb}) {
    try {
        yield call(post, '/form_names/', {id: id, name: name, display_name: display_name, type: nameType});
        yield put(receiveFormNameSave(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Deletes a form name from the server
 * @param id: The id of the form to delete from the server
 * @returns
 */
function* fetchFormNameDelete({id, cb}) {
    try {
        yield call(del, '/form_names/', {id: id});
        yield put(receiveFormNameDelete(cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches a form definition from the server
 * @param id: The id of the form to fetch from the server
 * @returns
 */
function* fetchProposalFormOrder({callId, cb}) {
    try {
        const response = yield call(get, `/proposal_form_order/${callId}/`);

        yield put(receiveProposalFormOrder(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches a form definition from the server
 * @param id: The id of the form to fetch from the server
 * @returns
 */
function* fetchReviewFormOrder({callId, cb}) {
    try {
        const response = yield call(get, `/review_form_order/${callId}/`);

        yield put(receiveReviewFormOrder(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches all forms from the server
 * @returns
 */
function* fetchAllForms({cb}) {
    try {
        const response = yield call(get, '/forms/');

        yield put(receiveAllForms(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Fetches all forms from the server
 * @returns
 */
function* fetchAllFormNames({cb}) {
    try {
        const response = yield call(get, '/form_names/');

        yield put(receiveAllFormNames(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

/***
 * Submits a data for a form to the server for a specific form id
 * @param id: The id of the form for which data is being submitted
 * @param data: The JSON data for the form that is being submitted
 * @param cb: A callback that is called when the form has been submitted successfully
 * @returns
 */
function* submitForm({extra, data, postURL, cb}) {
    try {
        const response = yield call(post, postURL, {...extra, data,});
        yield put(receiveSubmit(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* submitDeleteMultiForm({callId, proposalId, formId, formIndex, cb}) {
    try {
        const response = yield call(del, '/delete_multi_form/',
            {callId: callId, proposalId: proposalId, formId: formId, formIndex: formIndex}
        );
        yield put(receiveDeleteMultiForm(response, cb));
    } catch (error) {
        return yield put({type: REQUEST_ERROR, errors: error.json || error.message});
    }
}

function* watchFetch() {
    yield takeEvery(FORM_REQUEST, fetchForm);
}

function* watchSubmit() {
    yield takeEvery(SUBMIT_REQUEST, submitForm);
}

function* watchAllForm() {
    yield takeEvery(ALL_FORMS_REQUEST, fetchAllForms);
}

function* watchAllFormNames() {
    yield takeEvery(FORM_NAMES_REQUEST, fetchAllFormNames);
}

function* watchProposalFormOrder() {
    yield takeEvery(PROPOSAL_FORM_ORDER_REQUEST, fetchProposalFormOrder);
}

function* watchReviewFormOrder() {
    yield takeEvery(REVIEW_FORM_ORDER_REQUEST, fetchReviewFormOrder);
}

function* watchSaveForm() {
    yield takeEvery(FORM_SAVE_REQUEST, fetchFormSave);
}

function* watchDeleteForm() {
    yield takeEvery(FORM_DELETE_REQUEST, fetchFormDelete);
}

function* watchDeleteFormName() {
    yield takeEvery(FORM_NAME_DELETE_REQUEST, fetchFormNameDelete);
}

function* watchSaveFormName() {
    yield takeEvery(FORM_NAME_SAVE_REQUEST, fetchFormNameSave);
}

function* watchDeleteMultiForm() {
    yield takeEvery(DELETE_MULTI_FORM_REQUEST, submitDeleteMultiForm);
}

const sagas = [
    watchFetch,
    watchSubmit,
    watchAllForm,
    watchAllFormNames,
    watchProposalFormOrder,
    watchReviewFormOrder,
    watchSaveForm,
    watchDeleteForm,
    watchDeleteFormName,
    watchSaveFormName,
    watchDeleteMultiForm
];

// Reducers
const path = 'forms';
const reducer = (state = {
    all: Enumerable.empty(),
    names: Enumerable.empty(),
    proposalFormOrder: Enumerable.empty(),
    reviewFormOrder: Enumerable.empty()
}, action = {}) => {
    switch (action.type) {
    case SUBMIT_SUCCESS:
        if (action.cb)
            defer(() => action.cb(action.response));

        break;
    case ALL_FORMS_SUCCESS:
        state = {
            ...state,
            all: Enumerable.from(action.allForms)
        };

        if (action.cb)
            defer(() => action.cb(state.all));

        break;
    case FORM_NAMES_SUCCESS:
        state = {
            ...state,
            names: Enumerable.from(action.allFormNames)
        };

        if (action.cb)
            defer(() => action.cb(state.names));

        break;
    case PROPOSAL_FORM_ORDER_SUCCESS:
        state = {
            ...state,
            proposalFormOrder: Enumerable.from(action.order)
        };

        if (action.cb)
            defer(() => action.cb(action.order));

        break;
    case PROPOSAL_FORM_ORDER_REQUEST:
        // Reset the existing state
        state = {
            ...state,
            proposalFormOrder: Enumerable.empty()
        };
        break;
    case REVIEW_FORM_ORDER_SUCCESS:
        state = {
            ...state,
            reviewFormOrder: Enumerable.from(action.order)
        };

        if (action.cb)
            defer(() => action.cb(action.order));

        break;
    case REVIEW_FORM_ORDER_REQUEST:
        // Reset the existing state
        state = {
            ...state,
            reviewFormOrder: Enumerable.empty()
        };
        break;
    case FORM_SUCCESS:
        const form = new Form(action.response);
        const all = state.all.where(f => f.id !== action.response.id);
        state = {
            ...state,
            all: all.concat([form])
        };
        if (action.cb)
            defer(() => action.cb(form));
        break;
    case DELETE_MULTI_FORM_SUCCESS:
    case FORM_NAME_SAVE_SUCCESS:
    case FORM_NAME_DELETE_SUCCESS:
    case FORM_DELETE_SUCCESS:
    case FORM_SAVE_SUCCESS:
        if (action.cb)
            defer(() => action.cb(action.response));
        break;
    default:
    }
    return state;
};

export {
    reducer,
    path,
    sagas,

    requestForm,
    requestSubmit,
    requestAllForms,
    requestAllFormNames,
    requestProposalFormOrder,
    requestReviewFormOrder,
    requestFormSave,
    requestFormDelete,
    requestFormNameDelete,
    requestFormNameSave,
    requestDeleteMultiForm
};
