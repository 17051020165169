import React from 'react';
import {Link} from 'react-router-dom';

const PermissionDenied = () => (
    <div>
        <h4>You do not have permission to access this page.</h4>
        <Link to="/">Home</Link>
    </div>
);

export default PermissionDenied;