import React, {Component} from 'react';
import {Link} from 'react-router-dom';


import {requestRegisterVerify} from '../../stores/auth/store';
import {connect} from 'react-redux';
import {Col, Image, Panel} from 'react-bootstrap';

import logo from '../../assets/images/logo.png';

class AccountConfirmEmail extends Component {
    constructor() {
        super();

        this.state = {
            receivedResponse: false,
            success: false
        };
    }

    componentWillMount() {
        const {dispatch, match: {params: {confirmationKey}}} = this.props;
        dispatch(requestRegisterVerify(confirmationKey, (ok) => {
            this.setState({
                receivedResponse: true,
                success: ok
            });
        }));
    }

    render() {
        let message;
        if (this.state.receivedResponse) {
            if (this.state.success) {
                message = (
                    <div>Your account has been verified successfully. You may now <Link to="/auth/login">log in</Link>.
                    </div>
                );
            }
            else {
                message = (<div>There was an error verifying your account.</div>);
            }
        }
        else
            message = (<div>Verifying account, please wait...</div>);

        return (
            <div>
                <Col md={4} mdOffset={4}>
                    <div className="text-center">
                        <Image src={logo} alt="ADACS Proposal System" title="ADACS Proposal System" responsive/>
                        <h1>Proposal System</h1>
                    </div>

                    <Panel header={<h3>Registration Complete</h3>} className="login-panel">
                        {message}
                    </Panel>
                </Col>
            </div>
        );
    }
}

const WrappedAccountConfirmEmail = connect()(AccountConfirmEmail);

export default WrappedAccountConfirmEmail;
