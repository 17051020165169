import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {requestSingleCall} from '../../stores/calls/store';
import {Button, Col, PageHeader, Row, Table} from 'react-bootstrap';
import {humanizeLowerCaseWithUnderscores, ListFromArray, navigate} from '../../utils/generalUtils';
import {requestAllProposals, requestProposal} from '../../stores/proposals/store';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Breadcrumbs from '../../components/Breadcrumbs';
import {DEBUG} from '../../App';
import {Link} from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import Enumerable from 'linq';
import {requestAllFormNames, requestAllForms, requestForm, requestProposalFormOrder} from '../../stores/forms/store';
import jsonSchemaLibrary from 'json-schema-library';
import {MULTI_FORM_TYPES, RESOURCE_FORM_TYPES} from '../../utils/constants';
import jsonpointer from 'jsonpointer';
import {BootstrapSelect} from '../../utils/BootstrapInputs';
import ProposalDownloader from '../../components/downloaders/ProposalDownloader';
import SavePDF from '../../utils/SavePDF';

/* eslint no-unused-vars: 0 */
/* eslint react/jsx-key: 0 */

class ListProposals extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            facilityFilter: null,
            proposalData: {}
        };
    }

    processData(forms, data) {
        const pages = [];

        // Iterate over the form ids
        this.props.proposalFormOrder.forEach(formId => {
            // Get the form for this id
            const form = forms[formId];

            // Get the data for this id
            const form_data = data[formId];

            // Get the form name
            const formName = this.props.formNames.firstOrDefault((n) => n.id === form.name);
            const strFormName = formName.name + ' ' + form.version;

            const page =
                {
                    formName: formName,
                    strFormName: strFormName,
                    multi: MULTI_FORM_TYPES.any(t => t === formName.type),
                    __fields__: {},
                    __sorted__: {}
                };

            function addData(schema, value, pointer, index) {
                if (schema instanceof Error || schema.type === 'error')
                    return;

                const fieldData = {
                    __xOffset__: 0
                };

                let shouldAdd = true;

                switch (schema.type) {
                case 'object':
                case 'array':
                    fieldData.__schema__ = schema;
                    fieldData.__fields__ = value;
                    break;
                case 'string':
                case 'number':
                case 'boolean':
                case 'integer':
                    fieldData.__schema__ = schema;
                    fieldData.value = value;
                    fieldData.__xOffset__ = 5;
                    break;
                default:
                    shouldAdd = false;
                }

                if (shouldAdd) {
                    if (!page.__fields__[index])
                        page.__fields__[index] = {};

                    let ptr = page.__fields__[index];

                    Enumerable.from(pointer.split('/')).skip(1).forEach(k => {
                        if (!ptr[k]) {
                            ptr[k] = {__fields__: {}};
                        }

                        ptr = ptr[k].__fields__;
                    });

                    let key = Enumerable.from(pointer.split('/')).skip(1).toArray().join('/');
                    if (key.length)
                        key = '/' + key;

                    const extra = jsonpointer.get(form.uiSchema, key);
                    if (extra)
                        fieldData.__extra__ = extra;
                    else
                        fieldData.__extra__ = {};

                    Object.assign(ptr, fieldData);
                }
            }

            // Check if this is a multi form
            if (page.multi) {
                // Iterate over each data entry in the multi form array
                Enumerable.from(form_data).forEach((d, idx) => {
                    // Iterate over the form schema and data
                    let iter = new jsonSchemaLibrary.cores.Draft04(form.jsonSchema);
                    iter.each(d, (s, v, p) => addData(s, v, p, idx), iter.rootSchema);
                });
            } else {
                // Iterate over the form schema and data
                let iter = new jsonSchemaLibrary.cores.Draft04(form.jsonSchema);
                iter.each(form_data, (s, v, p) => addData(s, v, p, 0), iter.rootSchema);
            }

            function sortData({key, value}) {
                if (typeof value === 'object') {
                    const order = Enumerable.from(value.__fields__.__extra__['ui:order'] || []);
                    value.__sorted__ = Enumerable.from(value.__fields__)
                        .orderBy(v => order.indexOf(v.key))
                        .where(v => v.key.substr(0, 2) !== '__')
                        .select(sortData).toArray();
                }

                const o = {};
                o[key] = value;
                return o;
            }

            // Now post process the data and sort any fields in their correct order from the form schema
            Enumerable.from(page.__fields__).forEach((data, idx) => {
                const order = Enumerable.from((data.value.__extra__ && data.value.__extra__['ui:order']) || []);
                page.__sorted__[idx] = Enumerable.from(data.value)
                    .orderBy(v => order.indexOf(v.key))
                    .where(v => v.key.substr(0, 2) !== '__')
                    .select(sortData).toArray();
            });

            pages.push(page);
        });

        return pages;
    }

    componentWillMount() {
        this.props.requestSingleCall(this.props.match.params.callId, () =>
            this.props.requestAllProposals(this.props.match.params.callId, allProposals => {
                this.props.requestAllForms(() =>
                    this.props.requestAllFormNames(() =>
                        this.props.requestProposalFormOrder(this.props.call.id,
                            () => {
                                if (allProposals.length) {
                                    allProposals.forEach(proposal => {
                                        const forms = {};
                                        const form_ids = this.props.proposalFormOrder.toArray();
                                        let form_index = 0;

                                        function get_next_form(klass, cb) {
                                            if (form_index < form_ids.length) {
                                                klass.props.requestForm(form_ids[form_index], form => {
                                                    forms[form_ids[form_index]] = form;
                                                    form_index++;

                                                    get_next_form(klass, cb);
                                                });
                                            } else
                                                klass.props.requestProposal(proposal.id, cb);
                                        }

                                        get_next_form(this, (data) => {
                                            const proposalData = {
                                                ...this.state.proposalData
                                            };

                                            proposalData[proposal.id] = {
                                                forms: forms,
                                                data: data,
                                                processed: this.processData(forms, data)
                                            };

                                            if (Object.keys(proposalData).length === allProposals.count()) {
                                                this.setState({
                                                    isLoading: false
                                                });
                                            }

                                            this.setState({
                                                ...this.state,
                                                proposalData: proposalData
                                            });
                                        });
                                    });
                                } else {
                                    this.setState({
                                        isLoading: false
                                    });
                                }
                            })
                    ));
            })
        );
    }

    canEdit(proposal) {
        if (DEBUG)
            return true;

        // Can't save if the proposal no longer allows submissions
        if (!this.props.call.canSubmitProposal(proposal.authorId))
            return false;

        // Can edit this proposal if this user is the author
        if (proposal.authorId === this.props.localUser.id)
            return true;

        // Can't edit the proposal if it's submitted
        if (proposal.stateVal >= 1)
            return false;

        // Can't edit if this user is not one of the collaborators
        return !!proposal.collaborators.any((c) => c.id === this.props.localUser.id);
    }

    canReview(proposal) {
        if (this.props.localUser.isChair(this.props.call.committee))
            return true;

        // Can't review this proposal if this user is the author
        if (proposal.authorId === this.props.localUser.id)
            return false;

        // Can't review if this user is not one of the collaborators
        return !proposal.collaborators.any((c) => c.id === this.props.localUser.id);
    }

    render() {
        const {call, localUser} = this.props;

        if (!localUser.isValid() || this.state.isLoading) {
            return (<PageLoading/>);
        }

        let resource_column_names = [];
        let total_resource_data = {};
        const resource_types = [];

        // Get all resource column names
        this.props.proposals.forEach(
            p => {
                if (p.resourceValues && p.resourceValues.forEach) {
                    p.resourceValues.forEach(s => {
                        resource_column_names.push(s.key);
                    });
                }
            }
        );

        resource_column_names = new Set(resource_column_names);

        // Get the resource form if there is one
        let resource_form = null;
        if (this.props.proposals.count() && this.state.proposalData[this.props.proposals.first().id])
            resource_form = Enumerable.from(this.state.proposalData[this.props.proposals.first().id].processed)
                .firstOrDefault(f => RESOURCE_FORM_TYPES.any(t => t === f.formName.type));


        // Map the reviewers to the call
        const proposals = this.props.proposals
            // Filter by facility
            .where(p => {
                if (p.resourceValues && p.resourceValues.forEach) {
                    // Check if we should actually filter or not
                    if (!this.state.facilityFilter)
                        // No
                        return true;

                    // Yes
                    return Enumerable.from(p.resourceValues).any(kv => kv.key === this.state.facilityFilter);
                } else
                    // Nothing to do, data is not consistent yet
                    return false;
            })
            .select(
                p => {
                    // Show proposal state as Reviewed to committee chair if it has been reviewed
                    if (call.canSubmitReview() && localUser.isChair(call.committee)) {
                        p.state = p.isReviewed ? 'Reviewed' : p.state;
                    }
                    p.str_reviewers = <ListFromArray items={p.reviewers.select(e => (e.name)).toArray()}/>;

                    // ASTACTA-91 - Show average score columns
                    if (localUser.isChair(call.committee) && p.reviewScores) {
                        p['score_table'] = (
                            <table>
                                <tbody>
                                    {
                                        p.reviewScores.select((s) => {
                                            if (s.name === '__result__') {
                                                p.score = parseFloat(s.value).toFixed(2);
                                            } else {
                                                return (
                                                    <tr key={s.name}>
                                                        <td>{humanizeLowerCaseWithUnderscores(s.name)}</td>
                                                        <td className="spaced">:</td>
                                                        <td>{parseFloat(s.value).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            }
                                        }).toArray()
                                    }
                                </tbody>
                            </table>
                        );

                        if (p.resourceValues && p.resourceValues.forEach) {
                            p.resourceValues.forEach(s => {
                                if (!total_resource_data[s.key])
                                    total_resource_data[s.key] = {};

                                p[`str_${s.key}`] = (
                                    <table>
                                        <tbody>
                                            {
                                                s.value.select(rv => {
                                                    if (!total_resource_data[s.key][rv.key])
                                                        total_resource_data[s.key][rv.key] = 0;

                                                    resource_types.push(rv.key);

                                                    let real_title = rv.key;
                                                    if (
                                                        resource_form
                                                        && Enumerable.from(resource_form.__fields__).count()
                                                    )
                                                        real_title =
                                                            resource_form.__fields__[0].__schema__.properties[rv.key]
                                                                .title || rv.key;

                                                    if (rv.value) {
                                                        // Only add data to the total if this proposal is not a draft
                                                        // or if the proposal is reviewed
                                                        if (p.state !== 'Draft' || p.isReviewed)
                                                            total_resource_data[s.key][rv.key] += rv.value;

                                                        return (
                                                            <tr key={rv.key}>
                                                                <td>{real_title}</td>
                                                                <td className="spaced">:</td>
                                                                <td>{parseFloat(rv.value).toFixed(2)}</td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        if (!p[`rsrc_${s.key}`])
                                                            p[`rsrc_${s.key}`] = {};

                                                        p[`rsrc_${s.key}`][rv.key] = 'N/A';

                                                        return (
                                                            <tr key={rv.key}>
                                                                <td>{real_title}</td>
                                                                <td style={{paddingLeft: 5, paddingRight: 5}}>:</td>
                                                                <td>N/A</td>
                                                            </tr>
                                                        );
                                                    }
                                                }).toArray()
                                            }
                                        </tbody>
                                    </table>
                                );
                            });
                        }
                    }
                    return p;
                }
            ).toArray();

        total_resource_data = Enumerable.from(total_resource_data);

        // ASTACTA-91 - Show average score columns
        let tableCols = [
            /* Proposal Type */
            (<TableHeaderColumn
                dataField="proposalTypeName"
                key="proposalTypeName"
                dataSort={true}
            >
                Type
            </TableHeaderColumn>
            ),
            /* Proposal Name */
            (<TableHeaderColumn
                dataField="proposalName"
                isKey={true} key="proposalName"
                dataSort={true}
            >
                Name
            </TableHeaderColumn>
            ),
            /* Proposal Author */
            (<TableHeaderColumn dataField="author" key="author">Proposal Author</TableHeaderColumn>),
            /* Proposal State */
            (<TableHeaderColumn dataField="state" key="state">Proposal State</TableHeaderColumn>),
            /* Reviewers */
            !call.canSubmitProposal(localUser.id) && !call.isReviewOpen && localUser.isChair(call.committee) ? (
                <TableHeaderColumn dataField="str_reviewers" key="str_reviewers" dataFormat={
                    // Inserts the reviewer list
                    (cell, row) => {
                        if (!call.canSubmitProposal(row.authorId)) {
                            return cell;
                        }
                    }
                }>Reviewers</TableHeaderColumn>
            ) : null
        ];

        const facilityOptions = [];

        // get the current date and time
        const now = moment();

        // You should only see the Average Field Scores if you are a chair and the review has commenced. 
        if (localUser.isChair(call.committee) && now.isAfter(call.reviewCommencement)) {
            new Set(resource_column_names).forEach(c => {
                facilityOptions.push((<option key={c} value={c}>{c}</option>));
                tableCols.push(
                    (
                        <TableHeaderColumn dataField={`str_${c}`} key={`str_${c}`} dataFormat={
                            (cell, row) => cell
                        }>
                            {humanizeLowerCaseWithUnderscores(c)}
                        </TableHeaderColumn>
                    )
                );
            });
            tableCols.push(
                (
                    <TableHeaderColumn dataField="score_table" key="score_table" dataFormat={
                        (cell, row) => cell
                    }>
                        Average Field Scores
                    </TableHeaderColumn>
                )
            );
        }

        tableCols = tableCols.concat([
            /* The average score for this review */
            !call.canSubmitProposal('ANY')
            && localUser.isChair(call.committee)
            && now.isAfter(call.reviewCommencement) ?
                (
                    <TableHeaderColumn dataField="score" dataSort={true} key="score" dataFormat={
                        // Inserts the reviewer list
                        (cell, row) => {
                            if (!call.canSubmitProposal(row.authorId)) {
                                return cell;
                            }
                        }
                    }>Review Score</TableHeaderColumn>
                ) : null,
            /* View the proposal */
            (<TableHeaderColumn dataField="view" key="view" dataFormat={
                (cell, row) => <ListFromArray items={
                    [
                        <Link to={`/calls/${row.callId}/proposals/${row.id}/`}>
                            {call.canSubmitProposal(row.authorId) && this.canEdit(row) ?
                                'Edit Proposal' : 'View Proposal'}
                        </Link>,

                        this.canReview(row) ?
                            <Link to={`/calls/${row.callId}/proposals/${row.id}/reviews/`}>
                                View Reviews
                            </Link> : null,

                        <SavePDF component={<Link to="#" replace/>} callId={row.callId} proposalId={row.id}/>
                    ]
                }
                />
            }>View</TableHeaderColumn>),
        ]);

        const options = {
            defaultSortName: 'proposalName',  // default sort column name
            defaultSortOrder: 'asc'  // default sort order
        };

        return (
            <div>
                <Breadcrumbs items={
                    [
                        {
                            name: 'Call for Proposals',
                            url: '/calls'
                        },
                        {
                            name: call.name,
                            url: `/calls/${call.id}/`
                        },
                        {
                            name: 'Proposals',
                            url: `/calls/${call.id}/proposals/`
                        }
                    ]
                }/>

                <PageHeader>
                    Call for Proposal - {call.name}

                    {/* Add new proposal */}
                    {call.canSubmitProposal(localUser.id) ? (
                        <Button
                            className="pull-right"
                            onClick={
                                () => navigate(this, `/calls/${call.id}/new`)
                            }
                        >
                            Add Proposal
                        </Button>
                    ) : null}
                </PageHeader>

                {facilityOptions.length > 1 ? (
                    <Row>
                        <Col md={3}>
                            <BootstrapSelect
                                options={Enumerable.from([(
                                    <option key="blank" value="">---</option>)]).concat(facilityOptions).toArray()}
                                label="Filter by Facility"
                                onChange={(v) => this.setState({
                                    ...this.state,
                                    facilityFilter: v.target.value.length ? v.target.value : null
                                })}
                            />
                        </Col>
                        <Col md={9}/>
                    </Row>
                ) : null}

                {/* List all proposals for this call */}
                <BootstrapTable data={proposals} striped search pagination hover options={options}>
                    {tableCols}
                </BootstrapTable>

                {/* Show the resource totals if the current user is a chair */}
                {localUser.isChair(call.committee) ? (
                    <div>
                        <hr/>
                        <h4>Resource Totals</h4>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    {
                                        total_resource_data.select(v => (
                                            <td key={v.key}>
                                                {humanizeLowerCaseWithUnderscores(v.key)}
                                            </td>
                                        )).toArray()
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        total_resource_data.select(v => (
                                            <td key={v.key}>
                                                <table>
                                                    <tbody>
                                                        {
                                                            Enumerable.from(v.value).select(rv => (
                                                                <tr key={rv.key}>
                                                                    <td>{
                                                                        (
                                                                            resource_form &&
                                                                            Enumerable.from(resource_form.__fields__)
                                                                                .count()
                                                                        ) ?
                                                                            resource_form.__fields__[0].__schema__
                                                                                .properties[rv.key].title || rv.key
                                                                            : rv.key
                                                                    }</td>
                                                                    <td className="spaced">:</td>
                                                                    <td>{rv.value}</td>
                                                                </tr>
                                                            )).toArray()
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>)
                                        ).toArray()
                                    }
                                </tr>
                            </tbody>
                        </Table>
                        <hr/>
                        <h4>Download CSV Files</h4>
                        <ProposalDownloader
                            callName={call.name}
                            proposals={this.props.proposals}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({calls, proposals, auth, forms}) => ({
    call: calls.currentCall,
    proposals: proposals.all,
    localUser: auth.localUser,
    proposalFormOrder: forms.proposalFormOrder,
    forms: forms.all,
    formNames: forms.names,
});

export default connect(mapStateToProps,
    {
        requestAllProposals,
        requestSingleCall,
        requestAllForms,
        requestAllFormNames,
        requestProposalFormOrder,
        requestForm,
        requestProposal
    })(ListProposals);
