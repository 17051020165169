import React from 'react';
import {Route, Switch} from 'react-router-dom';

import AccountConfirmEmail from './AccountConfirmEmail';
import Login from './Login';
import Register from './Register';
import RegisterComplete from './RegisterComplete';
import ResetPassword from './ResetPassword';
import ConfirmResetPassword from './ConfirmResetPassword';
import CompleteRegistration from './CompleteRegistration';

const Auth = ({match: {url}}) => (
    <Switch>
        <Route path={`${url}/login`} component={Login}/>
        <Route path={`${url}/complete_registration`} component={CompleteRegistration}/>
        <Route path={`${url}/register/complete`} component={RegisterComplete}/>
        <Route path={`${url}/register`} component={Register}/>
        <Route path={`${url}/registration/account-confirm-email/:confirmationKey/`}
            component={AccountConfirmEmail}/>
        <Route path={`${url}/password-reset/confirm/:uidb64/:token/`}
            component={ConfirmResetPassword}/>
        <Route path={`${url}/password-reset/`} component={ResetPassword}/>
    </Switch>
);

export default Auth;
