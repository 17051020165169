import React, {Component} from 'react';
import {Breadcrumb} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import Enumerable from 'linq';

class Breadcrumbs extends Component {
    render() {
        const items = Enumerable.from(this.props.items).select(
            (i, index) => (
                <LinkContainer to={i.url} key={index}>
                    <Breadcrumb.Item active={index === this.props.items.length - 1}>
                        {i.name}
                    </Breadcrumb.Item>
                </LinkContainer>
            )
        ).toArray();

        return (
            <Breadcrumb>
                {items}
            </Breadcrumb>
        );
    }
}

export default Breadcrumbs;