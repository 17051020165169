import Enumerable from 'linq';

class LocalUser {
    constructor(details) {
        // Copy details in to our own object
        Object.assign(this, details);

        // Convert roles to an enumerable
        this.roles = Enumerable.from(this.roles);
    }

    isValid() {
        // Returns true if this object has been initialised by an object from the server
        // false if not initialised from the server yet.

        // id is undefined unless set by the server
        return !!this.id;
    }

    isAdmin() {
        // If this user is an admin
        return this.admin;
    }

    isChair(committeeId) {
        if (committeeId)
        // If this user is a chair for the specified committee
            return this.isAdmin() || this.roles.any((r) => r.committee === committeeId && r.role === 'CHAIR');

        // If this user has any CHAIR role
        return this.isAdmin() || this.roles.any((r) => r.role === 'CHAIR');
    }

    isMember(committeeId) {
        if (committeeId)
        // If this user is a member for the specified committee
            return this.isChair(committeeId)
                || this.roles.any((r) => r.committee === committeeId && r.role === 'MEMBER');

        // If this user has any MEMBER role
        return this.isChair(committeeId) || this.roles.any((r) => r.role === 'MEMBER');
    }

    isAuthor(call) {
        // If this user is an author of any proposal for the specified call
        return call.authorIds.any((id) => id === this.id);
    }

    isCollaborator(call) {
        // If this user is a collaborator of any proposal for the specified call
        return call.collaboratorIds.any((id) => id === this.id);
    }

    getChairCommittees(committees) {
        // Admin is chair of all committees
        if (this.isAdmin())
            return committees;

        // Filter the committees we are chair of
        const chairRoles = this.roles.where((r) => r.role === 'CHAIR');
        return committees.where((c) => chairRoles.any((r) => r.committee === c.id));
    }
}

export default LocalUser;